import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react';
import { generalUtils } from '@shared/common';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import ArrowSVG from '~/assets/icons/arrow.svg';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useLocale } from '~/hooks/useLocale';
import { useProducts } from '~/hooks/useProducts';

import { TransText } from './TransText';
import { UpsellButton } from './UpsellButton';

export const MeditationUpsell = () => {
  const nextRoute = '/upsells/bundle';
  const {
    selectedPlan,
    lead: { cartItems },
  } = useAppLead();
  const { t } = useTranslation();
  const { findProductPrice, findProductById } = useProducts(selectedPlan);
  const upsellItemsCount = cartItems?.filter((prod) => prod.isUpsell && !/upgrade/.test(prod.key))?.length || 0;
  const { isRestWW } = useLocale();
  const product = findProductById(isRestWW ? '115' : '109');
  const isSubscription = product?.isSubscription;
  const { currency, finalPrice, price } = findProductPrice(product);
  const { pushNextRoute, push, flowName } = useAppRouter(nextRoute);
  const [isVisible, setIsVisible] = useState(false);

  const handleSuccess = () => {
    if (upsellItemsCount >= 2) {
      push('/success');
    } else {
      pushNextRoute();
    }
  };

  useEffect(() => {
    window?.addEventListener('scroll', () => {
      setIsVisible(window.pageYOffset > 1200);
    });

    return window.removeEventListener('scroll', () => {});
  }, []);

  return product ? (
    <>
      <Box
        width='100%'
        bg='rgba(255, 255, 255, 0.8)'
        border='2px solid rgba(63, 161, 52, 0.40)'
        borderRadius='0.5rem'
        padding={{ base: '1rem 1rem 0.5rem 1rem', md: '1.5rem 1.5rem 0.5rem 1.5rem' }}
        marginBottom='1rem'
      >
        <Flex
          gap={isSubscription ? '2.5rem' : '1.5rem'}
          justifyContent='center'
          alignItems='center'
          borderBottom='1px solid #AED9A9'
          marginBottom='0.5rem'
          paddingBottom='1rem'
        >
          <Box>
            {isSubscription ? null : (
              <TransText
                transKey='common:one_time_offer'
                fontSize='12px'
                fontWeight={700}
                opacity={0.7}
                textTransform='uppercase'
              ></TransText>
            )}
            <TransText
              transKey={isSubscription ? 'common:only_per_month' : generalUtils.formatPrice(finalPrice, currency, 3)}
              color='#233E20'
              fontSize={isSubscription ? '20px' : '40px'}
              fontWeight={isSubscription ? 400 : 700}
              lineHeight='130%'
              components={{
                1: <Box as='span' fontSize='40px' fontWeight={700} />,
                2: <Box as='span' fontSize='16px' color='rgba(35, 62, 32, 0.64)' />,
              }}
              values={{
                value: generalUtils.formatPrice(finalPrice / 6, currency, 3),
              }}
            ></TransText>
          </Box>
          <Box>
            <TransText
              transKey='common:x_off'
              fontWeight={700}
              fontSize='12px'
              color='white'
              padding='0.25rem 0.5rem'
              bg='#C73E1D'
              borderRadius='0.25rem'
              lineHeight='100%'
              textAlign='center'
              marginBottom='6px'
              textTransform='uppercase'
              values={{
                value: 67,
              }}
            ></TransText>

            <TransText
              transKey={isSubscription ? 'common:per_month' : generalUtils.formatPrice(price, currency, 4, 4)}
              values={{ value: generalUtils.formatPrice(price / 6, currency, 3) }}
              textDecor='line-through'
              color='#C73E1D'
              fontSize={{ base: '14px', md: '1rem' }}
            ></TransText>
          </Box>
        </Flex>
        <TransText
          transKey='common:x_students_enrolled'
          textAlign='center'
          fontSize='12px'
          fontWeight={600}
          textTransform='uppercase'
          color='#3FA134'
          values={{ value: 4187 }}
        ></TransText>
      </Box>
      <Box marginBottom='0.5rem' width='100%'>
        <UpsellButton
          text={t('upsellMeditation:join_meditation_program')}
          product={product}
          onSuccess={handleSuccess}
        />
      </Box>
      <Link
        style={{ textDecoration: 'underline', fontWeight: 600, color: '#9499A2', fontSize: '14px' }}
        href={flowName ? { pathname: nextRoute, query: { flowName } } : nextRoute}
      >
        {t('common:skip')}
      </Link>
      {isSubscription ? (
        <TransText
          transKey='upsells:charge_every_6_months'
          color='rgba(35, 62, 32, 0.56)'
          marginTop='2rem'
          fontSize='12px'
          values={{
            price: generalUtils.formatPrice(finalPrice, currency, 2),
          }}
        ></TransText>
      ) : null}
      <Box
        display={{ base: isVisible ? 'block' : 'none', md: 'none' }}
        position='fixed'
        bottom='0'
        left='0'
        right='0'
        bg='white'
        padding='0.5rem'
        zIndex={999}
      >
        <Button
          width='100%'
          marginBottom='0.75rem'
          onClick={() => {
            generalUtils.scrollToElement('plan-selection');
          }}
          gap='1rem'
        >
          <TransText transKey='upsellMeditation:join_meditation_program' color='white'></TransText>
          <Flex>
            <ArrowSVG fill='#fff' />
          </Flex>
        </Button>
        <Link href={nextRoute}>
          <Text color='#9499A2' fontSize='14px' textDecor='underline' fontWeight={600} textAlign='center'>
            {t('common:skip')}
          </Text>
        </Link>
      </Box>
    </>
  ) : (
    <Flex justifyContent='space-between'>
      <Spinner />
    </Flex>
  );
};
