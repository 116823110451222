import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { generalUtils } from '@shared/common';
import { useState } from 'react';

import CloseSVG from '~/assets/icons/close.svg';
import DiscountBadgeSVG from '~/assets/icons/discount-badge.svg';
import TrialCommitmentChartSVG from '~/assets/icons/trial_commitment_chart.svg';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useProducts } from '~/hooks/useProducts';

export const DownsellForm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { selectedPlan, updateUser } = useAppLead();
  const { products, finalPrice, currency } = useProducts(selectedPlan);
  const { back, replace } = useAppRouter('/checkout');

  const handleClick = () => {
    const product = products.find(
      (p) =>
        p.id ===
        (selectedPlan?.plan.interval === 1
          ? '27'
          : selectedPlan?.plan.interval === 3
            ? '28'
            : selectedPlan?.plan.interval === 4
              ? '31'
              : selectedPlan?.plan.interval === 2
                ? '33'
                : '29'),
    );
    updateUser({
      hasDownsell: true,
      selectedPlan: product,
    });

    replace('/checkout');
  };

  const handleBack = () => {
    updateUser({
      hasDownsell: true,
    });

    back();
  };

  return (
    <Box width='100%' padding={{ base: '1rem 4%', md: '2rem' }}>
      {isModalOpen ? (
        <>
          <Flex justifyContent='right' marginBottom='0.5rem' cursor='pointer' onClick={handleBack}>
            <CloseSVG />
          </Flex>
          <Flex justifyContent='center' marginBottom='1rem'>
            <DiscountBadgeSVG width='64px' height='64px' />
          </Flex>
          <Text
            textAlign='center'
            fontSize='24px'
            fontWeight={700}
            lineHeight='120%'
            color='rgba(0, 0, 0, 0.9)'
            marginBottom='1rem'
          >
            Are you sure?
          </Text>
          <Text textAlign='center' color='rgba(0, 0, 0, 0.74)' marginBottom='1.5rem'>
            We want to see you succeed. You have a higher chance if you make a commitment now.
            <br />
            <br />
            If you start you trial now,{' '}
            <Box as='b' color='black'>
              {' '}
              we will add 50 easy-to-prepare and delicious desserts to your plan free of charge
            </Box>
            .
          </Text>
          <Button width='100%' marginBottom='1rem' onClick={handleClick}>
            Commit & start trial
          </Button>
          <Text
            fontSize='14px'
            onClick={handleBack}
            textDecoration='underline'
            textAlign='center'
            cursor='pointer'
            color='rgba(0, 0, 0, 0.6)'
          >
            Skip this offer
          </Text>
        </>
      ) : (
        <>
          <Flex justifyContent='right' marginBottom='0.5rem' cursor='pointer' onClick={() => setIsModalOpen(true)}>
            <CloseSVG />
          </Flex>
          <Text
            textAlign='center'
            fontSize='24px'
            fontWeight={700}
            lineHeight='120%'
            color='rgba(0, 0, 0, 0.8)'
            marginBottom='1rem'
          >
            Looking to increase your chance of success by 76%?
          </Text>
          <Text textAlign='center' color='rgba(0, 0, 0, 0.74)' marginBottom='1.5rem'>
            Scientific studies show that people feel more accountable and motivated once they&apos;ve made a strong
            commitment.
            <br />
            <br />
            You can lose the weight. You just need to commit and we want you to help.
            <br />
            <br />
            <b>
              If you commit now, we will give you 7-days trial just for $1. You can cancel anytime. Your challenge to
              lose 7 lbs and get a refund will be available as well.
            </b>
          </Text>
          <Flex justifyContent='center' maxWidth='16rem' margin='0 auto 1.5rem auto'>
            <TrialCommitmentChartSVG />
          </Flex>
          <Text fontWeight={700} fontSize='16px' color='black' marginBottom='0.5rem'>
            PRICE BREAKDOWN
          </Text>
          <Flex justifyContent='space-between' marginBottom='0.75rem'>
            <Text color='black'>{selectedPlan?.name}</Text>
            <Text color='black'>{generalUtils.formatPrice(finalPrice, currency)}</Text>
          </Flex>
          <Flex
            justifyContent='space-between'
            paddingBottom='0.75rem'
            marginBottom='0.75rem'
            borderBottom='1px solid rgba(0, 0, 0, 0.1)'
          >
            <Text fontWeight={500}>7-day trial</Text>
            <Text fontWeight={500}>-{generalUtils.formatPrice(Math.abs(finalPrice - 1), currency)}</Text>
          </Flex>
          <Flex justifyContent='space-between' marginBottom='1.5rem'>
            <Text fontWeight={700} color='black'>
              Total today
            </Text>
            <Text color='black'>{generalUtils.formatPrice(1, currency)}</Text>
          </Flex>
          <Button width='100%' marginBottom='1rem' onClick={handleClick}>
            Commit & start trial
          </Button>
          <Text
            fontSize='14px'
            onClick={() => setIsModalOpen(true)}
            textDecoration='underline'
            textAlign='center'
            cursor='pointer'
            color='rgba(0, 0, 0, 0.6)'
          >
            I don&apos;t want this offer
          </Text>
        </>
      )}
    </Box>
  );
};
