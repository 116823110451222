import { Box, Text } from '@chakra-ui/react';
import { formulas, useDate } from '@shared/common';
import { ForwardedRef, forwardRef } from 'react';

import { useAppLead } from '../hooks/useAppLead';

export const PlanDescription = forwardRef((props, ref: ForwardedRef<HTMLParagraphElement>) => {
  const { quizAnswers } = useAppLead();
  const { addDays } = useDate();

  const unitSystem = quizAnswers?.isMetric ? 'kg' : 'lb';
  const weight = unitSystem === 'kg' ? formulas.lbsToKg(Number(quizAnswers?.weight)) : Number(quizAnswers?.weight);
  const targetWeight =
    unitSystem === 'kg' ? formulas.lbsToKg(Number(quizAnswers?.targetWeight)) : Number(quizAnswers?.targetWeight);

  const weightLossTimeInMonths = formulas.calcWeightLossTime(
    Number(quizAnswers?.weight),
    Number(quizAnswers?.targetWeight),
  );
  const goalDate = addDays(weightLossTimeInMonths * 30);

  return (
    <Text textAlign='center' {...props} ref={ref}>
      The most popular anti-bloating plan! Over <b>174,577</b> plans ordered.
      <br />
      <Box as='span' textDecoration='underline' fontWeight={700}>
        {`Lose ${weight - targetWeight || 10}${unitSystem} until ${
          goalDate.month
        } ${goalDate.newDate.getFullYear()} without supplements`}
      </Box>
    </Text>
  );
});

PlanDescription.displayName = 'PlanDescription';
