import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useFreshState = <T>(initState: T): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState<T>(initState);

  useEffect(() => {
    if (initState) {
      setState(initState);
    }
  }, [initState]);

  return [state, setState];
};
