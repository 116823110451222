import { Button, Flex } from '@chakra-ui/react';

import ArrowSVG from '~/assets/icons/arrow.svg';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useAppRouter } from '~/hooks/useAppRouter';

import { useAppLead } from '../hooks/useAppLead';

export const GoalSelection = () => {
  const { updateQuizAnswers } = useAppLead();
  const analytics = useAnalytics();
  const { pushNextRoute } = useAppRouter('/quiz');

  const handleSelection = (goal: 'weight-loss' | 'improve-health') => {
    updateQuizAnswers({ goal });

    analytics.log('quiz_question_answer', { category: 'goal', label: goal });

    pushNextRoute();
  };

  return (
    <Flex gap={{ base: '0.5rem', md: '1rem' }} flexDirection={{ base: 'column', md: 'row' }} width='100%'>
      <Button
        flex={1}
        justifyContent={{ base: 'space-between', md: 'center' }}
        gap='1rem'
        onClick={() => handleSelection('improve-health')}
      >
        Long-term health
        <ArrowSVG fill='#fff' />
      </Button>
      <Button
        flex={1}
        justifyContent={{ base: 'space-between', md: 'center' }}
        variant='secondary'
        gap='1rem'
        onClick={() => handleSelection('weight-loss')}
      >
        Weight-loss & health <ArrowSVG fill='#FFD442' />
      </Button>
    </Flex>
  );
};
