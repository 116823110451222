import { Box, Flex, Grid } from '@chakra-ui/react';
import Image from 'next/image';

import Review1Image from '~/assets/images/reviews/review_1.png';
import Review10Image from '~/assets/images/reviews/review_10.png';
import Review2Image from '~/assets/images/reviews/review_2.png';
import Review3Image from '~/assets/images/reviews/review_3.png';
import Review4Image from '~/assets/images/reviews/review_4.png';
import Review5Image from '~/assets/images/reviews/review_5.png';
import Review6Image from '~/assets/images/reviews/review_6.png';
import Review7Image from '~/assets/images/reviews/review_7.png';
import Review8Image from '~/assets/images/reviews/review_8.png';
import Review9Image from '~/assets/images/reviews/review_9.png';

import { useAppLead } from '../hooks/useAppLead';

export const SuccessStories = () => {
  const { quizAnswers } = useAppLead();

  return (
    <>
      <Box display={quizAnswers?.gender === 'female' ? 'block' : 'none'} width='100%'>
        <Grid
          gap='1rem'
          gridTemplateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
          marginBottom='1rem'
          justifyContent='center'
          width='100%'
        >
          <Flex position='relative' width='100%' paddingBottom='145%' overflow='hidden'>
            <Image
              src={Review1Image}
              alt=''
              fill
              sizes='100vw'
              style={{
                objectFit: 'contain',
              }}
            />
          </Flex>
          <Flex position='relative' width='100%' paddingBottom='145%' overflow='hidden'>
            <Image
              src={Review2Image}
              alt=''
              fill
              sizes='100vw'
              style={{
                objectFit: 'contain',
              }}
            />
          </Flex>
          <Flex position='relative' width='100%' paddingBottom='145%' overflow='hidden'>
            <Image
              src={Review3Image}
              alt=''
              fill
              sizes='100vw'
              style={{
                objectFit: 'contain',
              }}
            />
          </Flex>
        </Grid>
        <Flex flexDirection={{ base: 'column', md: 'row' }} justifyContent='center' gap='1rem'>
          <Flex
            position='relative'
            width={{ base: '100%', md: '33%' }}
            paddingBottom={{ base: '145%', md: '50%' }}
            overflow='hidden'
          >
            <Image
              src={Review4Image}
              alt=''
              fill
              sizes='100vw'
              style={{
                objectFit: 'contain',
              }}
            />
          </Flex>
          <Flex
            position='relative'
            width={{ base: '100%', md: '33%' }}
            paddingBottom={{ base: '145%', md: '50%' }}
            overflow='hidden'
          >
            <Image
              src={Review5Image}
              alt=''
              fill
              sizes='100vw'
              style={{
                objectFit: 'contain',
              }}
            />
          </Flex>
        </Flex>
      </Box>
      <Box display={quizAnswers?.gender === 'female' ? 'none' : 'block'} width='100%'>
        <Grid
          gap='1rem'
          gridTemplateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
          marginBottom='1rem'
          justifyContent='center'
          width='100%'
        >
          <Flex position='relative' width='100%' paddingBottom='150%' overflow='hidden'>
            <Image
              src={Review6Image}
              alt=''
              fill
              sizes='100vw'
              style={{
                objectFit: 'contain',
              }}
            />
          </Flex>
          <Flex position='relative' width='100%' paddingBottom='145%' overflow='hidden'>
            <Image
              src={Review7Image}
              alt=''
              fill
              sizes='100vw'
              style={{
                objectFit: 'contain',
              }}
            />
          </Flex>
          <Flex position='relative' width='100%' paddingBottom='145%' overflow='hidden'>
            <Image
              src={Review8Image}
              alt=''
              fill
              sizes='100vw'
              style={{
                objectFit: 'contain',
              }}
            />
          </Flex>
        </Grid>
        <Flex flexDirection={{ base: 'column', md: 'row' }} justifyContent='center' gap='1rem'>
          <Flex
            position='relative'
            width={{ base: '100%', md: '33%' }}
            paddingBottom={{ base: '145%', md: '50%' }}
            overflow='hidden'
          >
            <Image
              src={Review9Image}
              alt=''
              fill
              sizes='100vw'
              style={{
                objectFit: 'contain',
              }}
            />
          </Flex>
          <Flex
            position='relative'
            width={{ base: '100%', md: '33%' }}
            paddingBottom={{ base: '145%', md: '50%' }}
            overflow='hidden'
          >
            <Image
              src={Review10Image}
              alt=''
              fill
              sizes='100vw'
              style={{
                objectFit: 'contain',
              }}
            />
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
