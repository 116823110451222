import { Box, Flex, Text } from '@chakra-ui/react';

import SpeedometerSVG from '~/assets/icons/meter.svg';

export const WeightLossZoneTable = () => {
  return (
    <Box width='100%'>
      <Flex padding='1rem' position='relative' right='-1.5rem'>
        <SpeedometerSVG height='8rem' width='100%' />
      </Flex>
      <Flex borderRadius='0.5rem' overflow='hidden'>
        <Box flex={1} backgroundColor='#e8e8e8' padding={{ base: '1rem', md: '2rem 2rem 2rem 2rem' }}>
          <Text fontWeight={700} fontSize='18px' lineHeight='120%' color='black' marginBottom='1rem'>
            Weight gain zone
          </Text>
          <Flex flexDirection='column' gap='1rem'>
            <Text
              fontSize={{ base: '14px', md: '1rem' }}
              color='black'
              paddingBottom='0.5rem'
              borderBottom='1px solid rgba(255, 255, 255, 0.5)'
            >
              Difficulty losing weight
            </Text>
            <Text
              fontSize={{ base: '14px', md: '1rem' }}
              color='black'
              paddingBottom='0.5rem'
              borderBottom='1px solid rgba(255, 255, 255, 0.5)'
            >
              Inflammation
            </Text>
            <Text
              fontSize={{ base: '14px', md: '1rem' }}
              color='black'
              paddingBottom='0.5rem'
              borderBottom='1px solid rgba(255, 255, 255, 0.5)'
            >
              Increased cravings
            </Text>
            <Text
              fontSize={{ base: '14px', md: '1rem' }}
              color='black'
              paddingBottom='0.5rem'
              borderBottom='1px solid rgba(255, 255, 255, 0.5)'
            >
              Constipation
            </Text>
            <Text
              fontSize={{ base: '14px', md: '1rem' }}
              color='black'
              paddingBottom='0.5rem'
              borderBottom='1px solid rgba(255, 255, 255, 0.5)'
            >
              Brain fog and fatigue
            </Text>
            <Text
              fontSize={{ base: '14px', md: '1rem' }}
              color='black'
              paddingBottom='0.5rem'
              borderBottom='1px solid rgba(255, 255, 255, 0.5)'
            >
              Excess fat storage
            </Text>
          </Flex>
        </Box>
        <Box flex={1} backgroundColor='#DAFDD7' padding={{ base: '1rem', md: '2rem 2rem 2rem 2rem' }}>
          <Text fontWeight={700} fontSize='18px' lineHeight='120%' color='black' marginBottom='1rem'>
            Weight loss zone
          </Text>
          <Flex flexDirection='column' gap='1rem'>
            <Text
              fontSize={{ base: '14px', md: '1rem' }}
              color='black'
              paddingBottom='0.5rem'
              borderBottom='1px solid rgba(255, 255, 255, 0.5)'
            >
              Steady weight loss
            </Text>
            <Text
              fontSize={{ base: '14px', md: '1rem' }}
              color='black'
              paddingBottom='0.5rem'
              borderBottom='1px solid rgba(255, 255, 255, 0.5)'
            >
              Reduced inflammation
            </Text>
            <Text
              fontSize={{ base: '14px', md: '1rem' }}
              color='black'
              paddingBottom='0.5rem'
              borderBottom='1px solid rgba(255, 255, 255, 0.5)'
            >
              Better appetite control
            </Text>
            <Text
              fontSize={{ base: '14px', md: '1rem' }}
              color='black'
              paddingBottom='0.5rem'
              borderBottom='1px solid rgba(255, 255, 255, 0.5)'
            >
              Regular toilet usage
            </Text>
            <Text
              fontSize={{ base: '14px', md: '1rem' }}
              color='black'
              paddingBottom='0.5rem'
              borderBottom='1px solid rgba(255, 255, 255, 0.5)'
            >
              More energy & sharp focus
            </Text>
            <Text
              fontSize={{ base: '14px', md: '1rem' }}
              color='black'
              paddingBottom='0.5rem'
              borderBottom='1px solid rgba(255, 255, 255, 0.5)'
            >
              Increased fat-burning rate
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
