import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react';
import { Currency, generalUtils, useTracking } from '@shared/common';
import { PaymentElement, PaymentProvider } from '@shared/payments';
import Link from 'next/link';

import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useProducts } from '~/hooks/useProducts';
import { useUpsellMutation } from '~/hooks/useUpsellMutation';

export const MealMasterclassUpsell = () => {
  const nextRoute = '/upsells/workouts';
  const {
    id,
    lead: { paymentProvider },
    updateCartItems,
  } = useAppLead();
  const { findProductById, findProductPrice, findPriceByCurrency } = useProducts();
  const product = findProductById('24');
  const { currency, finalPrice } = findProductPrice(product);
  const { trackPurchase } = useTracking();
  const { pushNextRoute, flowName } = useAppRouter(nextRoute);

  const upsellMutation = useUpsellMutation({
    onSuccess: ({ data }) => {
      if ('provider' in data && data.provider === PaymentProvider.STRIPE) {
        handleSuccess();
      }
    },
  });

  const handleOrderCreation = async ({ paymentProvider }: { paymentProvider: PaymentProvider }) => {
    const { data } = await upsellMutation.mutateAsync({
      leadId: String(id),
      planId: product?.id as string,
      provider: paymentProvider,
      currency,
    });

    if ('error' in data) {
      return {
        id: '',
      };
    } else {
      return data;
    }
  };

  const handleSuccess = () => {
    trackPurchase({
      id: `${id}-${product?.id}`,
      finalPrice,
      finalPriceUSD: findPriceByCurrency(product?.prices, Currency.USD).finalPrice,
      currency,
      leadId: String(id),
      items: { key: String(product?.name), name: String(product?.name), finalPrice },
    });

    updateCartItems(product);

    pushNextRoute();
  };

  return product ? (
    <>
      {paymentProvider === PaymentProvider.STRIPE ? (
        <Button
          isLoading={upsellMutation.status === 'loading'}
          width='100%'
          marginBottom='0.75rem'
          onClick={() => handleOrderCreation({ paymentProvider: PaymentProvider.STRIPE })}
        >
          <Box textAlign='center'>
            <Text marginBottom='4px' color='white'>
              ADD THIS TO MY ORDER
            </Text>
            <Text fontSize='14px' fontWeight={400} color='white'>
              {`ONLY ${generalUtils.formatPrice(finalPrice, currency)}`}
            </Text>
          </Box>
        </Button>
      ) : (
        <>
          <Text fontSize='24px' fontWeight={700}>
            {`ONLY ${generalUtils.formatPrice(finalPrice, currency)}`}
          </Text>
          <Text fontSize='13px' color='rgba(0, 0, 0, 0.74)' textAlign='center' maxWidth='14rem' marginBottom='0.5rem'>
            To add this to your order, please follow the instructions on PayPal.
          </Text>
          <Box width='100%'>
            <PaymentElement.Paypal
              createOrder={handleOrderCreation}
              options={{ isSubscription: false }}
              onSuccess={handleSuccess}
            />
          </Box>
        </>
      )}
      <Link
        style={{ textDecoration: 'underline' }}
        href={flowName ? { pathname: nextRoute, query: { flowName } } : nextRoute}
      >
        Skip
      </Link>
    </>
  ) : (
    <Flex justifyContent='space-between'>
      <Spinner />
    </Flex>
  );
};
