import { Box, CircularProgress, Flex, Text } from '@chakra-ui/react';
import { formulas } from '@shared/common';
import { animate } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';

import CheckSVG from '~/assets/icons/check.svg';
import UncheckedSVG from '~/assets/icons/unchecked.svg';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';

export const InflammationLoader = () => {
  const { quizAnswers } = useAppLead();
  const { pushNextRoute } = useAppRouter('/program/commit');
  const { score } = formulas.calcBMI(Number(quizAnswers?.weight), Number(quizAnswers?.height));
  const progressRef = useRef<HTMLParagraphElement | null>(null);
  const [isFinished, setIsFinished] = useState(false);
  const [progress, setProgress] = useState(0);
  const [step, setStep] = useState(0);
  const bmiGroup =
    score <= 18
      ? 18
      : score <= 20
      ? 20
      : score <= 24
      ? 24
      : score <= 26
      ? 26
      : score <= 30
      ? 30
      : score <= 34
      ? 34
      : 36;

  useEffect(() => {
    const controlls1 = animate(0, 100, {
      duration: 12,
      delay: 0.1,
      repeat: 0,
      onUpdate: (value: number) => {
        if (progressRef.current && value <= 100) {
          progressRef.current.textContent = `${value.toFixed(0) === '0' ? 100 : value.toFixed(0)}%`;
        }
      },
    });

    setTimeout(() => {
      setStep(1);
    }, 3000);

    setTimeout(() => {
      setStep(2);
    }, 6000);

    setTimeout(() => {
      setStep(3);
    }, 9000);

    setTimeout(() => {
      setStep(4);
    }, 11500);

    setTimeout(() => {
      setIsFinished(true);
    }, 12000);

    setTimeout(() => {
      pushNextRoute();
    }, 14500);

    return () => {
      controlls1.stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prog) => {
        if (prog + 2 === 100) {
          clearInterval(interval);
        }
        return prog + 2;
      });
    }, 240);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {isFinished && <ConfettiExplosion />}
      <Flex position='relative' alignItems='center' justifyContent='center' marginBottom='1.5rem'>
        <CircularProgress
          width='200px'
          height='220px'
          size='200px'
          thickness='6px'
          value={progress}
          capIsRound
          color='#5B9255'
          trackColor='#FFFFFF'
        ></CircularProgress>
        <Box
          position='absolute'
          bottom='26px'
          width='144px'
          height='144px'
          borderRadius='168px'
          backgroundColor='rgba(255, 255, 255, 0.6)'
        ></Box>
        <Text ref={progressRef} position='absolute' bottom='70px' fontWeight={900} fontSize='32px' color='#5B9255'>
          0%
        </Text>
      </Flex>
      <Text
        fontSize={{ base: '28px', md: '40px' }}
        lineHeight='110%'
        textAlign='center'
        fontWeight={900}
        fontFamily='var(--cabinetGroteskFont)'
        marginBottom='2rem'
      >
        {isFinished ? 'Congratulations, you are eligible for a coverage!' : 'Checking your eligibility...'}
      </Text>
      <Flex width='100%' maxWidth='26rem' flexDirection='column' margin='auto' gap='1rem'>
        <Flex alignItems='center' gap='0.5rem'>
          {step >= 1 ? <CheckSVG width='24px' height='24px' fill='#5B9255' /> : <UncheckedSVG />}
          <Text fontSize='18px'>{step >= 1 ? 'Lives in the US' : `Checking your country`}</Text>
        </Flex>
        <Flex alignItems='center' gap='0.5rem' opacity={step >= 1 ? 1 : 0.2}>
          {step >= 2 ? <CheckSVG width='24px' height='24px' fill='#5B9255' /> : <UncheckedSVG />}
          <Text fontSize='18px'>
            {step >= 2 ? `BMI higher than ${bmiGroup}` : step < 1 ? 'Check your BMI' : `Checking your BMI`}
          </Text>
        </Flex>
        <Flex alignItems='center' gap='0.5rem' opacity={step >= 2 ? 1 : 0.2}>
          {step >= 3 ? <CheckSVG width='24px' height='24px' fill='#5B9255' /> : <UncheckedSVG />}
          <Text fontSize='18px'>
            {step >= 3 ? `Your age is eligible` : step < 2 ? 'Check your age' : `Checking your age`}
          </Text>
        </Flex>
        <Flex alignItems='center' gap='0.5rem' opacity={step >= 3 ? 1 : 0.2}>
          {step >= 4 ? <CheckSVG width='24px' height='24px' fill='#5B9255' /> : <UncheckedSVG />}
          <Text fontSize='18px'>
            {step >= 4
              ? `High inflammation and stress levels`
              : step < 3
              ? 'Check your health conditions'
              : `Checking your health conditions`}
          </Text>
        </Flex>
      </Flex>
    </>
  );
};
