import { TextProps } from '@chakra-ui/react';
import { formulas, useDate } from '@shared/common';
import { FC } from 'react';

import { useAppLead } from '~/hooks/useAppLead';

import { TransText } from './TransText';

export const ShortWeightLossTitle: FC<TextProps> = (props) => {
  const { quizAnswers } = useAppLead();
  const { addDays } = useDate();

  const weightLostAfterMonth =
    formulas.calcFirstMonthWeightLoss(Number(quizAnswers?.weight), Number(quizAnswers?.targetWeight)) / 2;

  return (
    <TransText
      {...props}
      transKey='summary:headlines:you_can_lose_weight'
      values={{
        finalDate: `${addDays(14).month} ${addDays(14).newDate.getUTCDate()}`,
        weight: (weightLostAfterMonth / 2).toFixed(0),
      }}
    ></TransText>
  );
};

ShortWeightLossTitle.displayName = 'ShortWeightLossTitle';
