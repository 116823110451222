import { Text } from '@chakra-ui/react';
import { formulas } from '@shared/common';
import { ForwardedRef, forwardRef } from 'react';

import { useAppLead } from '~/hooks/useAppLead';

export const SummaryDynamicCopy = forwardRef((props, ref: ForwardedRef<HTMLParagraphElement>) => {
  const { quizAnswers } = useAppLead();
  const isMetric = quizAnswers?.isMetric;
  const weight = Number(quizAnswers?.weight);
  const units = isMetric ? 'kg' : 'lb';

  return (
    <Text {...props} ref={ref}>
      Increased cortisol levels typically influence metabolism and activate the body&apos;s fight-or-flight response.
      This can lead to heightened cravings, often without an obvious cause.
      <br />
      <br />
      One of the primary benefits of regulating cortisol levels, particularly for weight loss, is the reduction in
      late-night snacking. Properly balanced cortisol can help mitigate these cravings, contributing to more effective
      weight management and overall health benefits.
      <br />
      <br />
      If you start using DigestiPlan cortisol method, we estimate that you will be able to lose 3 lb within the first 2
      weeks. We have a database of more than 124,000 people, and we matched 9,247 similar to you (
      <b>{quizAnswers?.gender}</b>, <b>{quizAnswers?.age} years old</b>,{' '}
      <b>
        {Number(isMetric ? formulas.lbsToKg(weight) : weight).toFixed(0)} {units} starting weight
      </b>
      ).
    </Text>
  );
});

SummaryDynamicCopy.displayName = 'SummaryDynamicCopy';
