import { Box, Flex, Text } from '@chakra-ui/react';

import Shape1 from '~/assets/icons/shape_1.svg';
import Shape2 from '~/assets/icons/shape_2.svg';
import TransitionArrow from '~/assets/icons/transition_arrow.svg';

import { useAppLead } from '../hooks/useAppLead';

export const PlansHeader = () => {
  const { quizAnswers } = useAppLead();
  const expierence = quizAnswers?.experience as string;

  return (
    <>
      <Box width='100%' position='relative' zIndex={2}>
        <Box
          padding={{ base: '1rem 1rem 0 1rem', md: '1.5rem 1.5rem 0 1.5rem' }}
          maxWidth='36rem'
          backgroundColor='#f3eddf'
          borderRadius='0.25rem'
          margin='auto'
        >
          <Flex justifyContent='space-between' textAlign='center' marginBottom='1.5rem'>
            <Text flex={1} fontFamily='var(--cabinetGroteskFont)' fontWeight={700} fontSize='22px'>
              Current
            </Text>
            <Box height='auto' width='2px' backgroundColor='system.white' />
            <Text flex={1} fontFamily='var(--cabinetGroteskFont)' fontWeight={700} fontSize='22px'>
              After 1 month
            </Text>
          </Flex>
          <Flex position='relative' justifyContent='space-between' marginBottom='1rem'>
            <Flex position='relative' zIndex={2} flex={1} justifyContent='center'>
              <Shape1 />
            </Flex>
            <Flex position='relative' zIndex={2} flex={1} justifyContent='center'>
              <Shape2 />
            </Flex>
            <Flex position='absolute' top='50%' transform='translateY(-50%)' left='0' right='0' justifyContent='center'>
              <TransitionArrow fill='#ffffff' />
            </Flex>
          </Flex>
          {/* <Flex justifyContent='space-between' alignItems='end' gap='1.5rem'>
            <Text
              flex={1}
              fontFamily='var(--cabinetGroteskFont)'
              fontWeight={700}
              fontSize={{ base: '18px', md: '20px' }}
            >
              Current weight
            </Text>
            <Box height='auto' width='2px' paddingTop='2rem' backgroundColor='system.white' />
            <Text
              flex={1}
              fontFamily='var(--cabinetGroteskFont)'
              fontWeight={700}
              fontSize={{ base: '18px', md: '20px' }}
            >
              Target weight
            </Text>
          </Flex>
          <Flex justifyContent='space-between' gap='1.5rem' marginBottom='1px' borderBottom='2px solid white'>
            <Text flex={1}>
              {weight} {quizAnswers?.isMetric ? 'kg' : 'lb'}
            </Text>
            <Box height='auto' width='2px' paddingTop='2.75rem' backgroundColor='system.white' />
            <Text flex={1}>
              {targetWeight} {quizAnswers?.isMetric ? 'kg' : 'lb'}
            </Text>
          </Flex> */}
          <Flex gap='1.5rem'>
            <Box flex={1}>
              <Text marginBottom='0.5rem' fontSize='14px'>
                Bloating level
              </Text>
              <Flex flex={1} borderRadius='0.25rem' overflow='hidden' gap='0.25rem' marginBottom='1.25rem'>
                <Box flex={1} backgroundColor='#F85A69' height='5px' />
                <Box flex={1} backgroundColor='#F85A69' height='5px' />
                <Box flex={1} backgroundColor='#F85A69' height='5px' />
                <Box flex={1} backgroundColor='#F85A69' height='5px' />
                <Box flex={1} backgroundColor='white' height='5px' />
              </Flex>
              {/constipation/.test(expierence) && (
                <>
                  <Text marginBottom='0.5rem' fontSize='14px'>
                    Constipation level
                  </Text>
                  <Flex flex={1} borderRadius='0.25rem' overflow='hidden' gap='0.25rem' marginBottom='1.25rem'>
                    <Box flex={1} backgroundColor='#F85A69' height='5px' />
                    <Box flex={1} backgroundColor='#F85A69' height='5px' />
                    <Box flex={1} backgroundColor='#F85A69' height='5px' />
                    <Box flex={1} backgroundColor='#F85A69' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                  </Flex>
                </>
              )}
              {/inflammation/.test(expierence) && (
                <>
                  <Text marginBottom='0.5rem' fontSize='14px'>
                    Inflammation level
                  </Text>
                  <Flex flex={1} borderRadius='0.25rem' overflow='hidden' gap='0.25rem' marginBottom='1.25rem'>
                    <Box flex={1} backgroundColor='#F85A69' height='5px' />
                    <Box flex={1} backgroundColor='#F85A69' height='5px' />
                    <Box flex={1} backgroundColor='#F85A69' height='5px' />
                    <Box flex={1} backgroundColor='#F85A69' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                  </Flex>
                </>
              )}
              {/diarrhea/.test(expierence) && (
                <>
                  <Text marginBottom='0.5rem' fontSize='14px'>
                    Digestion level
                  </Text>
                  <Flex flex={1} borderRadius='0.25rem' overflow='hidden' gap='0.25rem' marginBottom='1.25rem'>
                    <Box flex={1} backgroundColor='#F85A69' height='5px' />
                    <Box flex={1} backgroundColor='#F85A69' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                  </Flex>
                </>
              )}
              {/fatigue/.test(expierence) && (
                <>
                  <Text marginBottom='0.5rem' fontSize='14px'>
                    Fatigue level
                  </Text>
                  <Flex flex={1} borderRadius='0.25rem' overflow='hidden' gap='0.25rem' marginBottom='1.25rem'>
                    <Box flex={1} backgroundColor='#F85A69' height='5px' />
                    <Box flex={1} backgroundColor='#F85A69' height='5px' />
                    <Box flex={1} backgroundColor='#F85A69' height='5px' />
                    <Box flex={1} backgroundColor='#F85A69' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                  </Flex>
                </>
              )}
              {/food_intolerance/.test(expierence) && (
                <>
                  <Text marginBottom='0.5rem' fontSize='14px'>
                    Food Intolerance
                  </Text>
                  <Flex flex={1} borderRadius='0.25rem' overflow='hidden' gap='0.25rem' marginBottom='1.25rem'>
                    <Box flex={1} backgroundColor='#F85A69' height='5px' />
                    <Box flex={1} backgroundColor='#F85A69' height='5px' />
                    <Box flex={1} backgroundColor='#F85A69' height='5px' />
                    <Box flex={1} backgroundColor='#F85A69' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                  </Flex>
                </>
              )}
              {/skin_problems/.test(expierence) && (
                <>
                  <Text marginBottom='0.5rem' fontSize='14px'>
                    Skin Problems
                  </Text>
                  <Flex flex={1} borderRadius='0.25rem' overflow='hidden' gap='0.25rem' marginBottom='1.25rem'>
                    <Box flex={1} backgroundColor='#F85A69' height='5px' />
                    <Box flex={1} backgroundColor='#F85A69' height='5px' />
                    <Box flex={1} backgroundColor='#F85A69' height='5px' />
                    <Box flex={1} backgroundColor='#F85A69' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                  </Flex>
                </>
              )}
            </Box>
            <Box height='auto' width='2px' backgroundColor='system.white' />
            <Box flex={1}>
              <Text marginBottom='0.5rem' fontSize='14px'>
                Bloating level
              </Text>
              <Flex flex={1} borderRadius='0.25rem' overflow='hidden' gap='0.25rem' marginBottom='1.25rem'>
                <Box flex={1} backgroundColor='white' height='5px' />
                <Box flex={1} backgroundColor='white' height='5px' />
                <Box flex={1} backgroundColor='white' height='5px' />
                <Box flex={1} backgroundColor='white' height='5px' />
                <Box flex={1} backgroundColor='white' height='5px' />
              </Flex>
              {/constipation/.test(expierence) && (
                <>
                  <Text marginBottom='0.5rem' fontSize='14px'>
                    Constipation level
                  </Text>
                  <Flex flex={1} borderRadius='0.25rem' overflow='hidden' gap='0.25rem' marginBottom='1.25rem'>
                    <Box flex={1} backgroundColor='white' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                  </Flex>
                </>
              )}
              {/inflammation/.test(expierence) && (
                <>
                  <Text marginBottom='0.5rem' fontSize='14px'>
                    Inflammation level
                  </Text>
                  <Flex flex={1} borderRadius='0.25rem' overflow='hidden' gap='0.25rem' marginBottom='1.25rem'>
                    <Box flex={1} backgroundColor='white' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                  </Flex>
                </>
              )}
              {/diarrhea/.test(expierence) && (
                <>
                  <Text marginBottom='0.5rem' fontSize='14px'>
                    Digestion level
                  </Text>
                  <Flex flex={1} borderRadius='0.25rem' overflow='hidden' gap='0.25rem' marginBottom='1.25rem'>
                    <Box flex={1} backgroundColor='#5B9255' height='5px' />
                    <Box flex={1} backgroundColor='#5B9255' height='5px' />
                    <Box flex={1} backgroundColor='#5B9255' height='5px' />
                    <Box flex={1} backgroundColor='#5B9255' height='5px' />
                    <Box flex={1} backgroundColor='#5B9255' height='5px' />
                  </Flex>
                </>
              )}
              {/fatigue/.test(expierence) && (
                <>
                  <Text marginBottom='0.5rem' fontSize='14px'>
                    Fatigue level
                  </Text>
                  <Flex flex={1} borderRadius='0.25rem' overflow='hidden' gap='0.25rem' marginBottom='1.25rem'>
                    <Box flex={1} backgroundColor='white' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                  </Flex>
                </>
              )}
              {/food_intolerance/.test(expierence) && (
                <>
                  <Text marginBottom='0.5rem' fontSize='14px'>
                    Food Intolerance
                  </Text>
                  <Flex flex={1} borderRadius='0.25rem' overflow='hidden' gap='0.25rem' marginBottom='1.25rem'>
                    <Box flex={1} backgroundColor='#F85A69' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                  </Flex>
                </>
              )}
              {/skin_problems/.test(expierence) && (
                <>
                  <Text marginBottom='0.5rem' fontSize='14px'>
                    Skin Problems
                  </Text>
                  <Flex flex={1} borderRadius='0.25rem' overflow='hidden' gap='0.25rem' marginBottom='1.25rem'>
                    <Box flex={1} backgroundColor='white' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                    <Box flex={1} backgroundColor='white' height='5px' />
                  </Flex>
                </>
              )}
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
};
