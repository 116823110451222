import { Box, Button, Flex, Text } from '@chakra-ui/react';

import { StickyWrapper } from '~/components/StickyWrapper';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useProducts } from '~/hooks/useProducts';

export const ProgramSelection = () => {
  const { pushNextRoute } = useAppRouter('/checkout');
  const { findProductById } = useProducts();
  const { updateUser } = useAppLead();
  const analytics = useAnalytics();

  const handleClick = (productId: number) => {
    const product = findProductById(String(productId));

    updateUser({ selectedPlan: product });

    if (product) {
      analytics.log('plans_select', { category: product?.name, label: product.name });
    }

    pushNextRoute();
  };

  return (
    <>
      <Box
        padding={{ base: '1rem', md: '1.25rem 1.5rem' }}
        border='2px solid'
        borderColor='#efefef'
        borderRadius='0.5rem'
        width={{ base: '100%', md: '40%' }}
        backgroundColor='system.white'
      >
        <Flex
          gap={{ base: '1.5rem', md: '1.5rem' }}
          justifyContent='space-between'
          alignItems='center'
          marginBottom='0.75rem'
        >
          <Text
            fontWeight={900}
            fontSize={{ base: '28px', md: '24px' }}
            fontFamily='var(--cabinetGroteskFont)'
            lineHeight='110%'
            color='#060654'
          >
            3-months plan
          </Text>
          <Text
            textAlign='center'
            fontSize={{ base: '24px', md: '24px' }}
            color='#060654'
            fontWeight={700}
            lineHeight='120%'
          >
            90%
          </Text>
        </Flex>
        <Text color='rgba(0, 0, 0, 0.7)' lineHeight='140%'>
          Coverage from National Weight Management Foundation
        </Text>
      </Box>
      <StickyWrapper position='fixed' zIndex='2'>
        <Button
          width='100%'
          maxWidth={{ base: 'none', md: '20rem' }}
          margin='auto'
          backgroundColor='#55C1FF'
          color='white'
          _hover={{
            backgroundColor: '#55C1FF',
          }}
          fontWeight={700}
          cursor='pointer'
          onClick={() => handleClick(14)}
        >
          Claim my plan
        </Button>
      </StickyWrapper>
    </>
  );
};
