import { FC, ReactNode } from 'react';

import { useAppRouter } from '~/hooks/useAppRouter';

export const FlowVariantSwitcher: FC<{ children: ReactNode; flowName: string; isNotEqual: boolean }> = ({
  children,
  flowName,
  isNotEqual,
}) => {
  const { flowName: currentFlowName } = useAppRouter();

  return (currentFlowName === flowName && !isNotEqual) ||
    (currentFlowName !== flowName && isNotEqual) ||
    (flowName === '' && !currentFlowName) ? (
    <>{children}</>
  ) : null;
};
