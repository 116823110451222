import { Box, Button, Flex, Spinner } from '@chakra-ui/react';
import { generalUtils } from '@shared/common';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import ArrowSVG from '~/assets/icons/arrow.svg';
import CalmProgramImage from '~/assets/images/calm_program.png';
import MobilitProgramImage from '~/assets/images/mobility_program.png';
import StressReleaseImage from '~/assets/images/stress_relese.png';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useLocale } from '~/hooks/useLocale';
import { useProducts } from '~/hooks/useProducts';

import { TransText } from './TransText';
import { UpsellButton } from './UpsellButton';

export const BundleUpsell = () => {
  const nextRoute = '/success';
  const {
    selectedPlan,
    lead: { cartItems },
  } = useAppLead();
  const { t } = useTranslation();
  const { findProductPrice, planInterval, findProductById } = useProducts(selectedPlan);
  const { isRestWW } = useLocale();

  const upsellItemsCount = cartItems?.filter((prod) => prod.isUpsell && !/upgrade/.test(prod.key))?.length || 0;
  const upsellBundleId =
    upsellItemsCount === 0
      ? isRestWW
        ? '118'
        : '112'
      : upsellItemsCount === 1
        ? isRestWW
          ? '117'
          : '111'
        : isRestWW
          ? '116'
          : '110';
  const product = findProductById(upsellBundleId);
  const isSubscription = product?.isSubscription;
  const { currency, finalPrice, price } = findProductPrice(product);
  const { pushNextRoute, flowName } = useAppRouter(nextRoute);
  const [isVisible, setIsVisible] = useState(false);
  const hasMobilityUpsell = cartItems?.some((item) => item.key === 'mobility-program');
  const hasStressReleaseUpsell = cartItems?.some((item) => item.key === 'stress-release-masterclass');
  const hasMeditationProgram = cartItems?.some((item) => item.key === 'meditation-program');

  useEffect(() => {
    window?.addEventListener('scroll', () => {
      setIsVisible(window.pageYOffset > 1200);
    });

    return window.removeEventListener('scroll', () => {});
  }, []);

  return product ? (
    <>
      <Flex
        alignItems='center'
        flexDir={{ base: 'column', md: 'row' }}
        gap={{ base: '0.5rem', md: '2rem' }}
        width='100%'
      >
        <Flex
          display={{ base: 'none', md: 'flex' }}
          width={{ base: '100%', md: '50%' }}
          flexDir='column'
          gap='0.25rem'
          bg='white'
          borderRadius='1rem'
          overflow='hidden'
        >
          {hasMobilityUpsell ? null : (
            <Flex>
              <Image style={{ width: '100%', objectFit: 'cover' }} src={MobilitProgramImage} alt='' />
            </Flex>
          )}
          {hasStressReleaseUpsell ? null : (
            <Flex>
              <Image style={{ width: '100%', objectFit: 'cover' }} src={StressReleaseImage} alt='' />
            </Flex>
          )}
          {hasMeditationProgram ? null : (
            <Flex>
              <Image style={{ width: '100%', objectFit: 'cover' }} src={CalmProgramImage} alt='' />
            </Flex>
          )}
        </Flex>
        <Box width={{ base: '100%', md: '50%' }} maxW='32rem'>
          <TransText
            transKey='upsellBundle:get_all_programs_and_maserclasses'
            fontWeight={900}
            fontFamily='var(--cabinetGroteskFont)'
            lineHeight='110%'
            fontSize={{ base: '30px', md: '56px' }}
            marginBottom={{ base: '1rem', md: '1.5rem' }}
            values={{
              initialPrice: isSubscription
                ? t('common:per_month', {
                  value: generalUtils.formatPrice(price / planInterval, currency, 3),
                })
                : generalUtils.formatPrice(price, currency, 4, 4),
              price: isSubscription
                ? t('common:per_month', {
                  value: generalUtils.formatPrice(finalPrice / planInterval, currency, 3),
                })
                : generalUtils.formatPrice(finalPrice, currency, 4, 4),
              innerStyles: {
                color: '#C73E1D',
                textDecoration: 'line-through',
              },
            }}
            color='primaryDark'
          ></TransText>
          <TransText
            transKey='upsellBundle:transform_your_life_with'
            fontSize={{ base: '20px', md: '32px' }}
            fontWeight={700}
            color='#3FA134'
            fontFamily='var(--cabinetGroteskFont)'
            lineHeight='120%'
            marginBottom='0.5rem'
          ></TransText>
          <Flex flexDirection='column' gap='0.75rem' marginBottom='1.5rem'>
            {hasMobilityUpsell ? null : (
              <Flex flexDir='column' gap='0.5rem'>
                <Flex gap='0.5rem' alignItems='center'>
                  <Flex
                    width='1.5rem'
                    height='1.5rem'
                    justifyContent='center'
                    alignItems='center'
                    bg='#3FA134'
                    borderRadius='1rem'
                    color='white'
                    fontWeight={700}
                    fontSize='14px'
                  >
                    1
                  </Flex>
                  <TransText
                    transKey='upsellBundle:mobility_masterclass_to_eliminate_belly'
                    flex={1}
                    fontWeight={600}
                    color='primaryDark'
                  ></TransText>
                </Flex>
                <Flex
                  display={{ base: 'flex', md: 'none' }}
                  position='relative'
                  borderRadius='0.5rem'
                  overflow='hidden'
                  paddingBottom='50%'
                >
                  <Image fill src={MobilitProgramImage} alt='' />
                </Flex>
              </Flex>
            )}
            {hasStressReleaseUpsell ? null : (
              <Flex flexDir='column' gap='0.5rem'>
                <Flex gap='0.5rem' alignItems='center'>
                  <Flex
                    width='1.5rem'
                    height='1.5rem'
                    justifyContent='center'
                    alignItems='center'
                    bg='#3FA134'
                    borderRadius='1rem'
                    color='white'
                    fontWeight={700}
                    fontSize='14px'
                  >
                    {hasMobilityUpsell ? 1 : 2}
                  </Flex>
                  <TransText
                    transKey='upsellBundle:heal_your_nervous_system'
                    flex={1}
                    fontWeight={600}
                    color='primaryDark'
                  ></TransText>
                </Flex>
                <Flex
                  display={{ base: 'flex', md: 'none' }}
                  position='relative'
                  borderRadius='0.5rem'
                  overflow='hidden'
                  paddingBottom='50%'
                >
                  <Image fill src={StressReleaseImage} alt='' />
                </Flex>
              </Flex>
            )}
            {hasMeditationProgram ? null : (
              <Flex flexDir='column' gap='0.5rem'>
                <Flex gap='0.5rem' alignItems='center'>
                  <Flex
                    width='1.5rem'
                    height='1.5rem'
                    justifyContent='center'
                    alignItems='center'
                    bg='#3FA134'
                    borderRadius='1rem'
                    color='white'
                    fontWeight={700}
                    fontSize='14px'
                  >
                    {hasMobilityUpsell && hasStressReleaseUpsell
                      ? 1
                      : hasMobilityUpsell || hasStressReleaseUpsell
                        ? 2
                        : 3}
                  </Flex>
                  <TransText
                    transKey='upsellBundle:yoga_meditation_classes'
                    flex={1}
                    fontWeight={600}
                    color='primaryDark'
                  ></TransText>
                </Flex>
                <Flex
                  display={{ base: 'flex', md: 'none' }}
                  position='relative'
                  borderRadius='0.5rem'
                  overflow='hidden'
                  paddingBottom='50%'
                >
                  <Image fill src={CalmProgramImage} alt='' />
                </Flex>
              </Flex>
            )}
          </Flex>
          <Box
            id='plan-selection'
            bg='rgba(255, 255, 255, 0.8)'
            border='2px solid rgba(63, 161, 52, 0.40)'
            borderRadius='0.5rem'
            padding={{ base: '1rem 1rem 0.5rem 1rem', md: '1.5rem 1.5rem 0.5rem 1.5rem' }}
            marginBottom='1rem'
          >
            <Flex
              gap={{ base: '1rem', md: '2.5rem' }}
              justifyContent='center'
              alignItems='center'
              borderBottom='1px solid #AED9A9'
              marginBottom='0.5rem'
              paddingBottom='1rem'
            >
              <Box flex={1}>
                {isSubscription ? null : (
                  <TransText
                    transKey='common:one_time_offer'
                    fontSize='12px'
                    fontWeight={700}
                    opacity={0.7}
                    textTransform='uppercase'
                  ></TransText>
                )}
                <TransText
                  transKey={
                    isSubscription ? 'common:only_per_month' : generalUtils.formatPrice(finalPrice, currency, 4, 4)
                  }
                  color='#233E20'
                  fontSize={isSubscription ? '20px' : '40px'}
                  fontWeight={isSubscription ? 400 : 700}
                  lineHeight={isSubscription ? '150%' : '130%'}
                  components={{
                    1: <Box as='span' fontSize='40px' fontWeight={700} />,
                    2: <Box as='span' fontSize='16px' color='rgba(35, 62, 32, 0.64)' />,
                  }}
                  values={{
                    value: generalUtils.formatPrice(finalPrice / planInterval, currency, 3),
                  }}
                ></TransText>
              </Box>
              <Box flex={1}>
                <TransText
                  transKey='upsellBundle:save_with_this_bundle'
                  fontWeight={700}
                  fontSize='12px'
                  color='white'
                  padding='0.25rem 0.5rem'
                  bg='#C73E1D'
                  borderRadius='0.25rem'
                  lineHeight='100%'
                  textAlign='center'
                  marginBottom='6px'
                  values={{
                    value: isSubscription
                      ? generalUtils.formatPrice((price - finalPrice) / planInterval, currency, 3)
                      : generalUtils.formatPrice(price - finalPrice, currency, 4, 4),
                  }}
                  textTransform='uppercase'
                ></TransText>

                <TransText
                  transKey={isSubscription ? 'common:per_month' : generalUtils.formatPrice(price, currency, 4, 4)}
                  textDecor='line-through'
                  color='#C73E1D'
                  textAlign='right'
                  fontSize={{ base: '14px', md: '1rem' }}
                  values={{ value: generalUtils.formatPrice(price / planInterval, currency, 3) }}
                ></TransText>
              </Box>
            </Flex>
            <TransText
              transKey='common:x_students_enrolled'
              textAlign='center'
              fontSize='12px'
              fontWeight={600}
              textTransform='uppercase'
              color='#3FA134'
              values={{ value: 4478 }}
            ></TransText>
          </Box>
          <Box marginBottom='0.5rem' width='100%'>
            <UpsellButton
              text={t('upsellBundle:join_best_selling_programs')}
              product={product}
              onSuccess={pushNextRoute}
            />
          </Box>
          <Link
            style={{
              display: 'block',
              textDecoration: 'underline',
              fontWeight: 600,
              color: '#9499A2',
              fontSize: '14px',
              textAlign: 'center',
            }}
            href={flowName ? { pathname: nextRoute, query: { flowName } } : nextRoute}
          >
            {t('common:skip')}
          </Link>
          {isSubscription ? (
            <TransText
              transKey='upsells:charge_every_6_months'
              values={{
                price: generalUtils.formatPrice(finalPrice, currency, 2),
                interval: planInterval,
                count: planInterval,
              }}
              color='rgba(35, 62, 32, 0.56)'
              marginTop='2rem'
              fontSize='12px'
              textAlign='center'
            ></TransText>
          ) : null}
        </Box>
      </Flex>
      <Box
        display={{ base: isVisible ? 'block' : 'none', md: 'none' }}
        position='fixed'
        bottom='0'
        left='0'
        right='0'
        bg='white'
        padding='0.5rem'
        zIndex={999}
      >
        <Button
          width='100%'
          marginBottom='0.75rem'
          onClick={() => {
            generalUtils.scrollToElement('plan-selection');
          }}
          gap='1rem'
        >
          <TransText transKey='upsellBundle:join_best_selling_programs' color='white'></TransText>
          <Flex>
            <ArrowSVG fill='#fff' />
          </Flex>
        </Button>
        <Link href={nextRoute}>
          <TransText
            transKey='common:skip'
            color='#9499A2'
            fontSize='14px'
            textDecor='underline'
            fontWeight={600}
            textAlign='center'
          ></TransText>
        </Link>
      </Box>
    </>
  ) : (
    <Flex justifyContent='space-between'>
      <Spinner />
    </Flex>
  );
};
