import { Currency } from '@shared/common';
import { PaymentProvider } from '@shared/payments';

import { FirebaseFunction, useFirebaseMutation } from './useFirebase';

export const useSubscriptionMutation = () => {
  return useFirebaseMutation<
    { id: string; status?: string },
    { leadId: string; planId: string; provider: PaymentProvider; currency: Currency; payload?: Record<string, unknown> }
  >(FirebaseFunction.CREATE_SUBSCRIPTION);
};
