import { Flex, Spinner, Text } from '@chakra-ui/react';
import { generalUtils } from '@shared/common';
import Image from 'next/image';
import { FC } from 'react';

import AquariusImage from '~/assets/images/zodiacs/aquarius.jpg';
import AriesImage from '~/assets/images/zodiacs/aries.jpg';
import CancerImage from '~/assets/images/zodiacs/cancer.jpg';
import CapricornImage from '~/assets/images/zodiacs/capricorn.jpg';
import GeminiImage from '~/assets/images/zodiacs/gemini.jpg';
import LeoImage from '~/assets/images/zodiacs/leo.jpg';
import LibraImage from '~/assets/images/zodiacs/libra.jpg';
import PiscesImage from '~/assets/images/zodiacs/pisces.jpg';
import SagittariusImage from '~/assets/images/zodiacs/sagittarius.jpg';
import ScorpioImage from '~/assets/images/zodiacs/scorpio.jpg';
import TaurusImage from '~/assets/images/zodiacs/taurus.jpg';
import VirgoImage from '~/assets/images/zodiacs/virgo.jpg';
import { useAppRouter } from '~/hooks/useAppRouter';

const signToZodiacMap = {
  aries: {
    date: 'March 21 - April 19',
    image: AriesImage,
    subtitle: (
      <>
        Dear Aries, in 2024, the stars align to invigorate your energetic spirit. This year, channel your boundless
        energy into nurturing your physical and emotional health.
      </>
    ),
    description: (
      <>
        <b>Physical Health</b> Your physical vigor is at a peak. Embrace activities that challenge your strength and
        endurance. Remember to balance high-impact exercises with restorative practices to avoid overexertion.
      </>
    ),
    emotion: (
      <>
        <b>Emotional Health</b> As a natural leader, managing your emotional health is crucial. Practice mindfulness and
        seek out activities that calm your fiery temperament, such as meditation or peaceful nature walks.
      </>
    ),
    stress: (
      <>
        <b>Stress Management</b> Your go-getter attitude might lead to stress. Find release in quick, high-intensity
        workouts, which are excellent outlets for high energy.
      </>
    ),
    diet: (
      <>
        <b>Diet and Nutrition</b> Focus on a well-balanced diet. Incorporate foods rich in antioxidants and omega-3
        fatty acids, which are subtly anti-inflammatory. Stay hydrated and limit processed foods.
      </>
    ),
    excercise: (
      <>
        <b>Exercise and Fitness</b> Engage in sports or fitness routines that align with your competitive and
        adventurous nature. Regular exercise will maintain your physical health and boost your mental well-being.
      </>
    ),
    preventative: (
      <>
        <b>Preventative Measures</b> Stay proactive with regular health check-ups. Pay attention to your body,
        especially your head and muscles, areas Aries tend to push hard.
      </>
    ),
    relaxation: (
      <>
        <b>Relaxation and Well-Being</b> Find relaxation in activities that also allow for physical expression.
        Adventure sports or outdoor activities can be exhilarating and relaxing for your spirited nature.
      </>
    ),
    balace: (
      <>
        <b>Balance and Harmony</b> Strive for a balance between your professional ambitions and personal health.
        Learning to temper your innate intensity with periods of relaxation is key to your overall well-being in 2024.
      </>
    ),
    conclusion: (
      <>
        <b>Conclusion</b> 2024 is a canvas for you to paint a vibrant picture of health and vitality, Aries. By
        harmonizing your energetic pursuits with mindful relaxation, you&apos;ll find a more profound sense of
        fulfillment and strength. Embrace this year as an opportunity to fortify your physical and emotional resilience.
      </>
    ),
  },
  taurus: {
    date: 'April 20 - May 20',
    image: TaurusImage,
    subtitle: (
      <>
        Dear Taurus, 2024 beckons you to focus on maintaining and enhancing your health through steady and nurturing
        practices.
      </>
    ),
    description: (
      <>
        <b>Physical Health</b> Your physical health in 2024 will benefit from regular, consistent routines. Choose
        exercises that are grounding and strengthening, like hiking or weight training, to build your physical
        resilience.
      </>
    ),
    emotion: (
      <>
        <b>Emotional Health</b> As a Taurus, you find comfort in stability. Cultivate emotional well-being by
        establishing routines that bring you joy and comfort. Don&apos;t shy away from seeking support when emotional
        turbulence arises.
      </>
    ),
    stress: (
      <>
        <b>Stress Management</b> To manage stress, indulge in activities that connect you with nature. Gardening,
        pottery, or walking in a park can be profoundly calming and grounding.
      </>
    ),
    diet: (
      <>
        <b>Diet and Nutrition</b> Focus on a diet rich in whole foods. Natural, anti-inflammatory foods like leafy
        greens, nuts, and seeds align with your earthy nature and support your health.
      </>
    ),
    excercise: (
      <>
        <b>Exercise and Fitness</b> Consistency is critical in your fitness journey. Find an exercise you love and stick
        to it. Regularity rather than intensity will be your path to fitness in 2024.
      </>
    ),
    preventative: (
      <>
        <b>Preventative Measures</b> Regular health check-ups are essential. Pay particular attention to your throat and
        neck area, as these are often areas of concern for Taurus.
      </>
    ),
    relaxation: (
      <>
        <b>Relaxation and Well-Being</b> Embrace relaxation techniques that involve sensory pleasures, like aromatherapy
        or massage. These will cater to your Venus-ruled nature and enhance your overall well-being.
      </>
    ),
    balace: (
      <>
        <b>Balance and Harmony</b> 2024 is about finding harmony between work and personal life. Prioritize your health
        and well-being to ensure you can enjoy the fruits of your labor.
      </>
    ),
    conclusion: (
      <>
        <b>Conclusion</b> 2024 is your year to establish and reinforce routines that nurture your physical and emotional
        health. By embracing practices that align with your nature, you will find a more profound sense of peace and
        well-being, allowing you to approach life with renewed strength and vitality.
      </>
    ),
  },
  gemini: {
    date: 'May 21 - June 20',
    image: GeminiImage,
    subtitle: (
      <>
        Dear Gemini, the year 2024 invites you to embrace the multifaceted aspects of your health, reflecting the dual
        nature of your sign.
      </>
    ),
    description: (
      <>
        <b>Physical Health</b> Your naturally agile and active nature calls for a diverse and flexible exercise regimen.
        Engage in activities stimulating your body and mind, like dance or team sports.
      </>
    ),
    emotion: (
      <>
        <b>Emotional Health</b> As someone who thrives on social interactions, ensure you balance your social life with
        moments of solitude for emotional well-being. Open communication and journaling can be practical tools for
        processing thoughts and feelings.
      </>
    ),
    stress: (
      <>
        <b>Stress Management</b> Your curious mind might lead to overstimulation. Find stress relief in short, varied
        activities - a quick jog, a puzzle, or a spontaneous road trip can be refreshing.
      </>
    ),
    diet: (
      <>
        <b>Diet and Nutrition</b> A varied diet rich in different nutrients will suit your versatile nature. Incorporate
        foods that promote brain health and focus, such as omega-3-rich fish and nuts.
      </>
    ),
    excercise: (
      <>
        <b>Exercise and Fitness</b> Your exercise routine should be as varied as your interests. Mix it with different
        workouts to keep yourself engaged and motivated.
      </>
    ),
    preventative: (
      <>
        <b>Preventative Measures</b> Stay on top of your health with regular check-ups. Pay attention to your
        respiratory system, an area often susceptible to Geminis.
      </>
    ),
    relaxation: (
      <>
        <b>Relaxation and Well-Being</b> Relaxation for you might mean engaging in hobbies that keep your mind active.
        Reading, short trips, or learning new skills can be relaxing yet stimulating.
      </>
    ),
    balace: (
      <>
        <b>Balance and Harmony</b> In 2024, strive to balance your social and personal time. Managing your energy and
        giving yourself time to recharge is essential.
      </>
    ),
    conclusion: (
      <>
        <b>Conclusion</b> 2024 is a year to embrace the diverse aspects of your health. By engaging in varied activities
        and nurturing your mental and physical well-being, you will find a harmonious balance that energizes and
        fulfills you.
      </>
    ),
  },
  cancer: {
    date: 'June 21 - July 22',
    image: CancerImage,
    subtitle: (
      <>
        Dear Cancer, in 2024, the cosmos encourages you to prioritize your health and well-being like never before. As a
        natural caretaker, you often put the needs of others ahead of your own, but this year, it&apos;s time to turn
        the spotlight onto yourself.
      </>
    ),
    description: (
      <>
        <b>Physical Health</b> Your physical health will be a significant focus in 2024. Pay close attention to your
        body&apos;s signals and make a habit of regular check-ups. Incorporate a well-balanced diet, regular exercise,
        and adequate sleep into your daily routine. Consider exploring holistic health practices such as yoga or
        meditation to enhance your well-being.
      </>
    ),
    emotion: (
      <>
        <b>Emotional Health</b> Your emotional health is just as crucial as your physical well-being. This year, address
        any lingering emotional issues that may have been affecting you. Seek therapy or counseling if needed. Surround
        yourself with a supportive network of friends and loved ones who understand your sensitive nature.
      </>
    ),
    stress: (
      <>
        <b>Stress Management</b> You&apos;re known for your nurturing qualities, which can sometimes lead to stress and
        burnout—practice self-care by setting boundaries and learning to say no when necessary. Find healthy outlets for
        anxiety, such as journaling, creative pursuits, or spending time in nature.
      </>
    ),
    diet: (
      <>
        <b>Diet and Nutrition</b> Pay attention to your diet in 2024. Incorporate more fruits and vegetables into your
        meals and reduce processed foods. Stay hydrated, and consider consulting a nutritionist to create a personalized
        dietary plan that suits your specific needs.
      </>
    ),
    excercise: (
      <>
        <b>Exercise and Fitness</b> Engage in regular physical activity that you enjoy. Whether swimming, dancing or
        taking long walks, find an exercise routine that makes you feel good. Physical fitness will boost your energy
        levels and overall vitality.
      </>
    ),
    preventative: (
      <>
        <b>Preventative Measures</b> Consider preventive health measures. Stay up-to-date with vaccinations and
        screenings, and don&apos;t ignore any unusual symptoms or discomfort. Early detection can be a lifesaver.
      </>
    ),
    relaxation: (
      <>
        <b>Relaxation and Well-Being</b> Prioritize relaxation and self-care. Schedule regular spa days, indulge in your
        favorite hobbies, and find moments of solitude to recharge your emotional batteries.
      </>
    ),
    balace: (
      <>
        <b>Balance and Harmony</b> In 2024, strive to balance your responsibilities and personal life. Remember that
        taking care of yourself allows you to better care for others.
      </>
    ),
    conclusion: (
      <>
        <b>Conclusion</b> The year 2024 allows you to nurture and prioritize your health. By tending to your physical
        and emotional well-being, you&apos;ll find the strength to navigate life&apos;s challenges and enjoy a year of
        balance, harmony, and vitality. Embrace self-care; you&apos;ll be better equipped to support those around you
        with love and compassion.
      </>
    ),
  },
  leo: {
    date: 'July 23 - August 22',
    image: LeoImage,
    subtitle: (
      <>
        Dear Leo, 2024 shines a spotlight on your health, urging you to radiate wellness in all aspects of life. Your
        natural charisma and strength will be your guiding stars.
      </>
    ),
    description: (
      <>
        <b>Physical Health</b> This year, your physical health is in the limelight. Engage in activities that allow you
        to shine, like dance or team sports. Balance these with routine health checks to keep your heart and ruling
        organ in top condition.
      </>
    ),
    emotion: (
      <>
        <b>Emotional Health</b> Your majestic nature craves recognition, but remember also to nurture your inner self.
        Practice self-affirmation and engage in activities that boost your self-esteem and emotional well-being.
      </>
    ),
    stress: (
      <>
        <b>Stress Management</b> As a natural leader, stress can stem from over-committing. Learn to delegate and take
        time for self-care. Activities that bring joy and relaxation, like attending a performance or a leisurely day
        out, can be practical stress relievers.
      </>
    ),
    diet: (
      <>
        <b>Diet and Nutrition</b> Your diet in 2024 should be as bold and vibrant as you are. Include a variety of
        colorful fruits and vegetables, which are visually appealing, rich in nutrients, and have anti-inflammatory
        properties.
      </>
    ),
    excercise: (
      <>
        <b>Exercise and Fitness</b> Your fitness routine should be as dynamic as your personality. Mix cardio with
        strength training to keep your routine exciting and compelling.
      </>
    ),
    preventative: (
      <>
        <b>Preventative Measures</b> Pay attention to your spine and heart health. Regular check-ups and a balanced
        lifestyle are essential to preventing health issues.
      </>
    ),
    relaxation: (
      <>
        <b>Relaxation and Well-Being</b> Indulge in activities that make you feel pampered and appreciated. Spa days,
        theater visits, or a simple evening with loved ones can rejuvenate your spirit.
      </>
    ),
    balace: (
      <>
        <b>Balance and Harmony</b> Strive for a balance between your personal desires and professional responsibilities.
        Remember, self-care isn&apos;t selfish; it&apos;s essential for maintaining your natural radiance.
      </>
    ),
    conclusion: (
      <>
        <b>Conclusion</b> 2024 is a year to shine in your health and well-being. Embrace practices that make you feel
        vibrant and alive. By caring for yourself, you ensure that your natural warmth and vitality continue to inspire
        and uplift those around you.
      </>
    ),
  },
  virgo: {
    date: 'August 23 - September 22',
    image: VirgoImage,
    subtitle: (
      <>
        Dear Virgo, in 2024, your inherent need for order and perfection finds a constructive outlet in managing your
        health and well-being.
      </>
    ),
    description: (
      <>
        <b>Physical Health</b> Attention to detail is your strength. Use it to fine-tune your physical health. Regular
        exercise, especially routines that require precision, like Pilates, will benefit you. Be mindful of your
        digestive system, which can be sensitive to Virgos.
      </>
    ),
    emotion: (
      <>
        <b>Emotional Health</b> Your analytical mind can sometimes lead to overthinking, affecting your emotional
        health. Practice mindfulness and relaxation techniques to keep anxiety at bay. Journaling can be a therapeutic
        outlet for your thoughts.
      </>
    ),
    stress: (
      <>
        <b>Stress Management</b> Your meticulous nature might cause stress. Balance your workload with activities that
        relax your mind, like gardening or crafting.
      </>
    ),
    diet: (
      <>
        <b>Diet and Nutrition</b> A balanced, nutritious diet is vital. Incorporate whole foods and consider a diet rich
        in fiber and low in processed ingredients to support your digestive health.
      </>
    ),
    excercise: (
      <>
        <b>Exercise and Fitness</b> Routine and discipline in your fitness regime will yield the best results. Consider
        activities that engage your mind, like strategic sports or hiking in nature.
      </>
    ),
    preventative: (
      <>
        <b>Preventative Measures</b> Regular health check-ups, particularly for your digestive and nervous systems, are
        important. Stay vigilant about any unusual symptoms.
      </>
    ),
    relaxation: (
      <>
        <b>Relaxation and Well-Being</b> Find relaxation in activities that bring a sense of order and accomplishment,
        such as organizing or DIY projects.
      </>
    ),
    balace: (
      <>
        <b>Balance and Harmony</b> In 2024, seek to balance your innate need for perfection with the acceptance that
        some things are beyond your control. This equilibrium will bring peace and enhance your well-being.
      </>
    ),
    conclusion: (
      <>
        <b>Conclusion</b> 2024 is about fine-tuning your life for optimal health and well-being. By focusing on creating
        a balanced lifestyle, you&apos;ll find both physical and emotional harmony, allowing you to approach life with
        renewed vigor and clarity.
      </>
    ),
  },
  libra: {
    date: 'September 23 - October 22',
    image: LibraImage,
    subtitle: (
      <>
        Dear Libra, in 2024, your journey is about creating a beautiful balance in your health, reflecting the harmony
        you cherish.
      </>
    ),
    description: (
      <>
        <b>Physical Health</b> Your affinity for balance finds expression in maintaining a consistent exercise regimen.
        Activities that require symmetry and grace, like dancing or yoga, can be particularly beneficial for your
        physical well-being.
      </>
    ),
    emotion: (
      <>
        <b>Emotional Health</b> As a natural mediator, you often absorb others&apos; emotions. This year, focus on
        building emotional resilience and finding inner peace. Activities like meditation or journaling can help you
        process and balance your emotions.
      </>
    ),
    stress: (
      <>
        <b>Stress Management</b> Your desire for harmony may lead to stress when faced with conflict. Practice
        stress-reducing techniques like deep breathing or mindfulness to maintain your inner calm.
      </>
    ),
    diet: (
      <>
        <b>Diet and Nutrition</b> A balanced diet is vital for you. Incorporate a variety of nutrients, focusing on
        fresh, whole foods. This approach not only nourishes your body but also aligns with anti-inflammatory benefits.
      </>
    ),
    excercise: (
      <>
        <b>Exercise and Fitness</b> Regular, moderate exercise will keep you feeling your best. Find a routine that you
        enjoy and can share with others, as companionship in fitness can be particularly rewarding for you.
      </>
    ),
    preventative: (
      <>
        <b>Preventative Measures</b> Regular health check-ups, especially for your kidneys and skin, are important. Pay
        attention to balance in your body&apos;s systems.
      </>
    ),
    relaxation: (
      <>
        <b>Relaxation and Well-Being</b> Indulge in activities that bring you aesthetic pleasure and relaxation, like
        visiting art galleries or enjoying music, to nourish your soul.
      </>
    ),
    balace: (
      <>
        <b>Balance and Harmony</b> In 2024, strive to balance your social obligations with personal health. Remember,
        nurturing yourself allows you to bring more beauty and harmony.
      </>
    ),
    conclusion: (
      <>
        <b>Conclusion</b> 2024 is your year to find the perfect balance in your health and well-being. By embracing
        practices that align with your love for harmony, you will find a more profound sense of peace and vitality,
        enhancing your ability to spread beauty and balance in every aspect of life.
      </>
    ),
  },
  scorpio: {
    date: 'October 23 - November 21',
    image: ScorpioImage,
    subtitle: (
      <>
        Dear Scorpio, 2024 calls you to dive deep into the waters of health and transformation, exploring the depths of
        your physical and emotional well-being.
      </>
    ),
    description: (
      <>
        <b>Physical Health</b> Your intense nature thrives when you challenge yourself physically. High-intensity
        workouts or sports that require strategic thinking can be particularly beneficial. Be mindful of your
        reproductive and excretory systems, as these can be areas of concern for Scorpios.
      </>
    ),
    emotion: (
      <>
        <b>Emotional Health</b> Your emotional depth is a source of strength. This year, channel your intensity into
        understanding and healing deep-seated emotional issues. Practices like therapy or deep meditation can be
        transformative.
      </>
    ),
    stress: (
      <>
        <b>Stress Management</b> Your all-or-nothing approach can sometimes lead to stress. Find balance through
        activities that allow for emotional release, such as martial arts or intense physical exercise.
      </>
    ),
    diet: (
      <>
        <b>Diet and Nutrition</b> Focus on a diet that fuels your transformative journey. Foods rich in antioxidants and
        healthy fats can support energy levels and provide anti-inflammatory benefits.
      </>
    ),
    excercise: (
      <>
        <b>Exercise and Fitness</b> Engage in exercises that challenge both your body and mind. Your fitness regime
        should reflect your passion for depth and transformation.
      </>
    ),
    preventative: (
      <>
        <b>Preventative Measures</b> Regular health screenings are important, especially for hormonal balance and blood
        health.
      </>
    ),
    relaxation: (
      <>
        <b>Relaxation and Well-Being</b> Find relaxation in activities that allow for introspection, like journaling or
        solo outdoor activities. This solitude can be rejuvenating for your soul.
      </>
    ),
    balace: (
      <>
        <b>Balance and Harmony</b> In 2024, strive to balance your intense nature with moments of calm and rejuvenation.
        Understanding that rest is as important as activity will be key to your well-being.
      </>
    ),
    conclusion: (
      <>
        <b>Conclusion</b> 2024 is a year of profound health and emotional transformation. Embrace the journey of
        deepening your understanding of yourself and your health. By nurturing your physical and emotional depths, you
        will emerge more robust and resilient, ready to face the challenges and joys that life brings.
      </>
    ),
  },
  sagittarius: {
    date: 'November 22 - December 21',
    image: SagittariusImage,
    subtitle: (
      <>
        Dear Sagittarius, 2024 is a year of adventure and exploration in health and well-being. Your natural zest for
        life and learning will be your guiding light.
      </>
    ),
    description: (
      <>
        <b>Physical Health</b> Your love for exploration can be channeled into trying new forms of physical activity.
        Consider outdoor sports or travel that involves physical challenges. Be mindful of your hips and thighs, as
        these areas can be prone to strain.
      </>
    ),
    emotion: (
      <>
        <b>Emotional Health</b> Your optimistic outlook is a boon, but focus on deeper emotional introspection. Take
        time to understand and address your emotional needs, perhaps through travel or spiritual exploration.
      </>
    ),
    stress: (
      <>
        <b>Stress Management</b> Your adventurous spirit may sometimes lead to overextension. Find stress relief in
        activities that offer learning experiences, like hiking in new terrains or learning martial arts.
      </>
    ),
    diet: (
      <>
        <b>Diet and Nutrition</b> Your diet in 2024 should reflect your adventurous spirit. Experiment with
        international cuisines that offer a variety of nutrients, focusing on fresh and whole foods for a naturally
        anti-inflammatory effect.
      </>
    ),
    excercise: (
      <>
        <b>Exercise and Fitness</b> Engage in a fitness routine that is as dynamic and varied as your interests. This
        will not only keep you physically fit but also mentally stimulated.
      </>
    ),
    preventative: (
      <>
        <b>Preventative Measures</b> Regular health check-ups, particularly for your liver and thighs, are essential.
        Pay attention to your body&apos;s signals, especially when engaging in new or strenuous activities.
      </>
    ),
    relaxation: (
      <>
        <b>Relaxation and Well-Being</b> Find relaxation in new experiences and adventures. Whether it&apos;s a retreat
        or a spontaneous road trip, the change of scenery can be revitalizing.
      </>
    ),
    balace: (
      <>
        <b>Balance and Harmony</b> In 2024, strive to balance your love for adventure and the need for rest. Remember,
        restorative practices are as important as active pursuits.
      </>
    ),
    conclusion: (
      <>
        <b>Conclusion</b> Embrace 2024 as a year to expand your horizons in health. By exploring new physical and
        emotional well-being avenues, you&apos;ll enrich your life with diverse experiences and vibrant health. Your
        journey is about discovering the joy in wellness and the wellness in pleasure.
      </>
    ),
  },
  capricorn: {
    date: 'December 22 - January 19',
    image: CapricornImage,
    subtitle: (
      <>
        Dear Capricorn, 2024 is a year to build strong foundations in your health and wellness journey. Your inherent
        discipline and ambition will serve you well in this endeavor.
      </>
    ),
    description: (
      <>
        <b>Physical Health</b> As someone who values structure, create a consistent and challenging exercise routine.
        Activities that require endurance, like long-distance running or hiking, will be beneficial. Be mindful of your
        knees and bones, ensuring you get enough calcium and vitamin D.
      </>
    ),
    emotion: (
      <>
        <b>Emotional Health</b> Your pragmatic nature is an asset, but pay attention to the importance of emotional
        health. Engage in practices that help you connect with your emotions, such as mindfulness or counseling.
      </>
    ),
    stress: (
      <>
        <b>Stress Management</b> Your ambitious goals can sometimes lead to stress. Balance your work ethic with
        relaxation techniques that ground you, like gardening or reading.
      </>
    ),
    diet: (
      <>
        <b>Diet and Nutrition</b> A structured diet plan works well for you. Focus on nutrient-rich foods, particularly
        those that support bone and joint health. Anti-inflammatory foods can also be incorporated seamlessly into your
        meals.
      </>
    ),
    excercise: (
      <>
        <b>Exercise and Fitness</b> Regular, disciplined exercise routines will help you maintain peak physical
        condition. Consider incorporating weight training to support bone health.
      </>
    ),
    preventative: (
      <>
        <b>Preventative Measures</b> Regular health screenings are crucial, especially for bone density and joint
        health. Be proactive in addressing any health concerns early.
      </>
    ),
    relaxation: (
      <>
        <b>Relaxation and Well-Being</b> Find relaxation in activities that also give you a sense of accomplishment,
        like completing a challenging hike or mastering a new skill.
      </>
    ),
    balace: (
      <>
        <b>Balance and Harmony</b> In 2024, strive to balance your professional ambitions with your health needs.
        Remember, taking time for self-care is essential for long-term success.
      </>
    ),
    conclusion: (
      <>
        <b>Conclusion</b> 2024 is about laying solid and healthy foundations for your future. By focusing on building a
        balanced and structured approach to your health, you set the stage for a lifetime of well-being and achievement.
        Embrace this year as an opportunity to fortify your body and mind, ensuring you are well-equipped for the
        challenges and triumphs ahead.
      </>
    ),
  },
  aquarius: {
    date: 'January 20 - February 18',
    image: AquariusImage,
    subtitle: (
      <>
        Dear Aquarius, in 2024, your unique perspective and innovative spirit will play a significant role in your
        approach to health and wellness.
      </>
    ),
    description: (
      <>
        <b>Physical Health</b> Your unconventional nature may draw you to unique forms of exercise, like aerial yoga or
        futuristic fitness technology. Listen to your body and be mindful of your ankles and circulatory system, which
        can be sensitive for Aquarians.
      </>
    ),
    emotion: (
      <>
        <b>Emotional Health</b> It&apos;s important to connect deeply with your emotions as an intellectual sign.
        Explore new forms of emotional expression, such as through art therapy or innovative mindfulness practices.
      </>
    ),
    stress: (
      <>
        <b>Stress Management</b> Your tendency to ponder the future can lead to stress. Engage in activities that ground
        you in the present, like gardening or crafting.
      </>
    ),
    diet: (
      <>
        <b>Diet and Nutrition</b> Experiment with your diet in a health-conscious and environmentally sustainable way.
        Consider plant-based options that align with both your humanitarian values and anti-inflammatory benefits.
      </>
    ),
    excercise: (
      <>
        <b>Exercise and Fitness</b> Incorporate various unconventional and stimulating exercises into your routine to
        keep yourself engaged and motivated.
      </>
    ),
    preventative: (
      <>
        <b>Preventative Measures</b> Regular health check-ups are critical, mainly focusing on your circulatory and
        nervous systems. Stay ahead with the latest health and wellness trends, applying what resonates with your
        lifestyle.
      </>
    ),
    relaxation: (
      <>
        <b>Relaxation and Well-Being</b> Find relaxation in intellectual pursuits and social activism. Engaging in
        community work or learning new skills can be as rejuvenating for you as traditional relaxation techniques.
      </>
    ),
    balace: (
      <>
        <b>Balance and Harmony</b> In 2024, strive to balance your innovative ideas with practical health habits.
        Integrating futuristic concepts with traditional wellness practices will bring a unique harmony to your life.
      </>
    ),
    conclusion: (
      <>
        <b>Conclusion</b> 2024 is your year to revolutionize your approach to health. By embracing innovative practices
        and maintaining a balanced perspective, you will navigate the year with enhanced physical and emotional
        well-being, ready to positively impact yourself and the world around you.
      </>
    ),
  },
  pisces: {
    date: 'February 19 - March 20',
    image: PiscesImage,
    subtitle: (
      <>
        Dear Pisces, 2024 invites you to flow with the intuitive currents of your health and well-being, embracing the
        fluid nature of your sign.
      </>
    ),
    description: (
      <>
        <b>Physical Health</b> Your physical health in 2024 benefits from gentle, fluid movements. Water-based
        activities like swimming or aqua aerobics align perfectly with your nature. Be mindful of your feet, which can
        be a sensitive area for Pisces.
      </>
    ),
    emotion: (
      <>
        <b>Emotional Health</b> Your deep emotional sensitivity is a gift. This year, focus on nurturing your emotional
        well-being through creative outlets like music, art, or writing.
      </>
    ),
    stress: (
      <>
        <b>Stress Management</b> As a sign that feels deeply, finding healthy ways to manage stress is essential.
        Engaging in activities that connect you to the natural world, like walks by the sea or gardening, can be
        particularly soothing.
      </>
    ),
    diet: (
      <>
        <b>Diet and Nutrition</b> Your diet in 2024 should be as fluid and varied as your tastes. Incorporate a range of
        nutrient-rich foods, focusing on hydrating fruits and vegetables with anti-inflammatory benefits.
      </>
    ),
    excercise: (
      <>
        <b>Exercise and Fitness</b> Choose exercise routines that feel more like a dance than a workout. Your fitness
        journey should be enjoyable and in tune with your body&apos;s needs.
      </>
    ),
    preventative: (
      <>
        <b>Preventative Measures</b> Regular check-ups, mainly focusing on your immune system and feet, are important.
        Pay attention to your body&apos;s subtle signals and respond intuitively.
      </>
    ),
    relaxation: (
      <>
        <b>Relaxation and Well-Being</b> Find relaxation in activities that allow you to escape into your rich inner
        world. Meditation, music, or spending time near water can be deeply rejuvenating for you.
      </>
    ),
    balace: (
      <>
        <b>Balance and Harmony</b> In 2024, seek a balance between your dreamy nature and the practical aspects of
        maintaining good health. Embracing a routine that nurtures both your body and soul will bring harmony to your
        life.
      </>
    ),
    conclusion: (
      <>
        <b>Conclusion</b> 2024 is a year to embrace the ebb and flow of your health journey. You&apos;ll navigate the
        year with grace and vitality by tuning into your intuitive understanding of what your body and mind need.
        Embrace this time to connect with yourself deeply, and you&apos;ll find a harmonious rhythm in your health and
        overall well-being.
      </>
    ),
  },
};

type Zodiac =
  | 'aries'
  | 'taurus'
  | 'gemini'
  | 'cancer'
  | 'leo'
  | 'virgo'
  | 'libra'
  | 'scorpio'
  | 'sagittarius'
  | 'capricorn'
  | 'aquarius'
  | 'pisces';

export const ZodiacHeader = () => {
  const { query } = useAppRouter();
  const zodiac = query.zodiac as Zodiac;
  const info = signToZodiacMap[zodiac];

  return info ? (
    <>
      <Text
        fontSize={{ base: '32px', md: '56px' }}
        fontWeight={900}
        fontFamily='var(--cabinetGroteskFont)'
        lineHeight='100%'
        marginBottom={{ base: '0.5rem', md: '1rem' }}
      >
        {generalUtils.capitalizeFirstLetter(zodiac)}
      </Text>
      <Text fontSize='18px' color='#3FA134' marginBottom={{ base: '1.5rem', md: '2rem' }}>
        {info.date}
      </Text>
      <Flex
        position='relative'
        borderRadius='1rem'
        overflow='hidden'
        paddingBottom='60%'
        width='100%'
        marginBottom={{ base: '1.5rem', md: '2rem' }}
        bg='#eee'
      >
        <Image src={info.image} fill alt='' style={{ objectFit: 'cover' }} priority placeholder='blur' />
      </Flex>
      <Text marginBottom={{ base: '1.5rem', md: '2rem' }} fontSize='18px'>
        {info.subtitle}
      </Text>
      <Text fontSize='18px' marginBottom={{ base: '1.5rem', md: '2rem' }}>
        {info.description}
      </Text>
    </>
  ) : (
    <Flex
      justifyContent='center'
      alignItems='center'
      marginBottom={{ base: '1.5rem', md: '2rem' }}
      paddingBottom='100%'
      width='100%'
    >
      <Spinner />
    </Flex>
  );
};

export const ZodiacCopy: FC<{
  section: 'emotion' | 'stress' | 'diet' | 'excercise' | 'preventative' | 'relaxation' | 'balace' | 'conclusion';
}> = ({ section }) => {
  const { query } = useAppRouter();
  const zodiac = query.zodiac as Zodiac;
  const info = signToZodiacMap[zodiac];

  return info ? (
    <Text marginBottom={{ base: '1.5rem', md: '2rem' }} fontSize='18px'>
      {info[section]}
    </Text>
  ) : null;
};
