import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react';
import { generalUtils } from '@shared/common';
import Link from 'next/link';
import { useEffect, useState } from 'react';
// import Link from 'next/link';

import ArrowSVG from '~/assets/icons/arrow.svg';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useProducts } from '~/hooks/useProducts';

import { UpsellButton } from './UpsellButton';

export const MealMasterclassSubscriptionUpsell = () => {
  const nextRoute = '/upsells/meditations';
  const { selectedPlan } = useAppLead();
  const { findProductPrice, products } = useProducts();
  const currentPlanInterval = selectedPlan?.plan?.interval || 6;
  const product = products.find(
    (prod) => prod.isUpsell && prod.plan.interval === currentPlanInterval && /cooking/.test(prod.key),
  );
  const { currency, finalPrice, price } = findProductPrice(product);
  const { pushNextRoute, flowName } = useAppRouter(nextRoute);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window?.addEventListener('scroll', () => {
      setIsVisible(window.pageYOffset > 1200);
    });

    return window.removeEventListener('scroll', () => {});
  }, []);

  return product ? (
    <>
      <Box
        width='100%'
        bg='rgba(255, 255, 255, 0.8)'
        border='2px solid rgba(63, 161, 52, 0.40)'
        borderRadius='0.5rem'
        padding={{ base: '1rem 1rem 0.5rem 1rem', md: '1.5rem 1.5rem 0.5rem 1.5rem' }}
        marginBottom='1rem'
      >
        <Flex
          gap='2.5rem'
          justifyContent='center'
          alignItems='center'
          borderBottom='1px solid #AED9A9'
          marginBottom='0.5rem'
          paddingBottom='1rem'
        >
          <Box>
            <Text color='#233E20' fontSize='20px'>
              <b>Only</b>{' '}
              <Box as='span' fontSize='40px' fontWeight={700}>
                {generalUtils.formatPrice(finalPrice / currentPlanInterval, currency, 3)}
              </Box>{' '}
              <Box as='span' fontSize='16px' color='rgba(35, 62, 32, 0.64)'>
                /month
              </Box>
            </Text>
          </Box>
          <Box>
            <Text
              fontWeight={700}
              fontSize='14px'
              color='white'
              padding='0.25rem 0.5rem'
              bg='#C73E1D'
              borderRadius='0.25rem'
              lineHeight='100%'
              textAlign='center'
              marginBottom='6px'
            >
              75% OFF
            </Text>

            <Text textDecor='line-through' color='#C73E1D'>
              {`${generalUtils.formatPrice(price / currentPlanInterval, currency, 2)}/month`}
            </Text>
          </Box>
        </Flex>
        <Text textAlign='center' fontSize='12px' fontWeight={600} textTransform='uppercase' color='#3FA134'>
          7946 students enrolled
        </Text>
      </Box>
      <Text color='rgba(35, 62, 32, 0.56)' marginBottom='1rem' fontSize='14px'>
        {`charged for the period of your selected plan, you'll be charged ${generalUtils.formatPrice(
          finalPrice,
          currency,
          2,
        )} today for ${currentPlanInterval} ${currentPlanInterval === 1 ? 'mont' : 'months'}. Cancel anytime.`}
      </Text>
      <Box marginBottom='0.5rem' width='100%'>
        <UpsellButton text='Join The Cooking Masterclass Now' product={product} onSuccess={pushNextRoute} />
      </Box>
      <Link
        style={{ textDecoration: 'underline', fontWeight: 600, color: '#9499A2', fontSize: '14px' }}
        href={flowName ? { pathname: nextRoute, query: { flowName } } : nextRoute}
      >
        Skip
      </Link>
      <Box
        display={{ base: isVisible ? 'block' : 'none', md: 'none' }}
        position='fixed'
        bottom='0'
        left='0'
        right='0'
        bg='white'
        padding='0.5rem'
        zIndex={999}
      >
        <Button
          width='100%'
          marginBottom='0.75rem'
          onClick={() => {
            generalUtils.scrollToElement('plan-selection');
          }}
          gap='1rem'
        >
          <Text color='white'>Join The Cooking Masterclass Now</Text>
          <Flex>
            <ArrowSVG fill='#fff' />
          </Flex>
        </Button>
        <Link href={nextRoute}>
          <Text color='#9499A2' fontSize='14px' textDecor='underline' fontWeight={600} textAlign='center'>
            Skip
          </Text>
        </Link>
      </Box>
    </>
  ) : (
    <Flex justifyContent='space-between'>
      <Spinner />
    </Flex>
  );
};
