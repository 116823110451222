import { Box, Flex, Text } from '@chakra-ui/react';
import { formulas } from '@shared/common';

import CircleActivitySVG from '~/assets/icons/circle_activity.svg';
import CircleExerciseSVG from '~/assets/icons/circle_exerice.svg';
import CircleLifestyleSVG from '~/assets/icons/circle_lifestyle.svg';
import BMIProgressSVG from '~/assets/icons/quiz/bmi_progress.svg';
import { useAppLead } from '~/hooks/useAppLead';

export const CortisolScoreGrid = () => {
  const { quizAnswers } = useAppLead();
  const workout = quizAnswers?.workout || '';
  const typicalDay = quizAnswers?.typical_day || '';
  const { category } = formulas.calcBMI(Number(quizAnswers?.weight), Number(quizAnswers?.height));

  return (
    <>
      <Box
        width='100%'
        borderRadius='0.5rem'
        padding={{ base: '1rem', md: '1.5rem' }}
        backgroundColor='#fff'
        marginBottom='1.5rem'
        border='1px solid rgba(0, 0, 0, 0.1)'
      >
        <Text fontSize='24px' fontWeight={700}>
          Cortisol level
        </Text>
        <Text color='#FD6A78' fontWeight={700} marginBottom='1rem' fontSize='16px'>
          High (87.2/100)
        </Text>
        <Flex marginLeft='86%' display='inline-flex' flexDirection='column' marginBottom='-0.125rem'>
          <Flex
            display='block'
            padding='0.5rem'
            lineHeight='100%'
            backgroundColor='system.white'
            borderRadius='0.25rem'
            boxShadow='0px 4px 16px 0px rgba(0, 0, 0, 0.16)'
            fontSize='12px'
            fontWeight={700}
          >
            YOU
          </Flex>
          <Flex position='relative' top='-1px' zIndex={1} justifyContent='center'>
            <svg xmlns='http://www.w3.org/2000/svg' width='20' height='7' viewBox='0 0 20 7' fill='none'>
              <path
                d='M12.4988 6.00097C11.0379 7.16967 8.96212 7.16968 7.50124 6.00098L0 -1.74846e-06L20 0L12.4988 6.00097Z'
                fill='white'
              />
            </svg>
          </Flex>
        </Flex>
        <BMIProgressSVG />
        <Flex justifyContent='space-between' textAlign='center'>
          <Text fontSize={{ base: '11px', md: '12px' }} fontWeight={400} color={'#9DA2B0'}>
            Low
            <br />
            {`0-19`}
          </Text>
          <Text fontSize={{ base: '11px', md: '12px' }} fontWeight={400} color={'#9DA2B0'}>
            Normal:
            <br />
            20 - 45
          </Text>
          <Text fontSize={{ base: '11px', md: '12px' }} fontWeight={400} color={'#9DA2B0'}>
            Elevated:
            <br />
            46 - 72
          </Text>
          <Text fontSize={{ base: '11px', md: '12px' }} fontWeight={700} color='#FD6A78'>
            High:
            <br />
            73 - 100
          </Text>
        </Flex>
      </Box>
      <Flex width='100%' justifyContent='space-between' textAlign='center' gap='0.5rem'>
        <Flex flexDir='column' alignItems='center' gap='0.5rem'>
          <Flex>
            <CircleLifestyleSVG />
          </Flex>
          <Box>
            <Text fontSize='14px'>Lifestyle</Text>
            <Text fontSize='16px' fontFamily='var(--cabinetGroteskFont)' lineHeight='120%' fontWeight={700}>
              {/always|activity/.test(typicalDay) ? `Change seeker` : 'Slow'}
            </Text>
          </Box>
        </Flex>
        <Flex flexDir='column' alignItems='center' gap='0.5rem'>
          <Flex>
            <CircleExerciseSVG />
          </Flex>
          <Box>
            <Text fontSize='14px'>BMI</Text>
            <Text fontSize='16px' fontFamily='var(--cabinetGroteskFont)' lineHeight='120%' fontWeight={700}>
              {category}
            </Text>
          </Box>
        </Flex>
        <Flex flexDir='column' alignItems='center' gap='0.5rem'>
          <Flex>
            <CircleActivitySVG />
          </Flex>
          <Box>
            <Text fontSize='14px'>Activity level</Text>
            <Text fontSize='16px' fontFamily='var(--cabinetGroteskFont)' lineHeight='120%' fontWeight={700}>
              {/5/.test(workout) ? 'High' : /no|walks/.test(workout) ? 'Low' : 'Medium'}
            </Text>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};
