import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import { formulas } from '@shared/common';

import CircleActivitySVG from '~/assets/icons/circle_activity.svg';
import CircleAgeSVG from '~/assets/icons/circle_age.svg';
import CircleCurrentWeightSVG from '~/assets/icons/circle_current_weight.svg';
import CircleExerciseSVG from '~/assets/icons/circle_exerice.svg';
import CircleHeightSVG from '~/assets/icons/circle_height.svg';
import CircleLifestyleSVG from '~/assets/icons/circle_lifestyle.svg';
import CircleTargetWeightSVG from '~/assets/icons/circle_target_weight.svg';
import { BMIChart } from '~/components/BMIChart';

import { useAppLead } from '../hooks/useAppLead';

export const PersonalSummaryExtended = () => {
  const { quizAnswers } = useAppLead();
  const isMetric = quizAnswers?.isMetric;
  // const symptoms = quizAnswers?.symptoms || 'none';
  // const allergies = quizAnswers?.allergies || 'none';
  // const habits = quizAnswers?.bad_habits || 'none_of_the_above';
  const workout = quizAnswers?.workout || '';
  const typicalDay = quizAnswers?.typical_day || '';

  return (
    <Box width='100%'>
      <Grid
        gridTemplateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }}
        gap={{ base: '1rem', md: '2rem' }}
        textAlign={{ base: 'inherit', md: 'center' }}
        marginBottom={{ base: '1rem', md: '2rem' }}
      >
        <Flex
          gap='0.5rem'
          flexDir={{ base: 'row', md: 'column' }}
          alignItems='center'
          padding='1rem'
          borderRadius='0.5rem'
          backgroundColor='#fff'
        >
          <Flex justifyContent='center'>
            <CircleAgeSVG />
          </Flex>
          <Box flex={1}>
            <Text fontWeight={900} fontFamily='var(--cabinetGroteskFont)' fontSize='28px'>
              {quizAnswers?.age || 21}
            </Text>
            <Text fontSize={{ base: '12px', md: '1rem' }}>Age</Text>
          </Box>
        </Flex>
        <Flex
          gap='0.5rem'
          flexDir={{ base: 'row', md: 'column' }}
          alignItems='center'
          padding='1rem'
          borderRadius='0.5rem'
          backgroundColor='#fff'
        >
          <Flex justifyContent='center'>
            <CircleHeightSVG />
          </Flex>
          <Box>
            <Text fontWeight={900} fontFamily='var(--cabinetGroteskFont)' fontSize='28px'>
              {isMetric
                ? formulas.inchesToCm(Number(quizAnswers?.height)).toFixed(0)
                : `${Number(formulas.inchesToFeets(Number(quizAnswers?.height)).toFixed(0))}'${(
                    Number(quizAnswers?.height) -
                    Number(formulas.inchesToFeets(Number(quizAnswers?.height)).toFixed(0)) * 12
                  ).toFixed(0)}''`}
            </Text>
            <Text fontSize={{ base: '12px', md: '1rem' }}>Height</Text>
          </Box>
        </Flex>
        <Flex
          gap='0.5rem'
          flexDir={{ base: 'row', md: 'column' }}
          alignItems='center'
          padding='1rem'
          borderRadius='0.5rem'
          backgroundColor='#fff'
        >
          <Flex justifyContent='center'>
            <CircleCurrentWeightSVG />
          </Flex>
          <Box flex={1}>
            <Text fontWeight={900} fontFamily='var(--cabinetGroteskFont)' fontSize='28px'>
              {isMetric ? formulas.lbsToKg(Number(quizAnswers?.weight)).toFixed(0) : Number(quizAnswers?.weight)}
            </Text>
            <Text fontSize={{ base: '12px', md: '1rem' }}>Current weight</Text>
          </Box>
        </Flex>
        <Flex
          gap='0.5rem'
          flexDir={{ base: 'row', md: 'column' }}
          alignItems='center'
          padding='1rem'
          borderRadius='0.5rem'
          backgroundColor='#fff'
        >
          <Flex justifyContent='center'>
            <CircleTargetWeightSVG />
          </Flex>
          <Box flex={1}>
            <Text fontWeight={900} fontFamily='var(--cabinetGroteskFont)' fontSize='28px'>
              {isMetric
                ? formulas.lbsToKg(Number(quizAnswers?.targetWeight)).toFixed(0)
                : Number(quizAnswers?.targetWeight)}
            </Text>
            <Text fontSize={{ base: '12px', md: '1rem' }}>Target weight</Text>
          </Box>
        </Flex>
      </Grid>
      <Grid
        gridTemplateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
        gap={{ base: '1rem', md: '2rem' }}
        marginBottom={{ base: '1rem', md: '2rem' }}
      >
        <BMIChart />

        <Flex flexDir='column' gap='0.75rem' borderRadius='0.25rem' padding={{ base: '1rem', md: '1.5rem' }} bg='#fff'>
          <Flex alignItems='center' gap='1rem'>
            <Flex>
              <CircleLifestyleSVG />
            </Flex>
            <Box>
              <Text textStyle='small'>Lifestyle</Text>
              <Text fontSize='20px' fontFamily='var(--cabinetGroteskFont)' lineHeight='120%' fontWeight={700}>
                {/always|activity/.test(typicalDay) ? `Change seeker` : 'Slow'}
              </Text>
            </Box>
          </Flex>
          <Flex alignItems='center' gap='1rem'>
            <Flex>
              <CircleExerciseSVG />
            </Flex>
            <Box>
              <Text textStyle='small'>Exercise</Text>
              <Text fontSize='20px' fontFamily='var(--cabinetGroteskFont)' lineHeight='120%' fontWeight={700}>
                {/5/.test(workout) ? 'Hard exercise' : /no|walks/.test(workout) ? 'No exercise' : 'Low exercise'}
              </Text>
            </Box>
          </Flex>
          <Flex alignItems='center' gap='1rem'>
            <Flex>
              <CircleActivitySVG />
            </Flex>
            <Box>
              <Text textStyle='small'>Activity level</Text>
              <Text fontSize='20px' fontFamily='var(--cabinetGroteskFont)' lineHeight='120%' fontWeight={700}>
                {/5/.test(workout) ? 'High' : /no|walks/.test(workout) ? 'Low' : 'Medium'}
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Grid>
    </Box>
  );
};
