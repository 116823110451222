import { FirebaseFunction, useFirebaseMutation } from './useFirebase';

interface RequestData {
  provider: string;
  leadId: string;
  currency: string;
}

export const usePaymentAuthTokenMutation = () => {
  const mutation = useFirebaseMutation<{ id: string }, RequestData>(FirebaseFunction.FETCH_PAYMENT_AUTH_TOKEN);
  return {
    ...mutation,
    mutateAsync: async (variables: RequestData) => {
      const data = await mutation.mutateAsync(variables);
      return data.data;
    },
  };
};
