import { Box, Button, Flex, keyframes, Spinner, Text } from '@chakra-ui/react';
import { generalUtils, useHydration } from '@shared/common';
import { useTranslation } from 'next-i18next';

import ArrowSVG from '~/assets/icons/arrow.svg';
import { ChallengeCompletionDate } from '~/components/ChallengeCompletionDate';
import { TransText } from '~/components/TransText';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useProducts } from '~/hooks/useProducts';

const firstPulse = keyframes`
  0% {box-shadow: 0 0 0 0 #3FA134;}
  20% {box-shadow: transparent 0px 0px 0px 14px}
`;

export const PlanSelectionAnnual = () => {
  const isHydrated = useHydration();
  const { t } = useTranslation();
  const { pushNextRoute } = useAppRouter('/checkout/extended');
  const { findProductById, findProductPrice } = useProducts();
  const product = findProductById('122');
  const price = findProductPrice(product);

  const { updateUser } = useAppLead();
  const analytics = useAnalytics();

  const handleClick = () => {
    if (product) {
      updateUser({ selectedPlan: product });

      analytics.log('plans_select', { category: product.title, label: product.key });

      pushNextRoute();
    }
  };

  return isHydrated ? (
    <>
      <Box
        position='relative'
        padding={{ base: '1.25rem 1rem 1rem 1rem', md: '1.5rem 1.5rem 1rem 1.5rem' }}
        border='2px solid'
        borderRadius='0.5rem'
        width='100%'
        borderColor='#3FA135'
        backgroundColor='white'
        cursor='pointer'
        transition='all 200ms'
      >
        {product ? (
          <Flex flex={1} gap={{ base: '1.5rem', md: '1.5rem' }} justifyContent='space-between' alignItems='center'>
            <Flex justifyContent='center' position='absolute' top='-0.75rem' left='0' right='0' zIndex={10}>
              <TransText
                transKey='common:best_value_off'
                display='inline-block'
                padding='0.25rem 1rem'
                borderRadius='4px'
                backgroundColor='#FFD442'
                fontWeight={500}
                fontSize='11px'
                textAlign='center'
                color='black'
                textTransform='uppercase'
                values={{ value: 100 - Math.round((price.finalPrice / price.price) * 100) }}
              ></TransText>
            </Flex>
            <Flex gap={{ base: '1rem', md: '1.5rem' }} flex='1' alignItems='center'>
              <Box>
                <TransText
                  transKey='common:x_months_plan'
                  fontWeight={700}
                  fontSize={{ base: '20px', md: '24px' }}
                  lineHeight='100%'
                  color='primaryDark'
                  values={{
                    interval: 1,
                    count: 1,
                  }}
                  marginBottom='0.325rem'
                ></TransText>
                <TransText
                  transKey='common:x_months_for_free'
                  marginBottom={{ base: '0.25rem', md: '0.25rem' }}
                  color='black'
                  fontSize={{ base: '15px', md: '16px' }}
                  values={{ value: 11 }}
                ></TransText>
                <Flex gap='0.5rem' alignItems='center'>
                  <Text fontSize={{ base: '12px', md: '14px' }} color='#C73E1D' textDecoration='line-through'>
                    {generalUtils.formatPrice(price.price, price.currency)}
                  </Text>
                  <Text fontSize={{ base: '12px', md: '14px' }} color='rgba(5, 5, 23, 0.64)'>
                    {generalUtils.formatPrice(price.finalPrice, price.currency)}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Box textAlign='center'>
              <Text textDecoration='line-through' fontSize='14px' color='#C73E1D'>
                {generalUtils.formatPrice(price.price / 360, price.currency)}
              </Text>
              <Text fontSize={{ base: '24px', md: '30px' }} fontWeight={900} lineHeight='120%'>
                {generalUtils.formatPrice(price.finalPrice / 360, price.currency)}
              </Text>
              <TransText transKey='common:per_day' color='rgba(5, 5, 23, 0.64)' fontSize='14px'></TransText>
            </Box>
          </Flex>
        ) : (
          <Flex justifyContent='center'>
            <Spinner />
          </Flex>
        )}
      </Box>
      <Button
        width='100%'
        animation={`10s ease 0s infinite normal none running ${firstPulse}`}
        gap='1rem'
        marginBottom='0.5rem'
        onClick={() => handleClick()}
      >
        <Text color='white'>{t('common:claim_my_plan')}</Text>
        <ArrowSVG fill='white' />
      </Button>
      <ChallengeCompletionDate />
    </>
  ) : null;
};
