import { initPlasmicLoader } from '@plasmicapp/loader-nextjs';
import { Animate } from '@shared/animations';
import { Button } from '@shared/common';

import {
  CalculationsLoader,
  SummaryDynamicCopy,
  SummaryParameters,
  PlanSelectionAnnual,
  TestimonialsList,
  SummaryIntroHeader,
} from '~/components';

import { AgeSelection } from './components/AgeSelection';
import { AnnualPlanUpgradeUpsell } from './components/AnnualPlanUpgradeUpsell';
import { AppImageGallery } from './components/AppImageGallery';
import { BMIChart } from './components/BMIChart';
import { BundleUpsell } from './components/BundleUpsell';
import { ChallengeSelection } from './components/ChallengeSelection';
import { CheckoutPlanDislaimer } from './components/CheckoutPlanDisclaimer';
import { CortisolGenderSelection } from './components/CortisolGenderSelection';
import { CortisolScoreGrid } from './components/CortisolScoreGrid';
import { FlowVariantSwitcher } from './components/FlowVariantSwitcher';
import { GenderSelection, GenderSelectionWithIcons } from './components/GenderSelection';
import { MealMasterclassSubscriptionUpsell } from './components/MealMasterclassSubscriptionUpsell';
import { MealMasterclassUpsell } from './components/MealMasterclassUpsell';
import { MeditationUpsell } from './components/MeditationUpsell';
import { ModalButtonWithQuestions } from './components/ModalButtonWithQuestions';
import { PlansGoalCopy } from './components/PlansGoalCopy';
import { PlansProblemsCopy } from './components/PlansProblemsCopy';
import { ShortWeightLossTitle } from './components/ShortWeightLossTitle';
import { StickyWrapper } from './components/StickyWrapper';
import { StressReleaseUpsell } from './components/StressReleaseUpsell';
import { TransText } from './components/TransText';
import { WorkoutsUpsell } from './components/WorkoutsUpsell';
import { AdvertorialHeadline } from './layouts/advertorial/AdvertorialHeadline';
import { AdvertorialSubheading } from './layouts/advertorial/AdvertorialSubheading';
import { AdvertorialWeightLossChart } from './layouts/advertorial/AdvertorialWeightLossChart';
import { PoopTypeCard } from './layouts/advertorial/PoopTypeCard';
import { CartItemsSummary } from './layouts/CartItemsSummary';
import { CheckoutSplittedForm } from './layouts/CheckoutSplittedForm';
import { DownsellForm } from './layouts/DownsellForm';
import { EmailForm } from './layouts/EmailForm';
import { GoalSelection } from './layouts/GoalSelection';
import { InflammationLoader } from './layouts/inflammation/InflammationLoader';
import { InflammationProgramCoverage } from './layouts/inflammation/InflammationProgramCoverage';
import { InflammationWeightLoss } from './layouts/inflammation/InflammationWeightLoss';
import { PersonalSummaryExtended } from './layouts/PersonalSummaryExtended';
import { PersonalSummaryGrid } from './layouts/PersonalSummaryGrid';
import { PlanDescription } from './layouts/PlanDescription';
import { PlanSelection } from './layouts/PlanSelection';
import { PlanSelectionChoice } from './layouts/PlanSelectionChoice';
import { PlanSelectionTags } from './layouts/PlanSelectionTags';
import { PlansHeader } from './layouts/PlansHeader';
import { PlansHeadline, PlansHeadline2 } from './layouts/PlansHeadline';
import { ProgramSelection } from './layouts/ProgramSelection';
import { QuoraComments } from './layouts/QuoraComments';
import { SuccessStories } from './layouts/SuccessStories';
import { TimeBanner } from './layouts/TimeBanner';
import { WeightLossChart, WeightLossForecastGrid, WeightLossForecastGridFull } from './layouts/WeightLossForecastGrid';
import { WeightLossZoneTable } from './layouts/WeightLossZoneTable';
import {
  SimpleWeightLossChart,
  WeightLossChartComparison,
  WeightLossChartWithoutWrapper,
  WeightLostChart,
  WeightLostChartBlue,
} from './layouts/WeightLostChart';
import { ZodiacCopy, ZodiacHeader } from './layouts/ZodiacHeader';

export const PLASMIC = initPlasmicLoader({
  projects: [
    {
      id: process.env.NEXT_PUBLIC_PLASMIC_PROJECT_ID as string,
      token: process.env.NEXT_PUBLIC_PLASMIC_TOKEN as string,
    },
  ],
  preview: process.env.NODE_ENV === 'development',
});

PLASMIC.registerComponent(Button, {
  name: 'Button',
  props: {
    children: {
      type: 'slot',
    },
    size: {
      type: 'choice',
      options: ['big', 'small'],
      defaultValue: 'big',
    },
    variant: {
      type: 'choice',
      options: ['primary', 'secondary', 'blank', 'yellow'],
      defaultValue: 'primary',
    },
    action: {
      type: 'choice',
      options: ['navigate', 'scroll'],
      defaultValue: 'navigate',
    },
    value: {
      type: 'string',
    },
    flowName: {
      type: 'string',
    },
  },
});

PLASMIC.registerComponent(AgeSelection, {
  name: 'AgeSelection',
  props: {
    flowName: {
      type: 'string',
    },
  },
});

PLASMIC.registerComponent(Animate, {
  name: 'Animate',
  props: {
    children: {
      type: 'slot',
    },
    variant: {
      type: 'choice',
      options: ['swipeBottom', 'opacity'],
      defaultValue: 'opacity',
    },
  },
});

PLASMIC.registerComponent(WeightLostChart, {
  name: 'WeightLostChart',
  props: {},
});

PLASMIC.registerComponent(TimeBanner, {
  name: 'TimeBanner',
  props: {},
});

PLASMIC.registerComponent(EmailForm, {
  name: 'EmailForm',
  props: {},
});

PLASMIC.registerComponent(PlanSelection, {
  name: 'PlanSelection',
  props: {},
});

PLASMIC.registerComponent(SuccessStories, {
  name: 'SuccessStories',
  props: {},
});

PLASMIC.registerComponent(AppImageGallery, {
  name: 'AppImageGallery',
  props: {},
});

PLASMIC.registerComponent(GenderSelection, {
  name: 'GenderSelection',
  props: {
    flowName: {
      type: 'string',
    },
    copyPrefix: {
      type: 'string',
    },
  },
});

PLASMIC.registerComponent(GenderSelectionWithIcons, {
  name: 'GenderSelectionWithIcons',
  props: {},
});

PLASMIC.registerComponent(BMIChart, {
  name: 'BMIChart',
  props: {},
});

PLASMIC.registerComponent(FlowVariantSwitcher, {
  name: 'FlowVariantSwitcher',
  props: {
    children: {
      type: 'slot',
    },
    flowName: {
      type: 'string',
    },
    isNotEqual: {
      type: 'boolean',
      defaultValue: false,
    },
  },
});

PLASMIC.registerComponent(WeightLossZoneTable, {
  name: 'WeightLossZoneTable',
  props: {},
});

PLASMIC.registerComponent(PersonalSummaryGrid, {
  name: 'PersonalSummaryGrid',
  props: {},
});

PLASMIC.registerComponent(PlansHeader, {
  name: 'PlansHeader',
  props: {},
});

PLASMIC.registerComponent(WeightLostChartBlue, {
  name: 'WeightLostChartBlue',
  props: {},
});

PLASMIC.registerComponent(PlanDescription, {
  name: 'PlanDescription',
  props: {},
});

PLASMIC.registerComponent(PlansHeadline, {
  name: 'PlansHeadline',
  props: {},
});

PLASMIC.registerComponent(WeightLossChartWithoutWrapper, {
  name: 'WeightLossChartWithoutWrapper',
  props: {},
});

PLASMIC.registerComponent(PlansHeadline2, {
  name: 'PlansHeadline2',
  props: {},
});

PLASMIC.registerComponent(GoalSelection, {
  name: 'GoalSelection',
  props: {},
});

PLASMIC.registerComponent(AdvertorialHeadline, {
  name: 'AdvertorialHeadline',
  props: {
    children: {
      type: 'slot',
    },
  },
});

PLASMIC.registerComponent(AdvertorialWeightLossChart, {
  name: 'AdvertorialWeightLossChart',
  props: {},
});

PLASMIC.registerComponent(PoopTypeCard, {
  name: 'PoopTypeCard',
  props: {},
});

PLASMIC.registerComponent(AdvertorialSubheading, {
  name: 'AdvertorialSubheading',
  props: {},
});

PLASMIC.registerComponent(StickyWrapper, {
  name: 'StickyWrapper',
  props: {
    children: {
      type: 'slot',
    },
  },
});

PLASMIC.registerComponent(SummaryIntroHeader, {
  name: 'SummaryIntroHeader',
  props: {
    children: {
      type: 'slot',
    },
  },
});

PLASMIC.registerComponent(InflammationWeightLoss, {
  name: 'InflammationWeightLoss',
  props: {},
});

PLASMIC.registerComponent(SummaryParameters, {
  name: 'SummaryParameters',
  props: {},
});

PLASMIC.registerComponent(ChallengeSelection, {
  name: 'ChallengeSelection',
  props: {},
});

PLASMIC.registerComponent(InflammationProgramCoverage, {
  name: 'InflammationProgramCoverage',
  props: {},
});

PLASMIC.registerComponent(ProgramSelection, {
  name: 'ProgramSelection',
  props: {},
});

PLASMIC.registerComponent(InflammationLoader, {
  name: 'InflammationLoader',
  props: {},
});

PLASMIC.registerComponent(SimpleWeightLossChart, {
  name: 'SimpleWeightLossChart',
  props: {},
});

PLASMIC.registerComponent(PlansGoalCopy, {
  name: 'PlansGoalCopy',
  props: {},
});

PLASMIC.registerComponent(CheckoutPlanDislaimer, {
  name: 'CheckoutPlanDislaimer',
  props: {},
});

PLASMIC.registerComponent(PlansProblemsCopy, {
  name: 'PlansProblemsCopy',
  props: {},
});

PLASMIC.registerComponent(ModalButtonWithQuestions, {
  name: 'ModalButtonWithQuestions',
  props: {},
});

PLASMIC.registerComponent(MealMasterclassUpsell, {
  name: 'MealMasterclassUpsell',
  props: {},
});

PLASMIC.registerComponent(CartItemsSummary, {
  name: 'CartItemsSummary',
  props: {},
});

PLASMIC.registerComponent(ShortWeightLossTitle, {
  name: 'ShortWeightLossTitle',
  props: {},
});

PLASMIC.registerComponent(DownsellForm, {
  name: 'DownsellForm',
  props: {},
});

PLASMIC.registerComponent(PersonalSummaryExtended, {
  name: 'PersonalSummaryExtended',
  props: {},
});

PLASMIC.registerComponent(WeightLossForecastGrid, {
  name: 'WeightLossForecastGrid',
  props: {},
});

PLASMIC.registerComponent(CheckoutSplittedForm, {
  name: 'CheckoutSplittedForm',
  props: {},
});

PLASMIC.registerComponent(PlanSelectionChoice, {
  name: 'PlanSelectionChoice',
  props: {},
});

PLASMIC.registerComponent(MealMasterclassSubscriptionUpsell, {
  name: 'MealMasterclassSubscriptionUpsell',
  props: {},
});

PLASMIC.registerComponent(WorkoutsUpsell, {
  name: 'WorkoutsUpsell',
  props: {},
});

PLASMIC.registerComponent(MeditationUpsell, {
  name: 'MeditationUpsell',
  props: {},
});

PLASMIC.registerComponent(BundleUpsell, {
  name: 'BundleUpsell',
  props: {},
});

PLASMIC.registerComponent(ZodiacHeader, {
  name: 'ZodiacHeader',
  props: {},
});

PLASMIC.registerComponent(ZodiacCopy, {
  name: 'ZodiacCopy',
  props: {
    section: {
      type: 'choice',
      options: ['emotion', 'stress', 'diet', 'excercise', 'preventative', 'relaxation', 'balace', 'conclusion'],
    },
  },
});

PLASMIC.registerComponent(QuoraComments, {
  name: 'QuoraComments',
  props: {},
});

PLASMIC.registerComponent(CortisolGenderSelection, {
  name: 'CortisolGenderSelection',
  props: {
    flowName: {
      type: 'string',
    },
  },
});

PLASMIC.registerComponent(WeightLossChart, {
  name: 'WeightLossChart',
  props: {},
});

PLASMIC.registerComponent(WeightLossForecastGridFull, {
  name: 'WeightLossForecastGridFull',
  props: {},
});

PLASMIC.registerComponent(CortisolScoreGrid, {
  name: 'CortisolScoreGrid',
  props: {},
});

PLASMIC.registerComponent(PlanSelectionTags, {
  name: 'PlanSelectionTags',
  props: {},
});

PLASMIC.registerComponent(AnnualPlanUpgradeUpsell, {
  name: 'AnnualPlanUpgradeUpsell',
  props: {},
});

PLASMIC.registerComponent(TransText, {
  name: 'TransText',
  defaultStyles: {
    width: '100%',
  },
  props: {
    transKey: {
      type: 'string',
    },
    values: {
      type: 'object',
    },
  },
});

PLASMIC.registerComponent(StressReleaseUpsell, {
  name: 'StressReleaseUpsell',
  props: {},
});

PLASMIC.registerComponent(WeightLossChartComparison, {
  name: 'WeightLossChartComparison',
  props: {},
});

PLASMIC.registerComponent(SummaryDynamicCopy, {
  name: 'SummaryDynamicCopy',
  props: {},
});

PLASMIC.registerComponent(CalculationsLoader, {
  name: 'CalculationsLoader',
  props: {},
});

PLASMIC.registerComponent(PlanSelectionAnnual, {
  name: 'PlanSelectionAnnual',
  props: {},
});

PLASMIC.registerComponent(TestimonialsList, {
  name: 'TestimonialsList',
  props: {},
});
