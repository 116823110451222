import { Box, Button, Flex, Input, Text } from '@chakra-ui/react';
import Image, { StaticImageData } from 'next/image';
import { FC, FormEvent, ReactNode, useRef, useState } from 'react';

import Avatar1Image from '~/assets/images/avatars/avatar_1.png';
import Avatar10Image from '~/assets/images/avatars/avatar_10.png';
import Avatar11Image from '~/assets/images/avatars/avatar_11.png';
import Avatar12Image from '~/assets/images/avatars/avatar_12.png';
import Avatar13Image from '~/assets/images/avatars/avatar_13.png';
import Avatar14Image from '~/assets/images/avatars/avatar_14.png';
import Avatar15Image from '~/assets/images/avatars/avatar_15.png';
import Avatar16Image from '~/assets/images/avatars/avatar_16.png';
import Avatar17Image from '~/assets/images/avatars/avatar_17.png';
import Avatar18Image from '~/assets/images/avatars/avatar_18.png';
import Avatar19Image from '~/assets/images/avatars/avatar_19.png';
import Avatar2Image from '~/assets/images/avatars/avatar_2.png';
import Avatar20Image from '~/assets/images/avatars/avatar_20.png';
import Avatar21Image from '~/assets/images/avatars/avatar_21.png';
import Avatar22Image from '~/assets/images/avatars/avatar_22.png';
import Avatar3Image from '~/assets/images/avatars/avatar_3.png';
import Avatar4Image from '~/assets/images/avatars/avatar_4.png';
import Avatar5Image from '~/assets/images/avatars/avatar_5.png';
import Avatar6Image from '~/assets/images/avatars/avatar_6.png';
import Avatar7Image from '~/assets/images/avatars/avatar_7.png';
import Avatar8Image from '~/assets/images/avatars/avatar_8.png';
import Avatar9Image from '~/assets/images/avatars/avatar_9.png';
import Sarah2Image from '~/assets/images/before-after/sarah-2.png';
import SarahImage from '~/assets/images/before-after/sarah.png';

export const QuoraComments = () => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [comment, setComment] = useState('');
  const [status, setStatus] = useState('0');

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (inputRef.current?.value) {
      setComment(inputRef.current?.value);
    }
  };

  return (
    <Box width='100%'>
      <Flex gap='1rem' alignItems='center' marginBottom='1rem'>
        <Flex bg='#F7F9F7' border='1px solid #EEEEEE' borderRadius='100px'>
          <Flex
            gap='0.5rem'
            alignItems='center'
            padding='0.5rem 1rem'
            borderRight='1px solid #EEEEEE'
            cursor='pointer'
            onClick={() => setStatus(status === '1' ? '0' : '1')}
          >
            <Flex transform='rotate(180deg)'>
              <ArrowSVG color={status === '1' ? '#3FA134' : undefined} />
            </Flex>
            <Text>
              <b>Upvote ·</b> 7,1k
            </Text>
          </Flex>
          <Flex
            alignItems='center'
            padding='0.5rem 1rem 0.5rem 0.5rem'
            cursor='pointer'
            onClick={() => setStatus(status === '-1' ? '0' : '-1')}
          >
            <ArrowSVG color={status === '-1' ? '#3FA134' : undefined} />
          </Flex>
        </Flex>
        <Flex gap='0.25rem' alignItems='center'>
          <DotsSVG />
          <Text fontWeight={700}>402</Text>
        </Flex>
        <Flex gap='0.25rem' alignItems='center'>
          <ShareSVG />
          <Text fontWeight={700}>93</Text>
        </Flex>
      </Flex>
      {comment ? (
        <Comment name='Anonymous user' date='a few minutes ago' disableUpvote text={<>{comment}</>} />
      ) : (
        <Box marginBottom={{ base: '1.5rem', md: '2rem' }}>
          <form onSubmit={handleSubmit}>
            <Flex flexDirection={{ base: 'column', md: 'row' }} gap='0.5rem'>
              <Input ref={inputRef} borderRadius='100px' placeholder='Add a comment...' fontSize='14px' />
              <Button type='submit' variant='yellow' borderRadius='100px' size='small'>
                Add comment
              </Button>
            </Flex>
          </form>
        </Box>
      )}
      <Box>
        <Comment
          avatar={Avatar1Image}
          name='Angela Higgins'
          date='Nov 18'
          likes={864}
          text={<>Amazing transformation! Did you have a specific meal plan? I&apos;m looking for ideas.</>}
        />
        <Box marginLeft='52px'>
          <Comment
            avatar={Avatar2Image}
            name='Marley Walter'
            date='Nov 18'
            likes={43}
            text={<>Ffs, read until the end, she used digesti</>}
          />
          <Comment
            avatar={Avatar1Image}
            name='Angela Higgins'
            date='Nov 18'
            likes={20}
            text={<>Oh, my bad, apologies. Could you please share what is digesti?</>}
          />
          <Comment
            avatar={Avatar2Image}
            name='Marley Walter'
            date='Nov 18'
            likes={576}
            text={
              <>
                It’s personalized anti-inflammatory meal program, just go to{' '}
                <Box as='a' href='/' textDecor='underline' color='#3FA134'>
                  digestiplan.com
                </Box>{' '}
                and answer questions there to get yours
              </>
            }
            replyEnabled
          />
        </Box>
      </Box>
      <Box>
        <Comment
          avatar={Avatar3Image}
          name='Hana Stanley'
          date='Nov 18'
          likes={1304}
          text={
            <>
              I read that anti-inflammatory diets help not just with weight loss but also with reducing the risk of
              certain chronic diseases. Did you notice any health benefits besides weight loss?
            </>
          }
        />
        <Box marginLeft='52px'>
          <Comment
            avatar={Avatar4Image}
            name='Julie Luna'
            date='Nov 18'
            likes={435}
            text={
              <>
                All my chronic pain symptoms are now gone or extremely reduced. Guys, it’s your diet that causes you
                pain.
              </>
            }
            replyEnabled
          />
        </Box>
      </Box>
      <Box>
        <Comment
          avatar={Avatar5Image}
          name='Adriana Walsh'
          date='Nov 18'
          likes={1241}
          text={
            <>
              Incredible progress! For those interested, anti-inflammatory diets often include foods like berries, fatty
              fish, broccoli, avocados, green tea, and many more. These foods are rich in nutrients and antioxidants
              which help combat inflammation. It&apos;s not just about weight loss but improving overall health.
            </>
          }
          replyEnabled
        />
      </Box>
      <Box>
        <Comment
          avatar={Avatar6Image}
          name='Annabelle Floyd'
          date='Nov 18'
          likes={749}
          image={Sarah2Image}
          text={
            <>
              Reading your story felt like reading my own journey! I started an anti-inflammatory diet about a year ago
              after struggling with persistent joint pain and fatigue. Within months, not only did my joint pain
              significantly reduce, but I also lost 25 pounds! It boosted my energy levels like nothing else. I never
              realized how much my diet impacted my overall health until I started following anti-inflammatory diet.
            </>
          }
        />
        <Box marginLeft='52px'>
          <Comment
            avatar={Avatar7Image}
            name='Lily-Rose Dean'
            date='Nov 18'
            likes={11}
            text={<>What meal plan did you use? Or did you create one on your own?</>}
          />
          <Comment
            avatar={Avatar8Image}
            name='Nadia Robles'
            date='Nov 18'
            likes={54}
            text={<>I used digesti as well.</>}
            replyEnabled
          />
        </Box>
      </Box>
      <Box>
        <Comment
          avatar={Avatar9Image}
          name='Aleeza Robertson'
          date='Nov 18'
          likes={487}
          text={
            <>
              You&apos;re a true inspiration! Your dedication and hard work really paid off. Thanks for sharing your
              story!
            </>
          }
        />
      </Box>
      <Box>
        <Comment
          avatar={Avatar10Image}
          name='Taylor Warner'
          date='Nov 18'
          likes={1328}
          text={<>Did you integrate any specific supplements or vitamins into your diet?</>}
        />
        <Box marginLeft='52px'>
          <Comment
            avatar={Avatar9Image}
            name='Aleeza Robertson'
            date='Nov 18'
            likes={31}
            text={<>No, just followed digesti meal plan.</>}
          />
        </Box>
      </Box>
      <Box>
        <Comment
          avatar={Avatar11Image}
          name='Catrin Sosa'
          date='Nov 18'
          likes={236}
          image={SarahImage}
          text={
            <>
              Your success is so encouraging! It&apos;s important to note that a diet change should be sustainable and
              enjoyable. Incorporating a variety of foods and balancing nutrients is key. For those looking for
              structured plans, I can recommend{' '}
              <Box as='a' href='/' textDecor='underline' color='#3FA134'>
                digestiplan.com
              </Box>{' '}
              as well.
            </>
          }
        />
      </Box>
      <Box>
        <Comment
          avatar={Avatar12Image}
          name='Linda Baxter'
          date='Nov 18'
          likes={8}
          text={<>Just starting my anti-inflammatory journey. Any tips for eating out or social situations?</>}
        />
        <Box marginLeft='52px'>
          <Comment
            avatar={Avatar13Image}
            name='Marianne Fuller'
            date='Nov 18'
            likes={328}
            text={<>Act normal and enjoy your life. Don’t eat shit.</>}
            replyEnabled
          />
        </Box>
      </Box>
      <Box>
        <Comment
          avatar={Avatar14Image}
          name='Lula Hayden'
          date='Nov 18'
          likes={67}
          text={<>Can you share how you managed cravings during your diet?</>}
          replyEnabled
        />
      </Box>
      <Box>
        <Comment
          avatar={Avatar15Image}
          name='Kira Medina'
          date='Nov 18'
          likes={237}
          text={<>Did you have to give up chocolate? Because that&apos;s where they lose me </>}
        />
        <Box marginLeft='52px'>
          <Comment
            avatar={Avatar16Image}
            name='Isobel Davila'
            date='Nov 18'
            likes={82}
            text={<>Just try to avoid sugar and processed foods as much as possible and you will be fine.</>}
          />
          <Comment
            avatar={Avatar15Image}
            name='Kira Medina'
            date='Nov 18'
            likes={7}
            text={<>Yes. If you eat chocolate, you will die.</>}
          />
          <Comment
            avatar={Avatar17Image}
            name='Nancy Farrell'
            date='Nov 18'
            likes={17}
            text={<>We will all die. Sooner or later.</>}
          />
          <Comment
            avatar={Avatar16Image}
            name='Isobel Davila'
            date='Nov 18'
            likes={141}
            text={<>But you will die faster. 😜 Jk, consume everything in moderation.</>}
            replyEnabled
          />
        </Box>
      </Box>
      <Box>
        <Comment
          avatar={Avatar18Image}
          name='Billie Park'
          date='Nov 18'
          likes={168}
          text={
            <>
              A big part of maintaining an anti-inflammatory diet is understanding the science behind it. Reducing
              processed foods and sugars, and increasing fruits, vegetables, and whole grains can significantly reduce
              inflammation markers in the body.
            </>
          }
          replyEnabled
        />
      </Box>
      <Box>
        <Comment
          avatar={Avatar19Image}
          name='Emma Oconnor'
          date='Nov 18'
          likes={286}
          text={
            <>
              Your success is so relatable! I also turned to an anti-inflammatory diet after dealing with digestive
              issues and unexplained weight gain. The difference it made was astounding. By replacing refined carbs with
              whole grains, adding more anti-inflammatory spices like turmeric and ginger to my meals, and focusing on
              hydration, I saw a complete turnaround. In 8 months, I not only lost 30 pounds but also felt more
              energetic and clear-headed.
            </>
          }
          replyEnabled
        />
      </Box>
      <Box>
        <Comment
          avatar={Avatar20Image}
          name='Emma Bruce'
          date='Nov 18'
          likes={680}
          text={
            <>
              Just wanted to pop in and say how positive and helpful this thread has been. Congrats to the OP on your
              journey, and thanks for the tips and resources, especially Digestiplan.com. It&apos;s great to see a
              community so supportive and informative!
            </>
          }
          replyEnabled
        />
      </Box>
      <Box>
        <Comment
          avatar={Avatar21Image}
          name='Tina Mayer'
          date='Nov 18'
          likes={182}
          text={
            <>This thread is a goldmine of motivation and information. I&apos;m bookmarking it for future reference!</>
          }
          replyEnabled
        />
      </Box>
      <Box>
        <Comment
          avatar={Avatar22Image}
          name='Elsie Rice'
          date='Nov 18'
          likes={75}
          text={<>I&apos;m inspired to start, but worried about keeping up. How did you stay motivated?</>}
          replyEnabled
        />
      </Box>
    </Box>
  );
};

const Comment: FC<{
  avatar?: StaticImageData;
  name: string;
  text: ReactNode;
  date: string;
  likes?: number;
  replyEnabled?: boolean;
  disableUpvote?: boolean;
  image?: StaticImageData;
}> = ({ avatar, name, text, date, likes, replyEnabled, disableUpvote, image }) => {
  const [state, setState] = useState('0');
  const [isFormOpened, setIsFormOpened] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [comment, setComment] = useState('');

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (inputRef.current?.value) {
      setComment(inputRef.current?.value);
    }
  };

  return (
    <>
      <Flex gap='0.75rem' marginBottom='1.5rem'>
        <Flex width='40px' height='40px' borderRadius='100px' bg='#eee' alignItems='center' justifyContent='center'>
          {avatar ? (
            <Image src={avatar} width={40} height={40} alt='' />
          ) : (
            <Text fontWeight={700} lineHeight='100%' fontSize='14px'>
              A
            </Text>
          )}
        </Flex>
        <Box flex={1}>
          <Text color='#233E20' marginBottom='0.125rem'>
            <b>{name} ·</b>{' '}
            <Box as='span' color='rgba(35, 62, 32, 0.64)'>
              {date}
            </Box>
          </Text>
          <Text color='#233E20' marginBottom='0.75rem'>
            {text}
          </Text>
          {image ? (
            <Flex
              position='relative'
              marginBottom='0.75rem'
              width='16rem'
              height='16rem'
              borderRadius='0.5rem'
              overflow='hidden'
            >
              <Image src={image} fill style={{ objectFit: 'cover' }} alt='' />
            </Flex>
          ) : null}
          {disableUpvote ? null : (
            <Flex gap='1rem' alignItems='center'>
              <Flex display='inline-flex' bg='#F7F9F7' border='1px solid #EEEEEE' borderRadius='100px'>
                <Flex
                  gap='0.5rem'
                  alignItems='center'
                  padding='0.5rem 1rem'
                  borderRight='1px solid #EEEEEE'
                  onClick={() => setState(state === '1' ? '0' : '1')}
                  cursor='pointer'
                >
                  <Flex transform='rotate(180deg)'>
                    <ArrowSVG color={state === '1' ? '#3FA134' : undefined} />
                  </Flex>
                  {likes ? <Text fontSize='14px'>{likes + Number(state)}</Text> : null}
                </Flex>
                <Flex
                  alignItems='center'
                  padding='0.5rem 1rem 0.5rem 0.5rem'
                  onClick={() => setState(state === '-1' ? '0' : '-1')}
                  cursor='pointer'
                >
                  <ArrowSVG color={state === '-1' ? '#3FA134' : undefined} />
                </Flex>
              </Flex>
              {replyEnabled && !isFormOpened ? (
                <Text
                  fontSize='14px'
                  fontWeight={700}
                  color='rgba(35, 62, 32, 0.64)'
                  cursor='pointer'
                  onClick={() => setIsFormOpened(true)}
                >
                  Reply
                </Text>
              ) : null}
            </Flex>
          )}
        </Box>
      </Flex>
      {isFormOpened ? (
        <>
          {comment ? (
            <Comment name='Anonymous user' date='a few minutes ago' disableUpvote text={<>{comment}</>} />
          ) : (
            <Box marginBottom={{ base: '1.5rem', md: '2rem' }}>
              <form onSubmit={handleSubmit}>
                <Flex flexDirection={{ base: 'column', md: 'row' }} gap='0.5rem'>
                  <Input ref={inputRef} borderRadius='100px' placeholder='Add a comment...' fontSize='14px' />
                  <Button type='submit' variant='yellow' borderRadius='100px' size='small'>
                    Add comment
                  </Button>
                </Flex>
              </form>
            </Box>
          )}
        </>
      ) : null}
    </>
  );
};

const DotsSVG = () => (
  <>
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.3337 1.66675H6.66699C3.90949 1.66675 1.66699 3.90925 1.66699 6.66675V16.6667C1.66699 16.8878 1.75479 17.0997 1.91107 17.256C2.06735 17.4123 2.27931 17.5001 2.50033 17.5001H13.3337C16.0912 17.5001 18.3337 15.2576 18.3337 12.5001V6.66675C18.3337 3.90925 16.0912 1.66675 13.3337 1.66675ZM16.667 12.5001C16.667 14.3384 15.172 15.8334 13.3337 15.8334H3.33366V6.66675C3.33366 4.82841 4.82866 3.33341 6.66699 3.33341H13.3337C15.172 3.33341 16.667 4.82841 16.667 6.66675V12.5001Z'
        fill='#233E20'
      />
      <path
        d='M7.91699 10.8333C8.60735 10.8333 9.16699 10.2736 9.16699 9.58325C9.16699 8.8929 8.60735 8.33325 7.91699 8.33325C7.22664 8.33325 6.66699 8.8929 6.66699 9.58325C6.66699 10.2736 7.22664 10.8333 7.91699 10.8333Z'
        fill='#233E20'
      />
      <path
        d='M12.083 10.8333C12.7734 10.8333 13.333 10.2736 13.333 9.58325C13.333 8.8929 12.7734 8.33325 12.083 8.33325C11.3927 8.33325 10.833 8.8929 10.833 9.58325C10.833 10.2736 11.3927 10.8333 12.083 10.8333Z'
        fill='#233E20'
      />
    </svg>
  </>
);

const ShareSVG = () => (
  <>
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.16642 5.875V3.33333C9.16642 3.11232 9.07862 2.90036 8.92234 2.74408C8.76606 2.5878 8.5541 2.5 8.33309 2.5C8.11447 2.50092 7.90497 2.58771 7.74975 2.74167L1.91642 8.575C1.83831 8.65247 1.77632 8.74464 1.73401 8.84619C1.6917 8.94774 1.66992 9.05666 1.66992 9.16667C1.66992 9.27668 1.6917 9.3856 1.73401 9.48715C1.77632 9.5887 1.83831 9.68086 1.91642 9.75833L7.74975 15.5917C7.86674 15.7063 8.01493 15.784 8.1758 15.8149C8.33667 15.8458 8.50309 15.8286 8.65425 15.7655C8.80541 15.7024 8.93461 15.5961 9.02571 15.4599C9.1168 15.3238 9.16574 15.1638 9.16642 15V12.4167H9.87475C11.1914 12.397 12.4965 12.6641 13.6995 13.1995C14.9025 13.7348 15.9746 14.5255 16.8414 15.5167C16.9459 15.6557 17.0912 15.7586 17.2571 15.8109C17.423 15.8632 17.6011 15.8623 17.7664 15.8083C17.9347 15.7516 18.0804 15.6425 18.1823 15.4971C18.2843 15.3517 18.3371 15.1775 18.3331 15C18.3331 7.4 11.5998 6.1 9.16642 5.875ZM9.87475 10.7333C9.31738 10.7316 8.76054 10.7678 8.20809 10.8417C8.0108 10.8716 7.83081 10.9713 7.70082 11.1227C7.57084 11.2741 7.49949 11.4671 7.49975 11.6667V12.9917L3.68309 9.16667L7.49975 5.34167V6.66667C7.49975 6.88768 7.58755 7.09964 7.74383 7.25592C7.90011 7.4122 8.11207 7.5 8.33309 7.5C9.09142 7.5 15.0914 7.66667 16.3914 12.8583C14.5045 11.4671 12.2191 10.7219 9.87475 10.7333Z'
        fill='#233E20'
      />
    </svg>
  </>
);

const ArrowSVG: FC<{ color?: string }> = ({ color = '#233E20' }) => (
  <>
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.6512 18.0208C10.3337 18.4166 9.66706 18.4166 9.34956 18.0208L2.68289 9.68742C2.58509 9.56474 2.52387 9.41697 2.50625 9.26108C2.48864 9.10518 2.51534 8.94748 2.5833 8.80607C2.65127 8.66467 2.75773 8.54529 2.89046 8.46166C3.0232 8.37802 3.17684 8.33352 3.33373 8.33325H6.66706V2.49992C6.66706 2.2789 6.75486 2.06694 6.91114 1.91066C7.06742 1.75438 7.27938 1.66658 7.50039 1.66658H12.5004C12.7214 1.66658 12.9334 1.75438 13.0896 1.91066C13.2459 2.06694 13.3337 2.2789 13.3337 2.49992V8.33325H16.6671C16.8239 8.33352 16.9776 8.37802 17.1103 8.46166C17.2431 8.54529 17.3495 8.66467 17.4175 8.80607C17.4854 8.94748 17.5121 9.10518 17.4945 9.26108C17.4769 9.41697 17.4157 9.56474 17.3179 9.68742L10.6512 18.0208ZM12.5004 9.99992H11.6671V3.33325H8.33372V9.99992H5.06789L10.0004 16.1658L14.9329 9.99992H12.5004Z'
        fill={color}
      />
    </svg>
  </>
);
