import { Text } from '@chakra-ui/react';
import { formulas, useDate } from '@shared/common';
import { ForwardedRef, forwardRef } from 'react';

import { useAppLead } from '../hooks/useAppLead';

export const PlansHeadline = forwardRef((props, ref: ForwardedRef<HTMLParagraphElement>) => {
  const { quizAnswers } = useAppLead();
  const { addDays } = useDate();

  const targetWeight = quizAnswers?.isMetric
    ? Number(formulas.lbsToKg(Number(quizAnswers?.targetWeight)).toFixed(0))
    : Number(Number(quizAnswers?.targetWeight).toFixed(0));
  const unitSystem = quizAnswers?.isMetric ? 'kg' : 'lb';
  const weightLossTimeInMonths = formulas.calcWeightLossTime(
    Number(quizAnswers?.weight),
    Number(quizAnswers?.targetWeight),
  );

  return (
    <Text
      ref={ref}
      fontWeight={900}
      fontSize={{ base: '32px', md: '40px' }}
      fontFamily='var(--cabinetGroteskFont)'
      lineHeight='110%'
      textAlign='center'
      {...props}
    >
      {`Your anti-inflammatory plan to lose ${targetWeight}${unitSystem} by ${
        addDays(weightLossTimeInMonths * 30).month
      } ${addDays(weightLossTimeInMonths * 30).newDate.getFullYear()}`}
    </Text>
  );
});

PlansHeadline.displayName = 'PlansHeadline';

export const PlansHeadline2 = () => {
  const { quizAnswers } = useAppLead();
  const targetWeight = quizAnswers?.isMetric
    ? Number(formulas.lbsToKg(Number(quizAnswers?.targetWeight)).toFixed(0))
    : Number(Number(quizAnswers?.targetWeight).toFixed(0));
  const unitSystem = quizAnswers?.isMetric ? 'kg' : 'lb';

  return (
    <Text
      fontWeight={900}
      fontSize={{ base: '26px', md: '40px' }}
      fontFamily='var(--cabinetGroteskFont)'
      lineHeight='110%'
      textAlign='center'
    >{`Achieve your weight goal of ${targetWeight}${unitSystem} with a plan made for you`}</Text>
  );
};
