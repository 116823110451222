import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { ForwardedRef, forwardRef } from 'react';

import { TransText } from '~/components/TransText';
import { useAppLead } from '~/hooks/useAppLead';

export const SummaryParameters = forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => {
  const { quizAnswers } = useAppLead();
  const { t } = useTranslation();

  return (
    <Box width='100%' {...props} ref={ref}>
      <Box
        padding={{ base: '1rem', md: '1.5rem' }}
        border='1px solid rgba(0, 0, 0, 0.06)'
        borderRadius='0.25rem'
        backgroundColor='rgba(0, 0, 0, 0.02)'
      >
        <TransText transKey='summary:cortisol_level' fontWeight={700} marginBottom='0.5rem'></TransText>
        <Box marginBottom='1.5rem'>
          <Flex position='relative' gap='0.125rem' marginBottom='0.5rem'>
            <Box flex={1} height='10px' backgroundColor='#44AB38' borderRadius='0.5rem 0 0 0.5rem' />
            <Box flex={1} height='10px' backgroundColor='#44AB38' />
            <Box flex={1} height='10px' backgroundColor='#F7E18A' />
            <Box flex={1} height='10px' backgroundColor='#F7E18A' />
            <Box flex={1} height='10px' backgroundColor='#F7E18A' />
            <Box flex={1} height='10px' backgroundColor='#FD6A78' />
            <Box flex={1} height='10px' backgroundColor='#FD6A78' borderRadius='0 0.5rem 0.5rem 0' />
            <Box
              position='absolute'
              height='24px'
              width='24px'
              borderRadius='1rem'
              backgroundColor='#340000'
              top='50%'
              transform='translateY(-50%)'
              border='3px solid white'
              left='84%'
              boxShadow='0 4px 4px 4px rgba(0,0,0, 0.05)'
            ></Box>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='12px' fontWeight={500}>
              {t('common:low')}
            </Text>
            <Text fontSize='12px' fontWeight={500}>
              {t('common:medium')}
            </Text>
            <Text fontSize='12px' fontWeight={500}>
              {t('common:high')}
            </Text>
          </Flex>
        </Box>

        {quizAnswers?.skin_changes === 'yes' && (
          <>
            <TransText transKey='summary:skin_problems' fontWeight={700} marginBottom='0.5rem'></TransText>
            <Box marginBottom='1.5rem'>
              <Flex position='relative' gap='0.125rem' marginBottom='0.5rem'>
                <Box flex={1} height='10px' backgroundColor='#44AB38' borderRadius='0.5rem 0 0 0.5rem' />
                <Box flex={1} height='10px' backgroundColor='#44AB38' />
                <Box flex={1} height='10px' backgroundColor='#F7E18A' />
                <Box flex={1} height='10px' backgroundColor='#F7E18A' />
                <Box flex={1} height='10px' backgroundColor='#F7E18A' />
                <Box flex={1} height='10px' backgroundColor='#FD6A78' />
                <Box flex={1} height='10px' backgroundColor='#FD6A78' borderRadius='0 0.5rem 0.5rem 0' />
                <Box
                  position='absolute'
                  height='24px'
                  width='24px'
                  borderRadius='1rem'
                  backgroundColor='#340000'
                  top='50%'
                  transform='translateY(-50%)'
                  border='3px solid white'
                  left='75%'
                  boxShadow='0 4px 4px 4px rgba(0,0,0, 0.05)'
                ></Box>
              </Flex>
              <Flex justifyContent='space-between'>
                <Text fontSize='12px' fontWeight={500}>
                  {t('common:low')}
                </Text>
                <Text fontSize='12px' fontWeight={500}>
                  {t('common:medium')}
                </Text>
                <Text fontSize='12px' fontWeight={500}>
                  {t('common:high')}
                </Text>
              </Flex>
            </Box>
          </>
        )}
        {quizAnswers?.feel_fatigued === 'yes' && (
          <>
            <TransText transKey='summary:fatigue_level' fontWeight={700} marginBottom='0.5rem'></TransText>
            <Box marginBottom='1.5rem'>
              <Flex position='relative' gap='0.125rem' marginBottom='0.5rem'>
                <Box flex={1} height='10px' backgroundColor='#44AB38' borderRadius='0.5rem 0 0 0.5rem' />
                <Box flex={1} height='10px' backgroundColor='#44AB38' />
                <Box flex={1} height='10px' backgroundColor='#F7E18A' />
                <Box flex={1} height='10px' backgroundColor='#F7E18A' />
                <Box flex={1} height='10px' backgroundColor='#F7E18A' />
                <Box flex={1} height='10px' backgroundColor='#FD6A78' />
                <Box flex={1} height='10px' backgroundColor='#FD6A78' borderRadius='0 0.5rem 0.5rem 0' />
                <Box
                  position='absolute'
                  height='24px'
                  width='24px'
                  borderRadius='1rem'
                  backgroundColor='#340000'
                  top='50%'
                  transform='translateY(-50%)'
                  border='3px solid white'
                  left='80%'
                  boxShadow='0 4px 4px 4px rgba(0,0,0, 0.05)'
                ></Box>
              </Flex>
              <Flex justifyContent='space-between'>
                <Text fontSize='12px' fontWeight={500}>
                  {t('common:low')}
                </Text>
                <Text fontSize='12px' fontWeight={500}>
                  {t('common:medium')}
                </Text>
                <Text fontSize='12px' fontWeight={500}>
                  {t('common:high')}
                </Text>
              </Flex>
            </Box>
          </>
        )}
        {quizAnswers?.weight_gain === 'yes' && (
          <>
            <TransText
              transKey='summary:difficulty_of_losing_weight'
              fontWeight={700}
              marginBottom='0.5rem'
            ></TransText>
            <Box marginBottom='1.5rem'>
              <Flex position='relative' gap='0.125rem' marginBottom='0.5rem'>
                <Box flex={1} height='10px' backgroundColor='#44AB38' borderRadius='0.5rem 0 0 0.5rem' />
                <Box flex={1} height='10px' backgroundColor='#44AB38' />
                <Box flex={1} height='10px' backgroundColor='#F7E18A' />
                <Box flex={1} height='10px' backgroundColor='#F7E18A' />
                <Box flex={1} height='10px' backgroundColor='#F7E18A' />
                <Box flex={1} height='10px' backgroundColor='#FD6A78' />
                <Box flex={1} height='10px' backgroundColor='#FD6A78' borderRadius='0 0.5rem 0.5rem 0' />
                <Box
                  position='absolute'
                  height='24px'
                  width='24px'
                  borderRadius='1rem'
                  backgroundColor='#340000'
                  top='50%'
                  transform='translateY(-50%)'
                  border='3px solid white'
                  left='88%'
                  boxShadow='0 4px 4px 4px rgba(0,0,0, 0.05)'
                ></Box>
              </Flex>
              <Flex justifyContent='space-between'>
                <Text fontSize='12px' fontWeight={500}>
                  {t('common:low')}
                </Text>
                <Text fontSize='12px' fontWeight={500}>
                  {t('common:medium')}
                </Text>
                <Text fontSize='12px' fontWeight={500}>
                  {t('common:high')}
                </Text>
              </Flex>
            </Box>
          </>
        )}
        {quizAnswers?.feel_hungry === 'yes' && (
          <>
            <TransText transKey='summary:hungriness_level' fontWeight={700} marginBottom='0.5rem'></TransText>
            <Box marginBottom='1.5rem'>
              <Flex position='relative' gap='0.125rem' marginBottom='0.5rem'>
                <Box flex={1} height='10px' backgroundColor='#44AB38' borderRadius='0.5rem 0 0 0.5rem' />
                <Box flex={1} height='10px' backgroundColor='#44AB38' />
                <Box flex={1} height='10px' backgroundColor='#F7E18A' />
                <Box flex={1} height='10px' backgroundColor='#F7E18A' />
                <Box flex={1} height='10px' backgroundColor='#F7E18A' />
                <Box flex={1} height='10px' backgroundColor='#FD6A78' />
                <Box flex={1} height='10px' backgroundColor='#FD6A78' borderRadius='0 0.5rem 0.5rem 0' />
                <Box
                  position='absolute'
                  height='24px'
                  width='24px'
                  borderRadius='1rem'
                  backgroundColor='#340000'
                  top='50%'
                  transform='translateY(-50%)'
                  border='3px solid white'
                  left='70%'
                  boxShadow='0 4px 4px 4px rgba(0,0,0, 0.05)'
                ></Box>
              </Flex>
              <Flex justifyContent='space-between'>
                <Text fontSize='12px' fontWeight={500}>
                  {t('common:low')}
                </Text>
                <Text fontSize='12px' fontWeight={500}>
                  {t('common:medium')}
                </Text>
                <Text fontSize='12px' fontWeight={500}>
                  {t('common:high')}
                </Text>
              </Flex>
            </Box>
          </>
        )}
        {quizAnswers?.suffer_from_headaches === 'yes' && (
          <>
            <TransText transKey='summary:headaches_level' fontWeight={700} marginBottom='0.5rem'></TransText>
            <Box marginBottom='1.5rem'>
              <Flex position='relative' gap='0.125rem' marginBottom='0.5rem'>
                <Box flex={1} height='10px' backgroundColor='#44AB38' borderRadius='0.5rem 0 0 0.5rem' />
                <Box flex={1} height='10px' backgroundColor='#44AB38' />
                <Box flex={1} height='10px' backgroundColor='#F7E18A' />
                <Box flex={1} height='10px' backgroundColor='#F7E18A' />
                <Box flex={1} height='10px' backgroundColor='#F7E18A' />
                <Box flex={1} height='10px' backgroundColor='#FD6A78' />
                <Box flex={1} height='10px' backgroundColor='#FD6A78' borderRadius='0 0.5rem 0.5rem 0' />
                <Box
                  position='absolute'
                  height='24px'
                  width='24px'
                  borderRadius='1rem'
                  backgroundColor='#340000'
                  top='50%'
                  transform='translateY(-50%)'
                  border='3px solid white'
                  left='65%'
                  boxShadow='0 4px 4px 4px rgba(0,0,0, 0.05)'
                ></Box>
              </Flex>
              <Flex justifyContent='space-between'>
                <Text fontSize='12px' fontWeight={500}>
                  {t('common:low')}
                </Text>
                <Text fontSize='12px' fontWeight={500}>
                  {t('common:medium')}
                </Text>
                <Text fontSize='12px' fontWeight={500}>
                  {t('common:high')}
                </Text>
              </Flex>
            </Box>
          </>
        )}
        {quizAnswers?.family_diagnosed_imbalance === 'yes' && (
          <>
            <TransText
              transKey='summary:possibility_hormonal_imbalance'
              fontWeight={700}
              marginBottom='0.5rem'
            ></TransText>
            <Box marginBottom='1.5rem'>
              <Flex position='relative' gap='0.125rem' marginBottom='0.5rem'>
                <Box flex={1} height='10px' backgroundColor='#44AB38' borderRadius='0.5rem 0 0 0.5rem' />
                <Box flex={1} height='10px' backgroundColor='#44AB38' />
                <Box flex={1} height='10px' backgroundColor='#F7E18A' />
                <Box flex={1} height='10px' backgroundColor='#F7E18A' />
                <Box flex={1} height='10px' backgroundColor='#F7E18A' />
                <Box flex={1} height='10px' backgroundColor='#FD6A78' />
                <Box flex={1} height='10px' backgroundColor='#FD6A78' borderRadius='0 0.5rem 0.5rem 0' />
                <Box
                  position='absolute'
                  height='24px'
                  width='24px'
                  borderRadius='1rem'
                  backgroundColor='#340000'
                  top='50%'
                  transform='translateY(-50%)'
                  border='3px solid white'
                  left='90%'
                  boxShadow='0 4px 4px 4px rgba(0,0,0, 0.05)'
                ></Box>
              </Flex>
              <Flex justifyContent='space-between'>
                <Text fontSize='12px' fontWeight={500}>
                  {t('common:low')}
                </Text>
                <Text fontSize='12px' fontWeight={500}>
                  {t('common:medium')}
                </Text>
                <Text fontSize='12px' fontWeight={500}>
                  {t('common:high')}
                </Text>
              </Flex>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
});

SummaryParameters.displayName = 'SummaryParameters';
