import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { TransText } from './TransText';

export const ModalButtonWithQuestions = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay onClick={onClose} />
        <ModalContent maxWidth='40rem'>
          <ModalCloseButton color='black' border='none' cursor='pointer' />
          <ModalBody paddingTop='3rem'>
            <TransText
              transKey='plans:challenge:whats_digesti'
              fontSize={{ base: '20px', md: '24px' }}
              fontWeight={700}
              marginBottom={{ base: '0.5rem', md: '0.75rem' }}
              color='black'
            ></TransText>
            <TransText
              transKey='plans:challenge:its_golden_ticket'
              marginBottom='2rem'
              color='rgba(0, 0, 0, 0.8)'
              values={{ weightStatic: 7 }}
            ></TransText>
            <TransText
              transKey='plans:challenge:how_it_works'
              fontSize={{ base: '20px', md: '24px' }}
              fontWeight={700}
              marginBottom={{ base: '0.5rem', md: '0.75rem' }}
              color='black'
            ></TransText>
            <TransText
              transKey='plans:challenge:how_it_works_explanation'
              marginBottom='2rem'
              color='rgba(0, 0, 0, 0.8)'
              values={{ weightStatic: 7 }}
            ></TransText>
            <TransText
              transKey='plans:challenge:what_if_i_dont_want_participate'
              fontSize={{ base: '20px', md: '24px' }}
              fontWeight={700}
              marginBottom={{ base: '0.5rem', md: '0.75rem' }}
              color='black'
            ></TransText>
            <TransText
              transKey='plans:challenge:what_if_i_dont_want_participate_explanation'
              marginBottom='2rem'
              color='rgba(0, 0, 0, 0.8)'
            ></TransText>
            <TransText
              transKey='plans:challenge:how_do_i_start'
              fontSize={{ base: '20px', md: '24px' }}
              fontWeight={700}
              marginBottom={{ base: '0.5rem', md: '0.75rem' }}
              color='black'
            ></TransText>
            <TransText
              transKey='plans:challenge:how_do_i_start_explanation'
              marginBottom='2rem'
              color='rgba(0, 0, 0, 0.8)'
            ></TransText>
            <TransText
              transKey='plans:challenge:what_happens_after_it'
              fontSize={{ base: '20px', md: '24px' }}
              fontWeight={700}
              marginBottom={{ base: '0.5rem', md: '0.75rem' }}
              color='black'
            ></TransText>
            <TransText
              transKey='plans:challenge:what_happens_after_it_explanation'
              marginBottom='2rem'
              color='rgba(0, 0, 0, 0.8)'
            ></TransText>
            <TransText
              transKey='plans:challenge:fine_print'
              fontSize={{ base: '20px', md: '24px' }}
              fontWeight={700}
              marginBottom={{ base: '0.5rem', md: '0.75rem' }}
              color='black'
            ></TransText>
            <TransText
              transKey='plans:challenge:fine_print_explanation'
              marginBottom='2rem'
              color='rgba(0, 0, 0, 0.8)'
            ></TransText>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Button onClick={onOpen}>{t('common:click_to_learn_more')}</Button>
    </>
  );
};
