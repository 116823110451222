import { Box, Flex, keyframes, Text } from '@chakra-ui/react';
import { useEffect } from 'react';

import CheckSVG from '~/assets/icons/check.svg';
import { useAppRouter } from '~/hooks/useAppRouter';

const progressAnimation = keyframes`
  from { width: 0%; }
  to { width: 100%; }
`;

const fadeInAnimation = keyframes({
  '0%': {
    opacity: 0.5,
  },
  '1%': {
    opacity: 1,
  },
  '100%': {
    opacity: 1,
  },
});

const fadeIn2Animation = keyframes({
  '0%': {
    opacity: 0.2,
  },
  '25%': {
    opacity: 0.2,
  },
  '26%': {
    opacity: 1,
  },
  '100%': {
    opacity: 1,
  },
});

const fadeIn3Animation = keyframes({
  '0%': {
    opacity: 0.2,
  },
  '60%': {
    opacity: 0.2,
  },
  '61%': {
    opacity: 1,
  },
  '100%': {
    opacity: 1,
  },
});

const fadeIn4Animation = keyframes({
  '0%': {
    opacity: 0.2,
  },
  '85%': {
    opacity: 0.2,
  },
  '86%': {
    opacity: 1,
  },
  '100%': {
    opacity: 1,
  },
});

const ANIMATION_DURATION = 8;

export const CalculationsLoader = () => {
  const { pushNextRoute } = useAppRouter('/cortisol-method/summary');

  useEffect(() => {
    const timeout = setTimeout(() => {
      pushNextRoute();
    }, ANIMATION_DURATION * 1000);

    return () => clearTimeout(timeout);
  }, [pushNextRoute]);

  return (
    <Box width='100%'>
      <Text
        fontSize={{ base: '30px', md: '44px' }}
        textAlign={{ base: 'inherit', md: 'center' }}
        maxW='44rem'
        lineHeight='110%'
        fontFamily="'Orelega One', sans-serif"
        margin='0 auto 3.5rem auto'
      >
        Determining your cortisol levels and how it can be changed...
      </Text>
      <Flex maxW='24rem' bg='white' borderRadius='2rem' overflow='hidden' margin='0 auto 1.5rem auto'>
        <Flex
          height='0.75rem'
          width='0'
          bg='#3FA134'
          animation={`${progressAnimation} ${ANIMATION_DURATION}s linear forwards`}
          transition='width 1ms'
        />
      </Flex>
      <Flex maxW='24rem' marginX='auto' flexDir='column' gap='0.5rem'>
        <Flex gap='0.5rem' alignItems='center' animation={`${fadeInAnimation} ${ANIMATION_DURATION}s linear forwards`}>
          <CheckSVG width='24px' height='24px' fill='#3FA134' />
          <Text fontSize='15px' lineHeight='120%'>
            Evaluating your answers...
          </Text>
        </Flex>
        <Flex
          gap='0.5rem'
          alignItems='center'
          opacity={0.2}
          animation={`${fadeIn2Animation} ${ANIMATION_DURATION}s linear forwards`}
        >
          <CheckSVG width='24px' height='24px' fill='#3FA134' />
          <Text fontSize='15px' lineHeight='120%'>
            Analyzing your results...
          </Text>
        </Flex>
        <Flex
          gap='0.5rem'
          alignItems='center'
          opacity={0.2}
          animation={`${fadeIn3Animation} ${ANIMATION_DURATION}s linear forwards`}
        >
          <CheckSVG width='24px' height='24px' fill='#3FA134' />
          <Text fontSize='15px' lineHeight='120%'>
            Determining your gut type...
          </Text>
        </Flex>
        <Flex
          gap='0.5rem'
          alignItems='center'
          opacity={0.2}
          animation={`${fadeIn4Animation} ${ANIMATION_DURATION}s linear forwards`}
        >
          <CheckSVG width='24px' height='24px' fill='#3FA134' />
          <Text fontSize='15px' lineHeight='120%'>
            Building your summary...
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};
