import { Button, Flex, Modal, ModalContent, ModalOverlay, Spinner, Text } from '@chakra-ui/react';
import { PaymentElement, PaymentProvider, StripeCreatePaymentMethod, StripeWrapper } from '@shared/payments';
import { useStripe } from '@stripe/react-stripe-js';
import { FC, useCallback, useEffect, useState } from 'react';

import ArrowSVG from '~/assets/icons/arrow.svg';
import { Product, useAppLead } from '~/hooks/useAppLead';
import { useClientPaymentMethodMutation } from '~/hooks/useClientPaymentMethodMutation';
import { useProducts } from '~/hooks/useProducts';
import { useTracking } from '~/hooks/useTracking';
import { UpsellErrorResponse, useUpsellMutation } from '~/hooks/useUpsellMutation';

export const UpsellButton: FC<{
  text: string;
  refSubscriptionId?: string;
  product?: Product;
  onSuccess: () => void;
}> = ({ text, product, refSubscriptionId, onSuccess }) => {
  const [isFakeLoader, setIsFakeLoader] = useState(false);
  const {
    id,
    lead: { paymentProvider, cartItems, upsellSubscriptionId },
    updateCartItems,
    updateUser,
  } = useAppLead();
  const tracking = useTracking();
  const { findProductPrice } = useProducts();
  const { currency } = findProductPrice(product);
  const upsellMutation = useUpsellMutation({
    onSuccess: ({ data }) => {
      if ('id' in data && data.provider !== PaymentProvider.PAYPAL) {
        handleSuccess({ id: data.id });
      }
    },
  });
  const paymentMethodMutation = useClientPaymentMethodMutation({
    onSuccess: () => {
      handleOrderCreation({
        paymentProvider: PaymentProvider.STRIPE,
      });
    },
  });
  const isLoading = upsellMutation.status === 'loading' || paymentMethodMutation.status === 'loading';
  const [error, setError] = useState<UpsellErrorResponse>();

  const handleSuccess = (order: { id: string }) => {
    tracking.trackPurchase(product?.id || '');

    if (product?.isSubscription && !upsellSubscriptionId && !refSubscriptionId) {
      updateUser({ upsellSubscriptionId: order.id });
    }

    setIsFakeLoader(true);
    updateCartItems(product);

    onSuccess();
  };

  const handleOrderCreation = async (order: { paymentProvider: PaymentProvider }) => {
    const { data } = await upsellMutation.mutateAsync({
      leadId: String(id),
      planId: product?.id as string,
      provider: order.paymentProvider,
      currency,
      subscriptionId: refSubscriptionId || upsellSubscriptionId,
    });

    if ('error' in data) {
      setError(data.error);

      return {
        id: '',
      };
    } else {
      return data;
    }
  };

  const currentProductInCart = cartItems?.find((prod) => prod.id === product?.id);

  return isFakeLoader ? null : currentProductInCart ? (
    <Text
      textAlign='center'
      color='rgba(0, 0, 0, 0.8)'
      fontSize='14px'
      bg='#fbd1d1'
      border='1px solid #d89999'
      padding='0.325rem 0.5rem'
      borderRadius='0.25rem'
    >
      Seems like you already bought this item, please skip this offer
    </Text>
  ) : product ? (
    <>
      {paymentProvider === PaymentProvider.PAYPAL ? (
        <PaymentElement.Paypal
          createOrder={handleOrderCreation}
          options={{ isSubscription: !upsellSubscriptionId }}
          onSuccess={handleSuccess}
          forcePPRerender={[upsellSubscriptionId]}
        />
      ) : (
        <Button
          isLoading={isLoading}
          width='100%'
          marginBottom='0.75rem'
          onClick={() => handleOrderCreation({ paymentProvider })}
          gap='1rem'
          whiteSpace='normal'
        >
          {isLoading ? (
            <Text color='white'>{text}</Text>
          ) : (
            <>
              <Text textAlign='left' color='white'>
                {text}
              </Text>
              <Flex>
                <ArrowSVG fill='#fff' />
              </Flex>
            </>
          )}
        </Button>
      )}
      {error ? (
        <>
          <StripeWrapper>
            {error?.code === 'missing_payment_method' || error.code === 'rejected' ? (
              <PaymentMethodUpdateModal
                onSuccess={(data) => {
                  setError(undefined);
                  paymentMethodMutation.mutate({ leadId: id || '', paymentMethodId: data.paymentMethodId });
                }}
              />
            ) : typeof error?.code === 'string' ? (
              <StripeNextActionHandler
                clientSecret={error.client_secret}
                onSuccess={() => {
                  setError(undefined);
                }}
              />
            ) : null}
          </StripeWrapper>
        </>
      ) : null}
    </>
  ) : (
    <Flex justifyContent='center' marginBottom='1rem'>
      <Spinner />
    </Flex>
  );
};

const StripeNextActionHandler: FC<{ clientSecret: string; onSuccess: () => void }> = ({ clientSecret, onSuccess }) => {
  const stripe = useStripe();
  const [error, setError] = useState('');

  const handleLoad = useCallback(async () => {
    if (stripe) {
      const res = await stripe?.handleNextAction({ clientSecret });

      if (res.paymentIntent?.status === 'succeeded') {
        onSuccess();
      } else {
        setError('Oops, something went wrong, please try again!');
      }
    }
  }, [stripe, clientSecret, onSuccess]);

  useEffect(() => {
    handleLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{error ? <Text>{error}</Text> : null}</>;
};

const PaymentMethodUpdateModal: FC<{ onSuccess: (data: { paymentMethodId: string }) => void }> = ({ onSuccess }) => (
  <Modal isOpen={true} onClose={() => {}}>
    <ModalOverlay />
    <ModalContent padding='1rem' textAlign='center' backgroundColor='system.white'>
      <Text fontSize='20px' fontWeight={700} marginBottom='0.5rem'>
        Update Payment Method
      </Text>
      <Text color='#717171' marginBottom='1.5rem'>
        You need to update your payment details to procceed
      </Text>
      <StripeCreatePaymentMethod onSuccess={onSuccess} />
    </ModalContent>
  </Modal>
);
