import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { FC, ReactNode } from 'react';

import { TransText } from '~/components/TransText';
import { useAppLead } from '~/hooks/useAppLead';

export const SummaryIntroHeader: FC<{ children: ReactNode }> = ({ children }) => {
  const { quizAnswers, gender } = useAppLead();
  const { t } = useTranslation();

  const answers = [
    quizAnswers?.skin_changes === 'yes' ? t('summary:skin_problems') : undefined,
    quizAnswers?.feel_fatigued === 'yes' ? t('summary:fatigue') : undefined,
    quizAnswers?.weight_gain === 'yes' ? t('summary:weight_gain_problems') : undefined,
    quizAnswers?.feel_hungry === 'yes' ? t('summary:hungry_feeling_after_food') : undefined,
    quizAnswers?.frequency_of_urination === 'yes' ? t('summary:frequent_urination') : undefined,
    quizAnswers?.suffer_from_headaches === 'yes' ? t('summary:headaches') : undefined,
    quizAnswers?.moon_face === 'yes' ? t('summary:moon_face') : undefined,
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <Flex alignItems='center' gap='2rem'>
      <Box width={{ base: '100%', md: '50%' }} display={{ base: 'none', md: 'flex' }}>
        {children}
      </Box>
      <Flex width={{ base: '100%', md: '50%' }} flexDirection='column' gap='1rem'>
        <TransText
          transKey='summary:headlines:your_plan_release_stress_level'
          fontSize={{ base: '32px', md: '40px' }}
          fontWeight={900}
          lineHeight='100%'
          fontFamily='var(--cabinetGroteskFont)'
          color='black'
        ></TransText>
        <Box display={{ base: 'flex', md: 'none' }}>{children}</Box>
        <TransText
          transKey={
            gender === 'male'
              ? 'summary:our_promise_with_dynamic_symptoms_male'
              : 'summary:our_promise_with_dynamic_symptoms'
          }
          color='black'
          values={{ symptoms: answers.replace(/,([^,]*)$/, ' &$1').toLowerCase(), weightStatic: 30 }}
        ></TransText>
      </Flex>
    </Flex>
  );
};
