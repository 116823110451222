import { Box, Flex, Text } from '@chakra-ui/react';

import ClockSVG from '~/assets/icons/quiz/clock.svg';
import Poop1SVG from '~/assets/icons/quiz/poop_1.svg';
import Poop2SVG from '~/assets/icons/quiz/poop_2.svg';
import Poop3SVG from '~/assets/icons/quiz/poop_3.svg';
import Poop4SVG from '~/assets/icons/quiz/poop_4.svg';
import Poop5SVG from '~/assets/icons/quiz/poop_5.svg';
import Poop6SVG from '~/assets/icons/quiz/poop_6.svg';
import Poop7SVG from '~/assets/icons/quiz/poop_7.svg';
import { useAppLead } from '~/hooks/useAppLead';

export const PoopTypeCard = () => {
  const { quizAnswers } = useAppLead();
  const poop = quizAnswers?.poop_types as string;
  const poopingFrequency = quizAnswers?.how_often_do_you_poop as string;
  const poopingTime = quizAnswers?.time_take_to_poop as string;
  const isConstipated = /separate_hard/.test(poop) || /lumpy_and_sausage/.test(poop);
  const isDiarhea = /soft_blobs/.test(poop) || /mushy_consistency/.test(poop) || /liquid_consintency/.test(poop);
  const poopResult =
    /separate_hard/.test(poop) && /lumpy_and_sausage/.test(poop) && !isDiarhea
      ? 'Constipated'
      : isConstipated && !isDiarhea
      ? 'Probably constipated'
      : (/a_sausage_shape/.test(poop) || /like_a_smooth/.test(poop)) && !isConstipated && !isDiarhea
      ? 'Good'
      : !isConstipated && isDiarhea
      ? 'Diarhea'
      : 'Constipated & diarrhea';

  const isGoodSleep = /8/.test(quizAnswers?.sleep_time as string);
  const isGoodWater = /85/.test(quizAnswers?.water_intake as string);
  const isPoopingTime = !/less/.test(poopingTime);
  const isPoopingFrequency = /2-3|4-5|Less/.test(poopingFrequency);

  return (
    <>
      <Box width='100%'>
        <Flex justifyContent='center' marginBottom='1rem'>
          {/separate_hard/.test(poop) && (
            <Flex justifyContent='center'>
              <Poop1SVG />
            </Flex>
          )}
          {/lumpy_and_sausage/.test(poop) && (
            <Flex justifyContent='center'>
              <Poop2SVG />
            </Flex>
          )}
          {/a_sausage_shape/.test(poop) && (
            <Flex justifyContent='center'>
              <Poop3SVG />
            </Flex>
          )}
          {/like_a_smooth/.test(poop) && (
            <Flex justifyContent='center'>
              <Poop4SVG />
            </Flex>
          )}
          {/soft_blobs/.test(poop) && (
            <Flex justifyContent='center'>
              <Poop5SVG />
            </Flex>
          )}
          {/mushy_consistency/.test(poop) && (
            <Flex justifyContent='center'>
              <Poop6SVG />
            </Flex>
          )}
          {/liquid_consintency/.test(poop) && (
            <Flex justifyContent='center'>
              <Poop7SVG />
            </Flex>
          )}
        </Flex>

        <Text fontSize='18px' lineHeight='130%' color='black' marginBottom='1rem'>
          We can use science-based Bristol stool chart to determine your bowel movement.
          <br />
          <br />
          Based on your answers,{' '}
          <Text
            as='span'
            fontWeight={900}
            fontSize='28px'
            lineHeight='120%'
            fontFamily='var(--cabinetGroteskFont)'
            color={poopResult === 'Good' ? '#2a8b02' : '#db820b'}
            marginBottom='0.5rem'
          >
            {poopResult === 'Good' ? 'you are not constipated' : 'you are constipated'}
          </Text>
          {poopResult === 'Good'
            ? ' which is good news. This is the first sign that you should be able to lose excessive weight even quicker.'
            : poopResult === 'Constipated' || poopResult === 'Probably constipated'
            ? ''
            : '. You can be surprised, since you might have expected to see diarrhea as a result.'}
        </Text>
      </Box>
      {poopResult === 'Good' ? (
        <></>
      ) : poopResult === 'Constipated' || poopResult === 'Probably constipated' ? (
        <Text color='black' fontSize='18px'>
          Methane gas, a noxious byproduct produced by various bacterial strains, has been demonstrated in research to
          decelerate bowel movements.
          <br />
          <br />
          This leads to constipation, bloating, and stool impaction, resulting in waste lingering in the gut and
          fermenting.
          <br />
          <br />
          The good news is that we can specifically target these bacteria to eradicate methane gas, accelerate
          digestion, restore peristalsis, and ensure the daily and complete elimination of waste.
        </Text>
      ) : (
        <Text color='black' fontSize='18px'>
          Unfortunately, nothing is straightforward.
          <br />
          <br />
          Diarrhea and constipation are two sides of the same coin, which may seem counterintuitive, but let me explain.
          <br />
          <br />
          Archaea and other parasites manipulate your digestive system to serve their needs, often slowing down
          peristalsis to extend their feeding time. Depending on your health, hormones, and the duration of the issue,
          your body responds in one of two ways.
          <br />
          <br />
          If you catch the problem early, your body might attempt to clear everything out desperately, leading to
          diarrhea as it tries to eliminate the issue. However, prolonged diarrhea can cause dehydration, prompting your
          body to eventually give up and allow Archaea to establish themselves, resulting in chronic, long-term
          constipation.
        </Text>
      )}
      {isPoopingFrequency || isPoopingTime ? (
        <Box marginTop='1.5rem'>
          <Text fontSize={{ base: '26px', md: '32px' }} fontWeight={900} lineHeight='110%' marginBottom='1rem'>{`Your ${
            isPoopingFrequency && isPoopingTime
              ? 'time spent on a toilet & latest patterns'
              : isPoopingFrequency
              ? 'latest patterns'
              : 'time spent on a toilet'
          }  makes me a bit worried`}</Text>
          <Text fontSize='18px' color='black' marginBottom='1rem'>
            Bowel habits can vary from person to person. This includes how often you have a{' '}
            <b>
              bowel movement, your control over when you have a bowel movement, and the bowel movement’s consistency and
              color.
            </b>{' '}
            Alterations in any aspect of these habits over the course of a day represent a change in bowel habits.
            <br />
            <br />
            While some bowel movement changes can represent temporary infections, others may indicate greater cause for
            concern.
          </Text>
          {isPoopingTime && isPoopingFrequency ? (
            <>
              <Box
                padding={{ base: '1rem', md: '1.5rem' }}
                border='1px solid rgba(0, 0, 0, 0.06)'
                borderRadius='0.25rem'
                backgroundColor='rgba(0, 0, 0, 0.02)'
                marginBottom='1rem'
              >
                <Text textAlign='center' marginBottom='0.5rem' fontWeight={500}>
                  Pooping time
                </Text>
                <Text fontSize='14px' lineHeight='130%' marginBottom='2rem'>
                  When you have the urge to go, you should be able to sit down on the toilet, and within a few minutes,
                  you should be done.
                </Text>
                <Box marginBottom='0.5rem'>
                  <Flex gap='0.125rem' borderRadius='0.5rem' overflow='hidden'>
                    <Box flex={1} height='10px' backgroundColor='#44AB38' />
                    <Box flex={1} height='10px' backgroundColor='#44AB38' />
                    <Box flex={1} height='10px' backgroundColor='#F3C716' />
                    <Box flex={1} height='10px' backgroundColor='#F7E18A' />
                    <Box flex={1} height='10px' backgroundColor='#FD6A78' />
                    <Box flex={1} height='10px' backgroundColor='#FD6A78' />
                    <Box flex={1} height='10px' backgroundColor='#FD6A78' />
                  </Flex>
                  <Box position='relative' top='-1rem' display='inline-block'>
                    <Box
                      height='1.5rem'
                      width='8px'
                      borderRadius='8px'
                      backgroundColor='black'
                      border='2px solid white'
                      margin='auto'
                    ></Box>
                    <Text fontSize='12px' textAlign='center'>
                      <b>Recommended time:</b>
                      <br />
                      {`<5 minutes`}
                    </Text>
                  </Box>
                  <Box
                    position='relative'
                    top='-1rem'
                    marginLeft={
                      /more/.test(poopingTime)
                        ? '52%'
                        : /15-30/.test(poopingTime)
                        ? '40%'
                        : /5-15/.test(poopingTime)
                        ? '16%'
                        : '2%'
                    }
                    display='inline-block'
                  >
                    <Box
                      height='1.5rem'
                      width='8px'
                      borderRadius='8px'
                      backgroundColor='black'
                      border='2px solid white'
                      margin='auto'
                    ></Box>
                    <Text fontSize='12px' textAlign='center'>
                      <b>Your time:</b>
                      <br />
                      {/more/.test(poopingTime)
                        ? '>30 minutes'
                        : /15-30/.test(poopingTime)
                        ? '15-30 minutes'
                        : /5-15/.test(poopingTime)
                        ? '5-15 minutes'
                        : '~5 minutes'}
                    </Text>
                  </Box>
                </Box>
                <Flex
                  padding='0.5rem 0.5rem'
                  border='1px solid rgba(0, 0, 0, 0.05)'
                  borderRadius='0.5rem'
                  alignItems='center'
                  gap='16px'
                  backgroundColor='white'
                >
                  <Flex backgroundColor='#fff0ca' padding='0.5rem' borderRadius='0.25rem'>
                    <ClockSVG width='24px' height='24px' stroke='#e0b105' />
                  </Flex>
                  <Box>
                    <Text fontWeight={700} fontSize='14px'>
                      Fact:
                    </Text>
                    <Text lineHeight='130%' fontSize='12px' color='black'>
                      If you spend more than 5 minutes on average, it might mean that you have irregular bowel
                      movements. It leads to increased risk of excessive weight.
                    </Text>
                  </Box>
                </Flex>
              </Box>
              <Text color='black' fontSize='18px'>
                <b>You spend more than 5 minutes in the bathroom.</b> There is no need to panic and it could be
                completely normal with all the latest technologies in-hand we tend to spend more and more time in the
                bathroom. But you should know that the{' '}
                <b>universal recommendation is that you should not spend more than 5 minutes on the toilet.</b>
                <br />
                But I am concerned about <b>the change in your pooping patterns</b>. For some people, having one bowel
                movement a day is normal. For others, having several bowel movements a day or one every 2 days is
                normal. There is no set healthy pattern.
                <br />
                <br />
                However, significant changes in the frequency or the appearance can indicate a problem, particularly
                when these changes occur with other issues, such as diarrhea, constipation, or abdominal pain.
                <br />
                <br />
                Let’s keep an eye on that.
              </Text>
            </>
          ) : isPoopingTime ? (
            <Box>
              <Box
                padding={{ base: '1rem', md: '1.5rem' }}
                border='1px solid rgba(0, 0, 0, 0.06)'
                borderRadius='0.25rem'
                backgroundColor='rgba(0, 0, 0, 0.02)'
                marginBottom='1rem'
              >
                <Text textAlign='center' marginBottom='0.5rem' fontWeight={500}>
                  Pooping time
                </Text>
                <Text fontSize='14px' lineHeight='130%' marginBottom='2rem'>
                  When you have the urge to go, you should be able to sit down on the toilet, and within a few minutes,
                  you should be done.
                </Text>
                <Box marginBottom='0.5rem'>
                  <Flex gap='0.125rem' borderRadius='0.5rem' overflow='hidden'>
                    <Box flex={1} height='10px' backgroundColor='#44AB38' />
                    <Box flex={1} height='10px' backgroundColor='#44AB38' />
                    <Box flex={1} height='10px' backgroundColor='#F3C716' />
                    <Box flex={1} height='10px' backgroundColor='#F7E18A' />
                    <Box flex={1} height='10px' backgroundColor='#FD6A78' />
                    <Box flex={1} height='10px' backgroundColor='#FD6A78' />
                    <Box flex={1} height='10px' backgroundColor='#FD6A78' />
                  </Flex>
                  <Box position='relative' top='-1rem' display='inline-block'>
                    <Box
                      height='1.5rem'
                      width='8px'
                      borderRadius='8px'
                      backgroundColor='black'
                      border='2px solid white'
                      margin='auto'
                    ></Box>
                    <Text fontSize='12px' textAlign='center'>
                      <b>Recommended time:</b>
                      <br />
                      {`<5 minutes`}
                    </Text>
                  </Box>
                  <Box
                    position='relative'
                    top='-1rem'
                    marginLeft={
                      /more/.test(poopingTime)
                        ? '52%'
                        : /15-30/.test(poopingTime)
                        ? '40%'
                        : /5-15/.test(poopingTime)
                        ? '16%'
                        : '2%'
                    }
                    display='inline-block'
                  >
                    <Box
                      height='1.5rem'
                      width='8px'
                      borderRadius='8px'
                      backgroundColor='black'
                      border='2px solid white'
                      margin='auto'
                    ></Box>
                    <Text fontSize='12px' textAlign='center'>
                      <b>Your time:</b>
                      <br />
                      {/more/.test(poopingTime)
                        ? '>30 minutes'
                        : /15-30/.test(poopingTime)
                        ? '15-30 minutes'
                        : /5-15/.test(poopingTime)
                        ? '5-15 minutes'
                        : '~5 minutes'}
                    </Text>
                  </Box>
                </Box>
                <Flex
                  padding='0.5rem 0.5rem'
                  border='1px solid rgba(0, 0, 0, 0.05)'
                  borderRadius='0.5rem'
                  alignItems='center'
                  gap='16px'
                  backgroundColor='white'
                >
                  <Flex backgroundColor='#fff0ca' padding='0.5rem' borderRadius='0.25rem'>
                    <ClockSVG width='24px' height='24px' stroke='#e0b105' />
                  </Flex>
                  <Box>
                    <Text fontWeight={700} fontSize='14px'>
                      Fact:
                    </Text>
                    <Text lineHeight='130%' fontSize='12px' color='black'>
                      If you spend more than 5 minutes on average, it might mean that you have irregular bowel
                      movements. It leads to increased risk of excessive weight.
                    </Text>
                  </Box>
                </Flex>
              </Box>
              <Text fontSize='18px' color='black'>
                <b>You spend more than 5 minutes in the bathroom.</b> There is no need to panic and it could be
                completely normal with all the latest technologies in-hand we tend to spend more and more time in the
                bathroom. But you should know that the{' '}
                <b>universal recommendation is that you should not spend more than 5 minutes on the toilet.</b>
              </Text>
            </Box>
          ) : (
            <>
              <Text fontSize='18px' color='black'>
                I am concerned about <b>the change in your pooping patterns</b>. For some people, having one bowel
                movement a day is normal. For others, having several bowel movements a day or one every 2 days is
                normal. There is no set healthy pattern.
                <br />
                <br />
                However, significant changes in the frequency or the appearance can indicate a problem, particularly
                when these changes occur with other issues, such as diarrhea, constipation, or abdominal pain.
                <br />
                <br />
                Let’s keep an eye on that.
              </Text>
            </>
          )}
        </Box>
      ) : null}
      {!isGoodSleep || !isGoodWater ? (
        <Box marginTop='1.5rem'>
          <Text fontSize={{ base: '26px', md: '32px' }} fontWeight={900} lineHeight='110%' marginBottom='1rem'>
            {`Can we do something about your ${
              !isGoodSleep && !isGoodWater ? 'sleep & water intake' : isGoodSleep ? 'water intake' : 'sleep'
            }?`}
          </Text>
          <Text fontSize='18px' color='black' marginBottom='1rem'>
            You don’t need to be an expert with years of experience to understand that hydration and sleep plays a vital
            role in living a healthy & happy life.{' '}
          </Text>
          {!isGoodSleep && (
            <>
              <Text fontSize='24px' fontWeight={700}>
                Sleep
              </Text>
              <Text marginBottom='1rem'>
                If you sleep <b>{quizAnswers?.sleep_time}</b>, it’s crucial to understand the reasons behind it. Do you
                have insomnia? Do you work late at night or procrastinate? Our team is ready to find out the issues and
                come up with some creative challenges <b>how you can manage to get more quality sleep</b>.
              </Text>
            </>
          )}
          {!isGoodWater && (
            <>
              <Text fontSize='24px' fontWeight={700}>
                Water intake
              </Text>
              <Text marginBottom='1rem' textTransform='lowercase'>
                There is no excuse not to include more water into your day. Based on your answer that you drink only{' '}
                <b>{quizAnswers?.water_intake}</b>, I’ll instruct my nutritionists to teach you how you can drink more
                water during the day without even thinking about it. It’s really simple and you need to do it.
              </Text>
            </>
          )}
        </Box>
      ) : null}
    </>
  );
};
