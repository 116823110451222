import { TextProps } from '@chakra-ui/react';
import { formulas, useDate } from '@shared/common';
import { FC } from 'react';

import { TransText } from '~/components/TransText';
import { useAppLead } from '~/hooks/useAppLead';

export const AdvertorialSubheading: FC<TextProps> = (props) => {
  const { quizAnswers } = useAppLead();
  const { addDays } = useDate();

  const weightLossTimeInMonths = formulas.calcWeightLossTime(
    Number(quizAnswers?.weight),
    Number(quizAnswers?.targetWeight),
  );

  return (
    <TransText
      transKey='plans:headlines:get_your_plan_to_lose_weight'
      values={{
        date: `${
          addDays(weightLossTimeInMonths * 30).month
        } ${addDays(weightLossTimeInMonths * 30).newDate.getFullYear()}`,
      }}
      {...props}
    ></TransText>
  );
};

AdvertorialSubheading.displayName = 'AdvertorialSubheading';
