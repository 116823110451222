import { Box, Flex, Text } from '@chakra-ui/react';
import { generalUtils, useHydration } from '@shared/common';
import {
  BraintreeWrapper,
  OrderInterface,
  PaymentElement,
  PaymentProvider,
  PaypalCheckout,
  StripeWrapper,
} from '@shared/payments';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import ArrowSVG from '~/assets/icons/arrow.svg';
import CircleCancelSVG from '~/assets/icons/circle_cancel.svg';
import CircleCardSVG from '~/assets/icons/circle_card.svg';
import CircleSecureSVG from '~/assets/icons/circle_secure.svg';
import PaymentMethodsImage from '~/assets/images/payment_methods.png';
import { OrderSummarySimple } from '~/components/OrderSummary';
import { TransText } from '~/components/TransText';
import { config } from '~/config';
import { usePaymentAuthTokenMutation } from '~/hooks/usePaymentAuthTokenMutation';
import { useSubscriptionMutation } from '~/hooks/useSubscriptionMutation';
import { useTracking } from '~/hooks/useTracking';

import { useAppLead } from '../hooks/useAppLead';
import { useAppRouter } from '../hooks/useAppRouter';
import { useProducts } from '../hooks/useProducts';

import { EmailForm } from './EmailForm';

export const CheckoutSplittedForm = () => {
  const { t } = useTranslation();
  const [isSucceeded, setIsSucceeded] = useState(false);
  const isHydrated = useHydration();
  const {
    id,
    updateUser,
    selectedPlan: plan,
    updateCartItems,
    lead: { cartItems },
  } = useAppLead();
  const { findProductById } = useProducts();
  const selectedPlan = plan || findProductById('21');
  const { finalPrice, currency } = useProducts(selectedPlan);
  const tracking = useTracking();
  const isTrail = selectedPlan?.isTrail;

  const { pushNextRoute, back, locale } = useAppRouter('/create-account');
  const subscriptionMutation = useSubscriptionMutation();
  const paymentAuthTokenMutation = usePaymentAuthTokenMutation();

  const handlePaymentsSuccess = (order: OrderInterface) => {
    tracking.trackPurchase(selectedPlan?.id || '', { method: order.method });

    setIsSucceeded(true);
    updateUser({
      paymentProvider: order.paymentProvider,
    });
    if ([PaymentProvider.STRIPE, PaymentProvider.BRAINTREE].includes(order.paymentProvider) && !isTrail) {
      // NOTE: initial subscription upgrade to annual plan is only available for stripe/braintree clients
      updateUser({
        initialOrderId: order.id,
      });
    }

    updateCartItems(selectedPlan);

    pushNextRoute();
  };

  const handleOrderCreation = async ({ paymentProvider }: { paymentProvider: PaymentProvider }) => {
    const { data } = await subscriptionMutation.mutateAsync({
      leadId: String(id),
      planId: selectedPlan?.id as string,
      provider: paymentProvider,
      currency,
    });

    return data;
  };
  const currentProductInCart = cartItems?.find((prod) => prod.id === selectedPlan?.id);

  return (
    <>
      <Flex cursor='pointer' gap='0.5rem' width='100%' marginBottom='1rem' onClick={back}>
        <Flex transform='rotate(180deg)'>
          <ArrowSVG />
        </Flex>
        <Text fontSize='14px'>{t('common:back_to_plans')}</Text>
      </Flex>
      <Flex
        alignItems='baseline'
        flexDirection={{ base: 'column', md: 'row-reverse' }}
        width='100%'
        gap={{ base: '0.5rem', md: '2rem' }}
      >
        <OrderSummarySimple flex={{ base: 1, md: 0.4 }} width={{ base: '100%', md: '40%' }} />
        <Box flex={{ base: 1, md: 0.6 }} width={{ base: '100%', md: '60%' }}>
          <Box padding='1.5rem 1rem' bg='#fff' borderRadius='0.5rem' border='1px solid #E1E1E1' marginBottom='1.5rem'>
            {id && isHydrated ? (
              <>
                <TransText
                  transKey='common:select_secure_payment_method'
                  fontSize='20px'
                  fontWeight={700}
                  marginBottom='1rem'
                  color='#233E20'
                ></TransText>
                <Box marginBottom='2rem'>
                  {isSucceeded ? (
                    <Box paddingBottom='40%' />
                  ) : (
                    <>
                      {currentProductInCart ? (
                        <Text
                          textAlign='center'
                          color='rgba(0, 0, 0, 0.8)'
                          fontSize='14px'
                          bg='#fbd1d1'
                          border='1px solid #d89999'
                          padding='0.325rem 0.5rem'
                          borderRadius='0.25rem'
                          marginBottom='1rem'
                        >
                          Seems like you already bought this item, please skip this offer
                        </Text>
                      ) : null}
                      <Box marginBottom='1rem'>
                        <BraintreeWrapper
                          fetchToken={async () => {
                            return await paymentAuthTokenMutation.mutateAsync({
                              provider: 'braintree',
                              leadId: id || '',
                              currency,
                            });
                          }}
                          options={{
                            amount: finalPrice,
                            currency,
                            isSubscription: true,
                          }}
                        >
                          {(braintreeCheckout) => (
                            <>
                              <PaypalCheckout
                                {...braintreeCheckout}
                                createOrder={handleOrderCreation}
                                onSubmit={({ paymentProvider, method }) => {
                                  tracking.checkoutSubmit({ category: paymentProvider, label: method });
                                }}
                                onSuccess={handlePaymentsSuccess}
                              />
                            </>
                          )}
                        </BraintreeWrapper>
                      </Box>
                      <Flex alignItems='center' gap='0.5rem' marginBottom='1rem'>
                        <Box flex={1} height='1px' bg='#E1E1E1' />
                        <TransText
                          transKey='checkout:or_pay_by_credit_card'
                          fontSize='12px'
                          fontWeight={600}
                          color='#233E20'
                          textTransform='uppercase'
                        ></TransText>
                        <Box flex={1} height='1px' bg='#E1E1E1' />
                      </Flex>
                      <StripeWrapper>
                        <PaymentElement.PaymentButtons
                          createOrder={handleOrderCreation}
                          onSuccess={handlePaymentsSuccess}
                          options={{
                            currency,
                            amount: finalPrice,
                            title: selectedPlan ? `${selectedPlan.title}` : undefined,
                          }}
                          onSubmit={({ paymentProvider, method }) => {
                            tracking.checkoutSubmit({ category: paymentProvider, label: method });
                          }}
                        />
                        <PaymentElement.StripeCustomForm
                          createOrder={handleOrderCreation}
                          onSuccess={handlePaymentsSuccess}
                          onSubmit={({ paymentProvider }) => {
                            tracking.checkoutSubmit({ category: paymentProvider, label: 'card' });
                          }}
                          options={{ locale }}
                        />
                      </StripeWrapper>
                    </>
                  )}
                </Box>
                <TransText
                  transKey='common:guaranteed_safe_checkout'
                  textAlign='center'
                  fontSize='12px'
                  marginBottom='0.5rem'
                  color='#070C15'
                ></TransText>
                <Flex justifyContent='center'>
                  <Image src={PaymentMethodsImage} width={342} height={17} alt='' />
                </Flex>
              </>
            ) : (
              <>
                <Text fontWeight={700} marginBottom='1rem' fontSize={{ base: '20px', md: '20px' }}>
                  {t('email:headlines:where_send_stress_release_plan')}
                </Text>
                <EmailForm buttonCopy={t('common:continue')} preventDefault />
              </>
            )}
          </Box>
          <Flex gap='1rem' flexWrap='wrap' justifyContent='center' alignItems='center'>
            <Flex gap='0.5rem' alignItems='center'>
              <CircleCardSVG />
              <TransText transKey='checkout:no_hidden_fees' fontWeight={700} fontSize='14px' color='black'></TransText>
            </Flex>
            <Flex gap='0.5rem' alignItems='center'>
              <CircleSecureSVG />
              <TransText
                transKey='checkout:secure_ssl_protected'
                fontWeight={700}
                fontSize='14px'
                color='black'
              ></TransText>
            </Flex>
            <Flex gap='0.5rem' alignItems='center'>
              <CircleCancelSVG />
              <TransText transKey='checkout:cancel_anytime' fontWeight={700} fontSize='14px' color='black'></TransText>
            </Flex>
          </Flex>
          {isTrail ? (
            <Text fontSize='11px' color='rgba(0, 0, 0, 0.6)' textAlign='center' marginTop='0.5rem'>
              {t('checkout:trial_disclaimer_copy', {
                value: generalUtils.formatPrice(finalPrice, currency),
                projectName: config.name,
                interval: selectedPlan.plan.interval,
              })}
            </Text>
          ) : null}
        </Box>
      </Flex>
    </>
  );
};
