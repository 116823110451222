import { Text } from '@chakra-ui/react';
import { ForwardedRef, forwardRef } from 'react';

import { useAppLead } from '~/hooks/useAppLead';

export const PlansProblemsCopy = forwardRef((props, ref: ForwardedRef<HTMLParagraphElement>) => {
  const { quizAnswers } = useAppLead();

  const options = quizAnswers?.symptoms?.replaceAll('_', ' ').split('|');
  const title = `Eliminate inflammation${
    options?.length === 1 && quizAnswers?.symptoms !== 'none'
      ? ` and ${options?.join('')}`
      : options && options.length > 1
      ? `, ${options?.join?.(', ')}`
      : ' and belly bloating'
  }`;
  return (
    <Text {...props} ref={ref}>
      {title.replace(/,([^,]*)$/, ' &$1')}
    </Text>
  );
});

PlansProblemsCopy.displayName = 'PlansProblemsCopy';
