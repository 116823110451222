import { Text } from '@chakra-ui/react';
import { formulas, useDate } from '@shared/common';
import { ForwardedRef, forwardRef } from 'react';

import { useAppLead } from '~/hooks/useAppLead';

export const PlansGoalCopy = forwardRef((props, ref: ForwardedRef<HTMLParagraphElement>) => {
  const { quizAnswers } = useAppLead();
  const { addDays } = useDate();

  const weight = quizAnswers?.isMetric
    ? Number(formulas.lbsToKg(Number(quizAnswers?.weight)).toFixed(0))
    : Number(Number(quizAnswers?.weight).toFixed(0));
  const targetWeight = quizAnswers?.isMetric
    ? Number(formulas.lbsToKg(Number(quizAnswers?.targetWeight)).toFixed(0))
    : Number(Number(quizAnswers?.targetWeight).toFixed(0));
  const unitSystem = quizAnswers?.isMetric ? 'kg' : 'lb';

  const weightLossTimeInMonths = formulas.calcWeightLossTime(
    Number(quizAnswers?.weight),
    Number(quizAnswers?.targetWeight),
  );
  return (
    <Text {...props} ref={ref}>
      <b>
        Lose {weight - targetWeight} {unitSystem}
      </b>{' '}
      by {addDays(weightLossTimeInMonths * 30).month} {new Date().getUTCDate()},{' '}
      {addDays(weightLossTimeInMonths * 30).newDate.getUTCFullYear()} and feel great
    </Text>
  );
});

PlansGoalCopy.displayName = 'PlansGoalCopy';
