import { Text } from '@chakra-ui/react';
import { ForwardedRef, forwardRef } from 'react';

import { useAppLead } from '~/hooks/useAppLead';

export const CheckoutPlanDislaimer = forwardRef((props, ref: ForwardedRef<HTMLParagraphElement>) => {
  const { selectedPlan } = useAppLead();

  return (
    <Text {...props} ref={ref}>
      {selectedPlan?.key === '7-day-plan'
        ? 'After your 1-week intro offer ends, you will be automatically enrolled in our monthly subscription at the price of $29.99'
        : selectedPlan?.key === '1-month-plan'
        ? 'After your 1-month intro offer ends, you will be automatically enrolled in our monthly subscription at the price of $29.99'
        : 'After your 3-month intro offer ends, you will be automatically enrolled in our 3-month subscription at the price of $59.99'}
    </Text>
  );
});

CheckoutPlanDislaimer.displayName = 'CheckoutPlanDislaimer';
