import { Box, Flex, Modal, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { generalUtils } from '@shared/common';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import DiscountBadgeSVG from '~/assets/icons/discount-badge.svg';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useProducts } from '~/hooks/useProducts';

import { TransText } from './TransText';
import { UpsellButton } from './UpsellButton';

export const AnnualPlanUpgradeUpsell = () => {
  const nextRoute = '/upsells/workouts';
  const { t } = useTranslation();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { selectedPlan, lead } = useAppLead();
  const { products, currency, isWWProduct, findProductPrice } = useProducts(selectedPlan);
  const { pushNextRoute } = useAppRouter(nextRoute);
  const mainPlanPrice = findProductPrice(selectedPlan);
  const planInterval = selectedPlan?.plan.interval || 6;
  const product = products.find(
    (prod) =>
      prod.isUpsell &&
      prod?.plan?.interval === 12 &&
      new RegExp(`${planInterval}-month-plan-upgrade-to-yearly-plan`).test(prod.key) &&
      isWWProduct(prod),
  );
  const currentOfferPrice = findProductPrice(product);
  const fullAnnualPlanPrice = mainPlanPrice.finalPrice + currentOfferPrice.finalPrice;
  const discount = currentOfferPrice.price - fullAnnualPlanPrice;

  return (
    <>
      <Box textAlign='center'>
        <TransText transKey='upsells:your_one_time_offer' color='black' fontWeight={700}></TransText>
        <TransText
          transKey='upsells:x_for_months'
          fontWeight={700}
          fontSize={{ base: '32px', md: '40px' }}
          lineHeight='120%'
          fontFamily='var(--cabinetGroteskFont)'
          marginBottom='0.5rem'
          color='primaryDark'
          values={{
            value: generalUtils.formatPrice(fullAnnualPlanPrice, currency, 3),
          }}
        ></TransText>
        <TransText
          transKey='upsells:upgrade:limited_time_promotion'
          fontSize='12px'
          marginBottom='1rem'
          color='rgba(0, 0, 0, 0.6)'
          values={{
            initialPrice: generalUtils.formatPrice(currentOfferPrice.price, currency, 3),
            discount: generalUtils.formatPrice(discount, currency, 3),
            interval: planInterval,
          }}
        ></TransText>
        <UpsellButton
          refSubscriptionId={lead.initialOrderId}
          text={t('upsells:upgrade_my_plan')}
          product={product}
          onSuccess={pushNextRoute}
        />
        <TransText
          transKey='upsells:im_fine_with_x_plan'
          textDecor='underline'
          fontSize='14px'
          values={{
            interval: planInterval,
            count: planInterval,
          }}
          cursor='pointer'
          onClick={onOpen}
        ></TransText>
      </Box>
      <Modal onClose={onClose} isOpen={isOpen}>
        <ModalOverlay bg='rgba(0, 0, 0, 0.8)' />
        <ModalContent margin='auto 1rem' padding='1rem'>
          <Flex justifyContent='center' marginBottom='1rem'>
            <DiscountBadgeSVG width='96px' fill='#379113' />
          </Flex>
          <TransText
            transKey='upsells:are_you_sure'
            textAlign='center'
            fontWeight={700}
            fontSize='20px'
            marginBottom='0.25rem'
          />
          <TransText
            transKey='upsells:upgrade:we_want_help_you_succeed'
            marginBottom='1rem'
            values={{
              initialPrice: generalUtils.formatPrice(fullAnnualPlanPrice, currency, 3),
              price: generalUtils.formatPrice(currentOfferPrice.price, currency, 3),
            }}
            color='#4d4d4d'
          />
          <UpsellButton
            refSubscriptionId={lead.initialOrderId}
            text={t('upsells:upgrade_my_plan')}
            product={product}
            onSuccess={pushNextRoute}
          />
          <Link href={nextRoute}>
            <TransText
              transKey='upsells:im_fine_with_x_plan'
              textDecor='underline'
              fontSize='14px'
              values={{
                interval: planInterval,
                count: planInterval,
              }}
              textAlign='center'
            ></TransText>
          </Link>
        </ModalContent>
      </Modal>
    </>
  );
};
