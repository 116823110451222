import { Box, Flex, Text } from '@chakra-ui/react';
import { formulas } from '@shared/common';
import { FC, ReactNode } from 'react';

import { useAppLead } from '~/hooks/useAppLead';

export const AdvertorialHeadline: FC<{ children: ReactNode }> = ({ children }) => {
  const { quizAnswers } = useAppLead();

  const weight = quizAnswers?.isMetric
    ? Number(formulas.lbsToKg(Number(quizAnswers?.weight)).toFixed(0))
    : Number(Number(quizAnswers?.weight).toFixed(0));

  const targetWeight = quizAnswers?.isMetric
    ? Number(formulas.lbsToKg(Number(quizAnswers?.targetWeight)).toFixed(0))
    : Number(Number(quizAnswers?.targetWeight).toFixed(0));
  const unitSystem = quizAnswers?.isMetric ? 'kilograms' : 'pounds';
  const weightLossTimeInMonths = formulas.calcWeightLossTime(
    Number(quizAnswers?.weight),
    Number(quizAnswers?.targetWeight),
  );

  const options = quizAnswers?.experience?.replaceAll('_', ' ').split('|');
  const title =
    quizAnswers?.symptoms === 'none_of_the_above'
      ? 'bloating'
      : options?.join?.(options.length === 2 ? ' & ' : ', ') || '';

  return (
    <Flex alignItems='center' gap='2rem'>
      <Box width={{ base: '100%', md: '50%' }} display={{ base: 'none', md: 'flex' }}>
        {children}
      </Box>
      <Flex width={{ base: '100%', md: '50%' }} flexDirection='column' gap='1rem'>
        <Text
          fontSize={{ base: '32px', md: '40px' }}
          fontWeight={900}
          lineHeight='100%'
          fontFamily='var(--cabinetGroteskFont)'
        >{`Here’s your plan review to lose ${weight - targetWeight} ${unitSystem} in ${weightLossTimeInMonths} ${
          weightLossTimeInMonths <= 1 ? 'month' : 'months'
        }`}</Text>
        <Box display={{ base: 'flex', md: 'none' }}>{children}</Box>
        <Text color='black' fontSize='18px'>
          Hi, Dr. Deborah Anderson here. I’m co-founder & head nutritionist of the Digesti plan. Good news - based on
          your answers, I believe our program suits you, and I’ve prepared an initial review of your plan.
          <br />
          <br />
          Keep reading to discover the scientific approach to help you eliminate{' '}
          <b>{title.replace(/,([^,]*)$/, ' &$1')}</b>.
        </Text>
      </Flex>
    </Flex>
  );
};
