import { Box, Flex, Text } from '@chakra-ui/react';
import { formulas, useDate } from '@shared/common';
import Image from 'next/image';

import SummaryChartImage from '~/assets/images/summary_chart.png';
import { useAppLead } from '~/hooks/useAppLead';

export const AdvertorialWeightLossChart = () => {
  const { quizAnswers } = useAppLead();
  const { year, month, addDays } = useDate();
  const weight = quizAnswers?.isMetric
    ? Number(formulas.lbsToKg(Number(quizAnswers?.weight)).toFixed(0))
    : Number(Number(quizAnswers?.weight).toFixed(0));

  const targetWeight = quizAnswers?.isMetric
    ? Number(formulas.lbsToKg(Number(quizAnswers?.targetWeight)).toFixed(0))
    : Number(Number(quizAnswers?.targetWeight).toFixed(0));
  const unitSystem = quizAnswers?.isMetric ? 'kg' : 'lb';

  const weightLostAfterMonth =
    formulas.calcFirstMonthWeightLoss(Number(quizAnswers?.weight), Number(quizAnswers?.targetWeight)) / 2;
  const weightLossTimeInMonths = formulas.calcWeightLossTime(
    Number(quizAnswers?.weight),
    Number(quizAnswers?.targetWeight),
  );

  return (
    <>
      <Text fontSize='18px' marginBottom='1rem' color='black'>
        {`In fact, you can achieve your target weight within the next ${weightLossTimeInMonths} months (by the end of ${
          addDays(weightLossTimeInMonths * 30).month
        }, ${addDays(weightLossTimeInMonths * 30).newDate.getFullYear()})`}
      </Text>
      <Box
        width={{ base: '104%', md: '100%' }}
        margin={{ base: '0 -2% 1rem -2%', md: '0 0 1rem 0' }}
        borderRadius='0.5rem'
        border='1px solid rgba(0, 0, 0, 0.15)'
        maxWidth='28rem'
      >
        <Flex
          gap='0.5rem'
          padding={{ base: '1rem', md: '1.5rem 1.5rem 1rem 1.5rem' }}
          justifyContent='center'
          borderBottom='1px solid rgba(0, 0, 0, 0.2)'
          alignItems='center'
        >
          {[-2, -1, 0, 1, 2].map((index) => (
            <>
              {index !== -2 && (
                <Box backgroundColor='rgba(10, 9, 8, 0.24)' width='4px' height='4px' borderRadius='4px'></Box>
              )}
              <Text
                color={index === 0 ? 'system.black' : 'rgba(10, 9, 8, 0.24)'}
                fontSize={{ base: index === 0 ? '32px' : '18px', md: index === 0 ? '32px' : '22px' }}
                lineHeight='110%'
                fontWeight={700}
              >
                {targetWeight + index}
                {index === 0 ? ` ${unitSystem}` : ''}
              </Text>
            </>
          ))}
        </Flex>
        <Flex padding={{ base: '0.5rem', md: '2.5rem' }} maxWidth='480px' flexDirection='column' margin='auto'>
          <Flex position='relative' width='100%' marginBottom='0.75rem'>
            <Flex>
              <Image
                src={SummaryChartImage}
                width={483}
                height={302}
                alt=''
                placeholder='blur'
                priority
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </Flex>
            <Text position='absolute' fontWeight={700} fontSize='14px' color='system.error' left='9%' top='10%'>
              {weight} {unitSystem}
            </Text>
            <Text position='absolute' fontWeight={700} fontSize='14px' color='system.success' left='82%' top='86%'>
              {targetWeight} {unitSystem}
            </Text>
            <Box position='absolute' bottom='59%' left={{ base: '27%', md: '28%' }}>
              <Text
                padding='0.125rem 0.5rem'
                backgroundColor='system.black'
                borderRadius='0.5rem'
                color='system.white'
                fontSize='14px'
              >
                You will lose first{' '}
                <b>
                  {(quizAnswers?.isMetric
                    ? formulas.lbsToKg(weightLostAfterMonth) < 1
                      ? 1
                      : formulas.lbsToKg(weightLostAfterMonth)
                    : weightLostAfterMonth
                  ).toFixed(0)}
                  {unitSystem}
                </b>
                <br />
                within <b>2 weeks</b>
              </Text>
              <Box
                width='0'
                height='0'
                marginLeft='1.5rem'
                borderLeft='5px solid transparent'
                borderRight='5px solid transparent'
                borderTop='5px solid #0A0908'
              />
            </Box>
          </Flex>
          <Flex marginLeft='2rem' gap='1rem' marginRight='0.5rem' justifyContent='space-between'>
            <Text fontSize='14px'>
              {month} {year}
            </Text>
            <Text fontSize='14px'>
              {addDays(weightLossTimeInMonths * 30).month} {addDays(weightLossTimeInMonths * 30).newDate.getFullYear()}
            </Text>
          </Flex>
        </Flex>
      </Box>
      <Text fontSize='18px' color='black'>
        We estimate that you will be able to lose {(weightLostAfterMonth / 2).toFixed(0)} pounds within the first 2
        weeks. We have a database of more than 124 thousand people, and we <b>matched 9,247 similar to you</b> (female,{' '}
        {quizAnswers?.ageRange}, {weight}
        {unitSystem} starting weight)
      </Text>
    </>
  );
};
