import { FirebaseFunction, MutationOptions, useFirebaseMutation } from './useFirebase';

interface Request {
  leadId: string;
  paymentMethodId: string;
}

export const useClientPaymentMethodMutation = (options?: MutationOptions<unknown, Request>) => {
  return useFirebaseMutation<unknown, Request>(FirebaseFunction.UPDATE_CLIENT_PAYMENT_METHOD, options);
};
