import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Box, Flex } from '@chakra-ui/react';
import Image from 'next/image';
import Slider from 'react-slick';

import NextArrowSVG from '~/assets/icons/next_arrow.svg';
import PrevArrowSVG from '~/assets/icons/prev_arrow.svg';
import Mock1Image from '~/assets/images/mocks/phone_1.png';
import Mock2Image from '~/assets/images/mocks/phone_2.png';
import Mock3Image from '~/assets/images/mocks/phone_3.png';
import Mock4Image from '~/assets/images/mocks/phone_4.png';
import Mock5Image from '~/assets/images/mocks/phone_5.png';

export const AppImageGallery = () => {
  return (
    <Box width={{ base: '114%', md: '100%' }} margin={{ base: '0 -7% 4rem -7%', md: '0 auto 4rem auto' }}>
      <Slider
        {...{
          dots: true,
          centerMode: true,
          centerPadding: '200px',
          responsive: [
            {
              breakpoint: 768,
              settings: {
                centerPadding: '100px',
              },
            },
          ],
          nextArrow: (
            <Flex>
              <NextArrowSVG />
            </Flex>
          ),
          prevArrow: (
            <Flex>
              <PrevArrowSVG />
            </Flex>
          ),
        }}
      >
        <div>
          <Flex position='relative' paddingBottom='150%'>
            <Image src={Mock1Image} fill style={{ objectFit: 'contain' }} alt='' />
          </Flex>
        </div>
        <div>
          <Flex position='relative' paddingBottom='150%'>
            <Image src={Mock2Image} fill style={{ objectFit: 'contain' }} alt='' />
          </Flex>
        </div>
        <div>
          <Flex position='relative' paddingBottom='150%'>
            <Image src={Mock3Image} fill style={{ objectFit: 'contain' }} alt='' />
          </Flex>
        </div>
        <div>
          <Flex position='relative' paddingBottom='150%'>
            <Image src={Mock4Image} fill style={{ objectFit: 'contain' }} alt='' />
          </Flex>
        </div>
        <div>
          <Flex position='relative' paddingBottom='150%'>
            <Image src={Mock5Image} fill style={{ objectFit: 'contain' }} alt='' />
          </Flex>
        </div>
      </Slider>
    </Box>
  );
};
