import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import { formulas } from '@shared/common';

import FemaleSVG from '~/assets/icons/female.svg';
// import ClockSVG from '~/assets/icons/quiz/clock.svg';
// import ScalesSVG from '~/assets/icons/scales.svg';
import WarningSVG from '~/assets/icons/warning.svg';
import { BMIChart } from '~/components/BMIChart';

import { useAppLead } from '../hooks/useAppLead';

export const PersonalSummaryGrid = () => {
  const { quizAnswers } = useAppLead();
  const isMetric = quizAnswers?.isMetric;
  // const poopingTime = quizAnswers?.time_take_to_poop as string;
  // const poopingFrequency = quizAnswers?.how_often_do_you_poop as string;
  // const poopingPatterns = quizAnswers?.pooping_patterns as string;

  return (
    <Box width='100%'>
      <Grid
        gridTemplateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
        gap={{ base: '0.5rem', md: '1rem' }}
        marginBottom={{ base: '0.5rem', md: '1rem' }}
      >
        {/* <Box
          padding={{ base: '1rem', md: '1.5rem' }}
          border='1px solid rgba(0, 0, 0, 0.06)'
          borderRadius='0.25rem'
          backgroundColor='rgba(0, 0, 0, 0.02)'
        >
          <Text textAlign='center' marginBottom='0.5rem' fontWeight={500}>
            Pooping time
          </Text>
          <Text fontSize='14px' lineHeight='130%' marginBottom='2rem'>
            When you have the urge to go, you should be able to sit down on the toilet, and within a few minutes, you
            should be done.
          </Text>
          <Box marginBottom='0.5rem'>
            <Flex gap='0.125rem' borderRadius='0.5rem' overflow='hidden'>
              <Box flex={1} height='10px' backgroundColor='#44AB38' />
              <Box flex={1} height='10px' backgroundColor='#44AB38' />
              <Box flex={1} height='10px' backgroundColor='#F3C716' />
              <Box flex={1} height='10px' backgroundColor='#F7E18A' />
              <Box flex={1} height='10px' backgroundColor='#FD6A78' />
              <Box flex={1} height='10px' backgroundColor='#FD6A78' />
              <Box flex={1} height='10px' backgroundColor='#FD6A78' />
            </Flex>
            <Box position='relative' top='-1rem' display='inline-block'>
              <Box
                height='1.5rem'
                width='8px'
                borderRadius='8px'
                backgroundColor='black'
                border='2px solid white'
                margin='auto'
              ></Box>
              <Text fontSize='12px' textAlign='center'>
                <b>Recommended time:</b>
                <br />
                {`<5 minutes`}
              </Text>
            </Box>
            <Box
              position='relative'
              top='-1rem'
              marginLeft={
                /more/.test(poopingTime)
                  ? '52%'
                  : /15-30/.test(poopingTime)
                  ? '40%'
                  : /5-15/.test(poopingTime)
                  ? '16%'
                  : '2%'
              }
              display='inline-block'
            >
              <Box
                height='1.5rem'
                width='8px'
                borderRadius='8px'
                backgroundColor='black'
                border='2px solid white'
                margin='auto'
              ></Box>
              <Text fontSize='12px' textAlign='center'>
                <b>Your time:</b>
                <br />
                {/more/.test(poopingTime)
                  ? '>30 minutes'
                  : /15-30/.test(poopingTime)
                  ? '15-30 minutes'
                  : /5-15/.test(poopingTime)
                  ? '5-15 minutes'
                  : '~5 minutes'}
              </Text>
            </Box>
          </Box>
          <Flex
            padding='0.5rem 0.5rem'
            border='1px solid rgba(0, 0, 0, 0.05)'
            borderRadius='0.5rem'
            alignItems='center'
            gap='16px'
            backgroundColor='white'
          >
            <Flex backgroundColor='#fff0ca' padding='0.5rem' borderRadius='0.25rem'>
              <ClockSVG width='24px' height='24px' stroke='#e0b105' />
            </Flex>
            <Box>
              <Text fontWeight={700} fontSize='14px'>
                Fact:
              </Text>
              <Text lineHeight='130%' fontSize='12px' color='black'>
                If you spend more than 5 minutes on average, it might mean that you have irregular bowel movements. It
                leads to increased risk of excessive weight.
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box
          padding={{ base: '1rem', md: '1.5rem' }}
          border='1px solid rgba(0, 0, 0, 0.06)'
          borderRadius='0.25rem'
          backgroundColor='rgba(0, 0, 0, 0.02)'
        >
          <Text textAlign='center' marginBottom='0.5rem' fontWeight={500}>
            Poop/body mass weight ratio
          </Text>
          <Text
            textAlign='center'
            fontWeight={900}
            fontSize={{ base: '36px', md: '44px' }}
            lineHeight='120%'
            fontFamily='var(--cabinetGroteskFont)'
            marginBottom='1rem'
          >
            0.72%
          </Text>
          <Flex
            padding='0.5rem 0.5rem'
            border='1px solid rgba(0, 0, 0, 0.05)'
            borderRadius='0.5rem'
            alignItems='center'
            gap='16px'
            backgroundColor='white'
          >
            <Flex backgroundColor='#D7FFBE' padding='0.5rem' borderRadius='0.25rem'>
              <ScalesSVG width='24px' height='24px' fill='#308000' />
            </Flex>
            <Box>
              <Text fontWeight={700} fontSize='14px'>
                Fact:
              </Text>
              <Text lineHeight='130%' fontSize='12px' color='black'>
                Good news! With every bathroom visit you should lose around 1.1 lb of weight.
              </Text>
            </Box>
          </Flex>
        </Box>

        {/2-3|4-5|less/.test(poopingFrequency) && (
          <Box
            padding={{ base: '1.25rem', md: '1.5rem' }}
            border='1px solid rgba(0, 0, 0, 0.06)'
            borderRadius='0.25rem'
            backgroundColor='rgba(0, 0, 0, 0.02)'
          >
            <Text textAlign='center' marginBottom='0.5rem' fontWeight={500}>
              Pooping frequency
            </Text>
            <Text
              textAlign='center'
              fontWeight={900}
              fontSize='28px'
              lineHeight='100%'
              fontFamily='var(--cabinetGroteskFont)'
              marginBottom='1rem'
              color='#db820b'
            >
              Less than once per week
            </Text>
            <Text fontSize='14px'>
              Sometimes the consistency is more important than the frequency, but{' '}
              <b>once or twice a day is a good baseline</b>. It gets the muscles in your large intestines moving. This
              will encourage the gas to make its way out of your system.
            </Text>
          </Box>
        )}
        {/less/.test(poopingPatterns) && (
          <Box
            padding={{ base: '1.25rem', md: '1.5rem' }}
            border='1px solid rgba(0, 0, 0, 0.06)'
            borderRadius='0.25rem'
            backgroundColor='rgba(0, 0, 0, 0.02)'
          >
            <Text textAlign='center' marginBottom='0.5rem' fontWeight={500}>
              Pooping patterns
            </Text>
            <Text
              textAlign='center'
              fontWeight={900}
              fontSize='28px'
              lineHeight='100%'
              fontFamily='var(--cabinetGroteskFont)'
              marginBottom='1rem'
              color='#db820b'
            >
              You have started pooping less
            </Text>
            <Text fontSize='14px'>
              If you are not pooping regularly, it could be a sign of constipation. Constipation can cause weight gain
              and you need to resolve it first to start losing weight without any extreme diet changes.
            </Text>
          </Box>
        )}
        <Box
          padding={{ base: '1rem', md: '1.5rem' }}
          border='1px solid rgba(0, 0, 0, 0.06)'
          borderRadius='0.25rem'
          backgroundColor='rgba(0, 0, 0, 0.02)'
        >
          <Text textAlign='center' marginBottom='0.25rem' fontWeight={500}>
            Pooping type
          </Text>

          <Text
            textAlign='center'
            fontWeight={900}
            fontSize='28px'
            lineHeight='120%'
            fontFamily='var(--cabinetGroteskFont)'
            color={poopResult === 'Good' ? '#2a8b02' : '#db820b'}
          >
            {poopResult}
          </Text>
          <Flex justifyContent='center'>
            {/separate_hard/.test(poop) && (
              <Flex justifyContent='center'>
                <Poop1SVG />
              </Flex>
            )}
            {/lumpy_and_sausage/.test(poop) && (
              <Flex justifyContent='center'>
                <Poop2SVG />
              </Flex>
            )}
            {/a_sausage_shape/.test(poop) && (
              <Flex justifyContent='center'>
                <Poop3SVG />
              </Flex>
            )}
            {/like_a_smooth/.test(poop) && (
              <Flex justifyContent='center'>
                <Poop4SVG />
              </Flex>
            )}
            {/soft_blobs/.test(poop) && (
              <Flex justifyContent='center'>
                <Poop5SVG />
              </Flex>
            )}
            {/mushy_consistency/.test(poop) && (
              <Flex justifyContent='center'>
                <Poop6SVG />
              </Flex>
            )}
            {/liquid_consintency/.test(poop) && (
              <Flex justifyContent='center'>
                <Poop7SVG />
              </Flex>
            )}
          </Flex>
          <Text fontSize='14px' lineHeight='130%'>
            We use science-based Bristol stool chart to determine your bowel movement without going to doctor.
          </Text>
        </Box> */}
        <Box
          padding={{ base: '1rem', md: '1.5rem' }}
          border='1px solid rgba(0, 0, 0, 0.06)'
          borderRadius='0.25rem'
          backgroundColor='rgba(0, 0, 0, 0.02)'
        >
          <Text fontWeight={700} fontSize='20px' marginBottom='0.5rem'>
            Inflamation and stress levels
          </Text>
          <Box marginBottom='1.5rem'>
            <Flex position='relative' gap='0.125rem' marginBottom='0.5rem'>
              <Box flex={1} height='10px' backgroundColor='#44AB38' borderRadius='0.5rem 0 0 0.5rem' />
              <Box flex={1} height='10px' backgroundColor='#44AB38' />
              <Box flex={1} height='10px' backgroundColor='#F7E18A' />
              <Box flex={1} height='10px' backgroundColor='#F7E18A' />
              <Box flex={1} height='10px' backgroundColor='#F7E18A' />
              <Box flex={1} height='10px' backgroundColor='#FD6A78' />
              <Box flex={1} height='10px' backgroundColor='#FD6A78' borderRadius='0 0.5rem 0.5rem 0' />
              <Box
                position='absolute'
                height='24px'
                width='24px'
                borderRadius='1rem'
                backgroundColor='#340000'
                top='50%'
                transform='translateY(-50%)'
                border='3px solid white'
                left='84%'
                boxShadow='0 4px 4px 4px rgba(0,0,0, 0.05)'
              ></Box>
            </Flex>
            <Flex justifyContent='space-between'>
              <Text fontSize='12px' fontWeight={500}>
                Low
              </Text>
              <Text fontSize='12px' fontWeight={500}>
                Medium
              </Text>
              <Text fontSize='12px' fontWeight={500}>
                High
              </Text>
            </Flex>
          </Box>
          <Text fontWeight={700} fontSize='20px' marginBottom='0.5rem'>
            Body stiffness and pain
          </Text>
          <Box marginBottom='1.5rem'>
            <Flex position='relative' gap='0.125rem' marginBottom='0.5rem'>
              <Box flex={1} height='10px' backgroundColor='#44AB38' borderRadius='0.5rem 0 0 0.5rem' />
              <Box flex={1} height='10px' backgroundColor='#44AB38' />
              <Box flex={1} height='10px' backgroundColor='#F7E18A' />
              <Box flex={1} height='10px' backgroundColor='#F7E18A' />
              <Box flex={1} height='10px' backgroundColor='#F7E18A' />
              <Box flex={1} height='10px' backgroundColor='#FD6A78' />
              <Box flex={1} height='10px' backgroundColor='#FD6A78' borderRadius='0 0.5rem 0.5rem 0' />
              <Box
                position='absolute'
                height='24px'
                width='24px'
                borderRadius='1rem'
                backgroundColor='#340000'
                top='50%'
                transform='translateY(-50%)'
                border='3px solid white'
                left='70%'
                boxShadow='0 4px 4px 4px rgba(0,0,0, 0.05)'
              ></Box>
            </Flex>
            <Flex justifyContent='space-between'>
              <Text fontSize='12px' fontWeight={500}>
                Underweight
              </Text>
              <Text fontSize='12px' fontWeight={500}>
                Normal
              </Text>
              <Text fontSize='12px' fontWeight={500}>
                Obese
              </Text>
            </Flex>
          </Box>
          <Text fontWeight={700} fontSize='20px' marginBottom='0.5rem'>
            Anxiety, depression, and brain fog
          </Text>
          <Box>
            <Flex position='relative' gap='0.125rem' marginBottom='0.5rem'>
              <Box flex={1} height='10px' backgroundColor='#44AB38' borderRadius='0.5rem 0 0 0.5rem' />
              <Box flex={1} height='10px' backgroundColor='#44AB38' />
              <Box flex={1} height='10px' backgroundColor='#F7E18A' />
              <Box flex={1} height='10px' backgroundColor='#F7E18A' />
              <Box flex={1} height='10px' backgroundColor='#F7E18A' />
              <Box flex={1} height='10px' backgroundColor='#FD6A78' />
              <Box flex={1} height='10px' backgroundColor='#FD6A78' borderRadius='0 0.5rem 0.5rem 0' />
              <Box
                position='absolute'
                height='24px'
                width='24px'
                borderRadius='1rem'
                backgroundColor='#340000'
                top='50%'
                transform='translateY(-50%)'
                border='3px solid white'
                left='75%'
                boxShadow='0 4px 4px 4px rgba(0,0,0, 0.05)'
              ></Box>
            </Flex>
            <Flex justifyContent='space-between'>
              <Text fontSize='12px' fontWeight={500}>
                Low
              </Text>
              <Text fontSize='12px' fontWeight={500}>
                Medium
              </Text>
              <Text fontSize='12px' fontWeight={500}>
                High
              </Text>
            </Flex>
          </Box>
        </Box>
        <BMIChart />
        <Box
          padding={{ base: '1rem', md: '1.5rem' }}
          border='1px solid rgba(0, 0, 0, 0.06)'
          borderRadius='0.25rem'
          backgroundColor='rgba(0, 0, 0, 0.02)'
        >
          <Text textAlign='center' marginBottom='1rem' fontWeight={500}>
            Your metabolic age
          </Text>
          <Text
            marginBottom='1.5rem'
            fontSize='44px'
            fontFamily='var(--cabinetGroteskFont)'
            textAlign='center'
            lineHeight='100%'
          >
            <b>{Number(quizAnswers?.age) + 5}</b> years
          </Text>
          <Flex
            alignItems='center'
            backgroundColor='#ffe4e7'
            padding='0.5rem 0.75rem'
            gap='12px'
            borderRadius='0.25rem'
          >
            <WarningSVG width='24px' height='24px' fill='red' />
            <Text lineHeight='120%' color='#FD6A78' fontWeight={500} fontSize='14px'>
              Your metabolism is slower than it should be
            </Text>
          </Flex>
        </Box>
      </Grid>
      <Grid
        gridTemplateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }}
        gap={{ base: '0.5rem', md: '1rem' }}
        textAlign='center'
      >
        <Box
          padding='1rem'
          border='1px solid rgba(0, 0, 0, 0.06)'
          borderRadius='0.25rem'
          backgroundColor='rgba(0, 0, 0, 0.02)'
        >
          <Text marginBottom='0.5rem' fontWeight={500}>
            Gender
          </Text>
          <FemaleSVG width='48px' height='48px' fill='#233E20' />
        </Box>
        <Box
          padding='1rem'
          border='1px solid rgba(0, 0, 0, 0.06)'
          borderRadius='0.25rem'
          backgroundColor='rgba(0, 0, 0, 0.02)'
        >
          <Text marginBottom='0.5rem' fontWeight={500}>
            Age
          </Text>
          <Text fontSize='44px' fontFamily='var(--cabinetGroteskFont)' textAlign='center' lineHeight='100%'>
            <b>{Number(quizAnswers?.age)}</b>
          </Text>
        </Box>
        <Box
          padding='1rem'
          border='1px solid rgba(0, 0, 0, 0.06)'
          borderRadius='0.25rem'
          backgroundColor='rgba(0, 0, 0, 0.02)'
        >
          <Text marginBottom='0.5rem' fontWeight={500}>{`Height, ${isMetric ? 'cm' : 'ft'}`}</Text>
          <Text fontSize='44px' fontFamily='var(--cabinetGroteskFont)' textAlign='center' lineHeight='100%'>
            <b>
              {isMetric
                ? formulas.inchesToCm(Number(quizAnswers?.height)).toFixed(0)
                : `${Number(formulas.inchesToFeets(Number(quizAnswers?.height)).toFixed(0))}'${
                    Number(quizAnswers?.height) -
                    Number(formulas.inchesToFeets(Number(quizAnswers?.height)).toFixed(0)) * 12
                  }''`}
            </b>
          </Text>
        </Box>
        <Box
          padding='1rem'
          border='1px solid rgba(0, 0, 0, 0.06)'
          borderRadius='0.25rem'
          backgroundColor='rgba(0, 0, 0, 0.02)'
        >
          <Text marginBottom='0.5rem' fontWeight={500}>{`Weight, ${isMetric ? 'kg' : 'lb'}`}</Text>
          <Text fontSize='44px' fontFamily='var(--cabinetGroteskFont)' textAlign='center' lineHeight='100%'>
            <b>{isMetric ? formulas.lbsToKg(Number(quizAnswers?.weight)).toFixed(0) : Number(quizAnswers?.weight)}</b>
          </Text>
        </Box>
      </Grid>
    </Box>
  );
};
