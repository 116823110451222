import { Currency } from '@shared/common';
import { PaymentProvider } from '@shared/payments';

import { FirebaseFunction, MutationOptions, useFirebaseMutation } from './useFirebase';

interface UpsellRequest {
  leadId: string;
  planId: string;
  provider: PaymentProvider;
  currency: Currency;
  subscriptionId?: string;
}

export interface UpsellErrorResponse {
  code: 'missing_payment_method' | 'use_stripe_sdk' | 'rejected';
  client_secret: string;
}

type UpsellResponse =
  | {
      id: string;
      provider: PaymentProvider;
      success: boolean;
    }
  | { error: UpsellErrorResponse };

export const useUpsellMutation = (options?: MutationOptions<UpsellResponse, UpsellRequest>) => {
  return useFirebaseMutation<UpsellResponse, UpsellRequest>(FirebaseFunction.BUY_UPSELL, options);
};
