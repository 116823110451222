import { Box, Flex, Text } from '@chakra-ui/react';
import { formulas, useDate } from '@shared/common';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import SummaryChartImage from '~/assets/images/summary_chart.png';
import { TransText } from '~/components/TransText';
import { useAppLead } from '~/hooks/useAppLead';

export const InflammationWeightLoss = () => {
  const { t } = useTranslation();
  const { quizAnswers } = useAppLead();
  const { year, month, addDays } = useDate();
  const weight = quizAnswers?.isMetric
    ? Number(formulas.lbsToKg(Number(quizAnswers?.weight)).toFixed(0))
    : Number(Number(quizAnswers?.weight).toFixed(0));

  const targetWeight = quizAnswers?.isMetric
    ? Number(formulas.lbsToKg(Number(quizAnswers?.targetWeight)).toFixed(0))
    : Number(Number(quizAnswers?.targetWeight).toFixed(0));
  const unitSystem = quizAnswers?.isMetric ? 'kg' : 'lb';

  const weightLostAfterMonth =
    formulas.calcFirstMonthWeightLoss(Number(quizAnswers?.weight), Number(quizAnswers?.targetWeight)) / 2;
  const weightLossTimeInMonths = formulas.calcWeightLossTime(
    Number(quizAnswers?.weight),
    Number(quizAnswers?.targetWeight),
  );

  return (
    <>
      <Box
        width={{ base: '104%', md: '100%' }}
        margin={{ base: '0 -2% 1rem -2%', md: '0 0 1rem 0' }}
        borderRadius='0.5rem'
        border='1px solid rgba(0, 0, 0, 0.15)'
        maxWidth='28rem'
      >
        <Flex
          gap='0.5rem'
          padding={{ base: '1rem', md: '1.5rem 1.5rem 1rem 1.5rem' }}
          justifyContent='center'
          borderBottom='1px solid rgba(0, 0, 0, 0.2)'
          alignItems='center'
        >
          {[-2, -1, 0, 1, 2].map((index) => (
            <>
              {index !== -2 && (
                <Box backgroundColor='rgba(10, 9, 8, 0.24)' width='4px' height='4px' borderRadius='4px'></Box>
              )}
              <Text
                color={index === 0 ? 'system.black' : 'rgba(10, 9, 8, 0.24)'}
                fontSize={{ base: index === 0 ? '32px' : '18px', md: index === 0 ? '32px' : '22px' }}
                lineHeight='110%'
                fontWeight={700}
              >
                {targetWeight + index}
                {index === 0 ? ` ${unitSystem}` : ''}
              </Text>
            </>
          ))}
        </Flex>
        <Flex padding={{ base: '0.5rem', md: '2.5rem' }} maxWidth='480px' flexDirection='column' margin='auto'>
          <Flex position='relative' width='100%' marginBottom='0.75rem'>
            <Flex>
              <TransText
                transKey='summary:your_starting_point'
                sx={{ writingMode: 'vertical-rl', transform: 'rotate(180deg)', textAlign: 'right', fontSize: '12px' }}
              ></TransText>
              <Image
                src={SummaryChartImage}
                alt=''
                placeholder='blur'
                priority
                style={{
                  width: '88%',
                  maxWidth: '100%',
                  height: 'auto',
                  flex: 1,
                }}
              />
            </Flex>
            <Text position='absolute' fontWeight={700} fontSize='14px' color='system.error' left='9%' top='10%'>
              {weight} {unitSystem}
            </Text>
            <Text position='absolute' fontWeight={700} fontSize='14px' color='system.success' left='82%' top='86%'>
              {targetWeight} {unitSystem}
            </Text>
            <Box position='absolute' bottom='59%' left={{ base: '27%', md: '28%' }}>
              <TransText
                transKey='summary:weight_loss_within_two_weeks'
                padding='0.125rem 0.5rem'
                backgroundColor='system.black'
                borderRadius='0.5rem'
                color='system.white'
                fontSize='14px'
                values={{
                  weight: (quizAnswers?.isMetric
                    ? formulas.lbsToKg(weightLostAfterMonth) < 1
                      ? 1
                      : formulas.lbsToKg(weightLostAfterMonth)
                    : weightLostAfterMonth
                  ).toFixed(0),
                }}
              ></TransText>
              <Box
                width='0'
                height='0'
                marginLeft='1.5rem'
                borderLeft='5px solid transparent'
                borderRight='5px solid transparent'
                borderTop='5px solid #0A0908'
              />
            </Box>
          </Flex>
          <Flex marginLeft='2rem' gap='1rem' marginRight='0.5rem' justifyContent='space-between'>
            <Text fontSize='14px'>
              {t(`common:${month.toLowerCase()}`)} {year}
            </Text>
            <Text fontSize='14px'>
              {t(`common:${addDays(weightLossTimeInMonths * 30).month}`)}{' '}
              {addDays(weightLossTimeInMonths * 30).newDate.getFullYear()}
            </Text>
          </Flex>
        </Flex>
      </Box>
      <TransText
        transKey='summary:doctor_patients'
        color='black'
        values={{
          weightLossAfterTwoWeeks: (weightLostAfterMonth / 2).toFixed(0),
          weightFrom: quizAnswers?.isMetric ? 9 : 20,
          weightTo: quizAnswers?.isMetric ? 14 : 30,
        }}
      ></TransText>
    </>
  );
};
