import { Text } from '@chakra-ui/react';
import { formulas } from '@shared/common';
import { ForwardedRef, forwardRef } from 'react';

import { useAppLead } from '~/hooks/useAppLead';

export const InflammationProgramCoverage = forwardRef((props, ref: ForwardedRef<HTMLParagraphElement>) => {
  const { quizAnswers } = useAppLead();
  const age = Number(quizAnswers?.age);
  const { score } = formulas.calcBMI(Number(quizAnswers?.weight), Number(quizAnswers?.height));
  const ageGroup = age <= 30 ? 20 : age <= 40 ? 30 : age <= 50 ? 40 : age <= 60 ? 50 : age <= 70 ? age <= 70 : 80;
  const bmiGroup =
    score <= 18
      ? 18
      : score <= 20
      ? 20
      : score <= 24
      ? 24
      : score <= 26
      ? 26
      : score <= 30
      ? 30
      : score <= 34
      ? 34
      : 36;

  return (
    <Text ref={ref} {...props}>
      In order to get accepted for the coverage, you have to live in the US, be above {ageGroup} years old, have a BMI
      higher than {bmiGroup}, and have certain health issues.
    </Text>
  );
});

InflammationProgramCoverage.displayName = 'InflammationProgramCoverage';
